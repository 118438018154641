<div class="aapp-login-container">
  <div class="container">
    <div class="row">
      <div class="d-flex w-100 justify-content-center">
        <um-login-form
          [orgUuid]="orgUuid"
          [managePasswordNavigateTo]="managePasswordNavigateTo"
          [payloadToken]="payloadToken"
          [isCaptchaEnabled]="true"
          [selectedApp]="'EPV'"
          (submitForm)="onLogin($event)"
        ></um-login-form>
      </div>
    </div>
  </div>
</div>
