<ac-icon-definitions></ac-icon-definitions>
<ensight-browser-message></ensight-browser-message>
@if (isDefaultRoute) {
  <ensight-router-root></ensight-router-root>
}
@if (isSharedRoute) {
  <ensight-router-root-new></ensight-router-root-new>
}
@if (isNotFoundRoute) {
  <ensight-router-root-not-found></ensight-router-root-not-found>
}

@if (!!authService.isLogged) {
  <ensight-style-editor *accessControl="{ user: ['ADMIN_MANAGE_THEMES'] }"></ensight-style-editor>
}
