import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseType } from '@core/model';
import * as apiConstant from '@core/constant';
import { getSearchQuery } from '@core/utils';
import { FullNameRes } from '@shared/models';
import { Observable } from 'rxjs';

@Injectable()
export class UserApiService {
  constructor(private http: HttpClient) {}

  // @deprecated
  public login(data: any) {
    return this.http.post<ResponseType<any>>(apiConstant.login, data);
  }

  // @deprecated
  public logout() {
    return this.http.get<ResponseType<any>>(apiConstant.logout);
  }

  public getUsersFullName(params: { ids: number[] }): Observable<FullNameRes> {
    return this.http.get<FullNameRes>(apiConstant.usersFullName, {
      params: getSearchQuery(params),
    });
  }
}
