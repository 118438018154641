import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { map, Observable, of } from 'rxjs';
import { orderBy } from 'lodash-es';
import { DragulaService } from 'ng2-dragula';

import { ModalConfig, ModalRef } from '@assurance/bootstrap';

import { DragulaDropModel, IFilterBlockDataPages } from '@shared/models';
import { LinkedPagesModal } from '../../models';

@Component({
  selector: 'ep-linked-pages-modal',
  templateUrl: './linked-pages-modal.component.html',
  styleUrls: ['./linked-pages-modal.component.scss'],
})
export class LinkedPagesModalComponent implements OnInit, OnDestroy {
  public config = inject(ModalConfig<LinkedPagesModal>);
  private modal = inject(ModalRef);
  private dragulaService = inject(DragulaService);
  private destroyRef = inject(DestroyRef);

  pagesDragModel = { bag: 'pages-bag', data: [] };
  pages: IFilterBlockDataPages[] = [];
  filteredPages$: Observable<IFilterBlockDataPages[]> = of([]);
  selectedPages: IFilterBlockDataPages[] = [];

  private searchValue = '';

  ngOnInit(): void {
    this.pages = [...this.config.data.pages];
    this.selectedPages = [...this.config.data.selectedPages];
    this.getFilteredPages();
    this.setDragData();
    this.initDragBlocks();
  }

  ngOnDestroy(): void {
    this.dragulaService.destroy(this.pagesDragModel.bag);
  }

  onCancel(): void {
    this.modal.close({ submitted: false });
  }

  addPage(page: IFilterBlockDataPages): void {
    const index = this.pages.findIndex(p => p.id === page.id);

    this.pages.splice(index, 1);
    this.selectedPages.push({ ...page, selected: true, order: this.selectedPages.length + 1 });
    //we need to reassign array because of virtual scroll
    this.pages = [...this.pages];
    this.getFilteredPages();
    this.setDragData();
  }

  removePage(page: IFilterBlockDataPages): void {
    const index = this.selectedPages.findIndex(p => p.id === page.id);

    this.selectedPages.splice(index, 1);
    this.pages.push({ ...page, selected: false, order: null });
    //we need to reassign array because of virtual scroll
    this.pages = [...this.pages];
    this.getFilteredPages();
    this.setDragData();
  }

  // searchData(event: { search: string }) {
  //TODO: need to add interface
  searchData(event: any): void {
    this.searchValue = (event?.search || '').toLowerCase();
    this.getFilteredPages();
  }

  apply(): void {
    this.modal.close({
      submitted: true,
      selectedPages: this.selectedPages,
    });
  }

  private initDragBlocks(): void {
    this.dragulaService
      .dropModel()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((dropModel: DragulaDropModel<IFilterBlockDataPages>) => {
        this.pagesDragModel.data = dropModel.sourceModel.map((data, index) => {
          return {
            ...data,
            order: index,
          };
        });

        this.selectedPages = this.pagesDragModel.data;
      });
  }

  private setDragData(): void {
    this.pagesDragModel.data = orderBy(this.selectedPages, ['order']);
  }

  private getFilteredPages(): void {
    this.filteredPages$ = of(this.pages).pipe(
      map(pages => pages.filter(p => p.label.toLowerCase().includes(this.searchValue))),
      map(pages => orderBy(pages, ['label']))
    );
  }
}
