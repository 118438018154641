import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getSearchQuery } from '@core/utils';
import { Observable } from 'rxjs';
import * as apiConstant from '@core/constant';
import { ResponseType } from '@core/model';

@Injectable()
export class CoverLetterApiService {
  constructor(private http: HttpClient) {}

  public getCoverLetterTemplates(params: any): Observable<ResponseType<any>> {
    return this.http.get<ResponseType<any>>(apiConstant.sharedContentServiceContent, {
      params: getSearchQuery(params),
    });
  }

  public getCoverLetter(presentationID: number) {
    const restUrl = `${apiConstant.presentation}/${presentationID}/${apiConstant.coverLetter}`;

    return this.http.get<ResponseType<any>>(restUrl);
  }

  public putCoverLetter(presentationID: number, data: any) {
    const restUrl = `${apiConstant.presentation}/${presentationID}/${apiConstant.coverLetter}`;

    return this.http.put<ResponseType<any>>(restUrl, data);
  }

  public deleteCoverLetter(presentationID: number) {
    const restUrl = `${apiConstant.presentation}/${presentationID}/${apiConstant.coverLetter}`;

    return this.http.delete<ResponseType<any>>(restUrl);
  }

  public postCoverLetter(presentationID: number, data: any) {
    const restUrl = `${apiConstant.presentation}/${presentationID}/${apiConstant.coverLetter}`;

    return this.http.post<ResponseType<any>>(restUrl, data);
  }

  public getSharedCoverLetter(): Observable<ResponseType<any>> {
    return this.http.get<ResponseType<any>>(apiConstant.sharedPresentationCoverLetter);
  }
}
