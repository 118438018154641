import { inject, Injectable } from '@angular/core';

import { combineLatest, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import {
  CoverLetterWithRequired,
  CustomPagesConfig,
  CustomPageValues,
  EligibleProductTypes,
  PageConfig,
} from '@shared/models';
import { getPresentationPlans } from '../../../../components/presentation/presentation.selectors';
import {
  getPermittedConfigs,
  getPresentationConfigs,
} from '../../../../components/presentation/redux/configs/selectors';
import { getDependentPagesConfig } from '../../../../components/presentation/setup/setup.selectors';
import { CareerPlan } from '@core/model';
import { AppState } from '../../../../reducers';
import { getCoverLetterWithRequired } from '../../../../components/presentation/modals/cover-sheet-modal/cover-sheet-modal.selectors';
import { isEmpty } from 'lodash-es';

//TODO: we don't need provide service to root, once inheritance of components was changed this will be changed
@Injectable({
  providedIn: 'root',
})
export class EndPagesAdapterService {
  private store = inject(Store<AppState>);

  filterEndPages(endPages: CustomPageValues[], isDownloadPDF?: boolean): Observable<CustomPageValues[]> {
    return combineLatest([
      this.store.pipe(select(getPresentationPlans)),
      this.store.pipe(
        select(location.href.includes('shared-presentation') ? getPresentationConfigs : getPermittedConfigs)
      ),
      this.store.pipe(select(getDependentPagesConfig)),
      this.store.select(getCoverLetterWithRequired),
    ]).pipe(
      map(([plans, configs, data, coverLetterWithRequired]) =>
        this.getFilteredEndPages(endPages, plans, configs, data?.configs, isDownloadPDF, coverLetterWithRequired)
      )
    );
  }

  private getFilteredEndPages(
    endPages: CustomPageValues[],
    plans: CareerPlan[],
    configs: PageConfig[],
    customPagesConfigs: CustomPagesConfig[],
    isDownloadPDF: boolean,
    coverLetterWithRequired: CoverLetterWithRequired
  ): CustomPageValues[] {
    const productTypes = plans.map(el => el.configjson.metadata.product_type);
    const filteredConfigs = configs
      .filter((config: PageConfig) => {
        if (config.config.uiId === 'cover') {
          if (coverLetterWithRequired.coverLettersRequired.length) {
            return true;
          } else {
            return !isEmpty(coverLetterWithRequired.coverLetter) ? config.config.showPreview : false;
          }
        }

        return config.config.showPreview;
      })
      .filter((config: PageConfig) => config.config.uiId !== 'target_premium');

    return endPages
      .filter(page => this.checkOnProductType(page, productTypes))
      .filter(page => {
        if (isDownloadPDF && page.hideOnPDF) {
          return false;
        }

        if (page.carrierUiIds && page.carrierUiIds.length) {
          return plans.some(
            plan =>
              page.carrierUiIds.includes(plan.configjson.metadata.carrier_code as string) &&
              this.isPlanSelected(plan, configs, customPagesConfigs)
          );
        }

        return true;
      })
      .filter(page => this.getFilteredEndPagesBySalesStories(page, filteredConfigs));
  }

  private checkOnProductType(page: CustomPageValues, productTypes: string[]): boolean {
    if (!page.customFields?.eligibleProductsActive) {
      return true;
    } else {
      const types = page.customFields?.eligibleProductTypes.reduce((acc: string[], item: EligibleProductTypes) => {
        return [...acc, item.label, item.productType];
      }, []);

      return productTypes.some((type: string) => types.includes(type));
    }
  }

  private isPlanSelected(plan: CareerPlan, configs: PageConfig[], customPagesConfigs: CustomPagesConfig[]): boolean {
    return configs?.some(
      (config: PageConfig) =>
        config.config.uiId !== 'cover' &&
        config.config.uiId !== 'target_premium' &&
        (config.config.showPreview || this.checkIfDependentPageEnabled(config, customPagesConfigs)) &&
        (config.config.selectedPlanId === plan.id ||
          config.config.carrierPlanId === plan.id ||
          config.config.activePlans?.find(activePlan => activePlan.carrierPlanId === plan.id && activePlan.isShown))
    );
  }

  private checkIfDependentPageEnabled(config: PageConfig, customPagesConfigs: CustomPagesConfig[]): boolean {
    return (
      config.config.isSalesConcept &&
      customPagesConfigs
        ?.find((customPageConfig: CustomPagesConfig) => customPageConfig.uiId === config.config.uiId)
        ?.pages.some(page => page.isSelected)
    );
  }

  private getFilteredEndPagesBySalesStories(page: CustomPageValues, configs: PageConfig[]): boolean {
    const notSalesStories = configs.some(config => !config.config?.isSalesConcept);
    const salesStories = configs
      .filter(config => config.config.isSalesConcept)
      .map(config => {
        const [uiId] = config.config.uiId.split('.');

        return uiId;
      });

    if (page.shouldHideRequiredPage) {
      if (notSalesStories || !salesStories.length) {
        return true;
      }

      if (salesStories.every(uiId => page.salesStories.includes(uiId))) {
        return false;
      }
    }

    return true;
  }
}
