import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as apiConstant from '@core/constant';
import { ResponseType } from '@core/model';
@Injectable()
export class SharedPresentationApiService {
  constructor(private http: HttpClient) {}

  public getSharedPresentationConfigs(): Observable<ResponseType<any>> {
    return this.http.get<ResponseType<any>>(apiConstant.sharedPresentationConfigs);
  }

  public updateSharedPresentationVersion(
    sharedToken: string,
    customPageUUID: string,
    addedDate: string
  ): Observable<ResponseType<{ success: boolean }>> {
    const url = `${apiConstant.sharedPresentationConfigs}/${customPageUUID}/version`;
    const headers = new HttpHeaders({
      'shared-token': sharedToken,
    });

    return this.http.patch<ResponseType<{ success: boolean }>>(url, { addedDate: addedDate }, { headers: headers });
  }

  onOpenPresentationSharedByEmail(metaParam: string): Observable<ResponseType<{ success: boolean }>> {
    const url = `${apiConstant.baseSharedUrl}${apiConstant.shareByEmail}`;
    const payload = { meta: metaParam };

    return this.http.patch<ResponseType<{ success: boolean }>>(url, payload);
  }

  public getSharedMetadataList(): Observable<ResponseType<any>> {
    return this.http.get<ResponseType<any>>(apiConstant.sharedMetadata);
  }

  public getSharedPresentation(): Observable<ResponseType<any>> {
    return this.http.get<ResponseType<any>>(apiConstant.sharedPresentation);
  }

  public createEvent(data: { eventName: string; extraInfo: string | null }): Observable<ResponseType<any>> {
    return this.http.post<ResponseType<any>>(apiConstant.sharedCreateEvent, data);
  }
}
