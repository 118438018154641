import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { pendingExports } from '@shared/constants';
import { PendingExportsData, CaseResponse } from '@shared/models';
import { Observable } from 'rxjs';
import * as apiConstant from '@core/constant';
import { ResponseType } from '@core/model';

@Injectable()
export class TransactionApiService {
  constructor(private http: HttpClient) {}

  public getPendingExportsByTransaction(transactionUUID: string): Observable<ResponseType<PendingExportsData[]>> {
    const restUrl = `${pendingExports}/${transactionUUID}`;

    return this.http.get<ResponseType<PendingExportsData[]>>(restUrl);
  }

  public getTransactionCaseId(params: { transactionUUID: string }) {
    return this.http.post<ResponseType<CaseResponse>>(apiConstant.transactionCaseData, params);
  }
}
