export const readCookie = (name: string): string => {
  const foundCookie = document.cookie.match('(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)');

  return foundCookie ? foundCookie.pop() : '';
};

export const deleteCookie = (name: string): void => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const getMatchesFunction = (): string => {
  let matchesFn: any;
  ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].forEach(fn => {
    if (!matchesFn && typeof (document.body as any)[fn] === 'function') {
      matchesFn = fn;

      return true;
    }

    return false;
  });

  return matchesFn;
};
