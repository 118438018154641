import { ShortfallFields } from '@shared/models';
import { SelectedPlansOptions } from '../../../components/presentation/shared/selected-plans/selected-plans.component';
import { ActivePlan, PlansTableField, SpreadsheetDrawConfig, XAxisSourceType } from '@core/model';
import { ModalRef } from '@assurance/bootstrap';

export interface DefaultState<T> {
  data: T;
  loading: boolean;
  loaded: boolean;
  error: any;
}

export interface DefaultConfigState<T> extends DefaultState<T> {
  updating: boolean;
}

export interface AgeLimitConfig {
  min?: number;
  max?: number;
  label?: 'age' | 'policy year';
  validRange?: {
    min: number;
    max: number;
  };
  force?: boolean;
  level?: 'in presentation' | 'on graph';
}

export interface VisualizationReadyEvent {
  id: string;
  show?: boolean;
}

export interface XAxisSourceEvent {
  value: XAxisSourceType;
}

export interface PlanMetric extends ActivePlan {
  key: string;
  tipValue: number;
  included?: boolean;
  order: number;
  isSelected: boolean;
  title: string;
}

export interface SingleViewPlanMetric extends PlanMetric {
  tabTitle: string;
  tabOrder: number;
}

export interface ConfigBase {
  internalId?: number;
  pageName?: string;
  pageOrder?: number;
  isNameChanged?: boolean;
}

export interface PageConfig extends ConfigBase {
  config: {
    activePlans: ActivePlan[];
    chartConfig: {
      area: boolean;
      yAxisType?: string;
      xAxisSource: XAxisSourceType;
      xMin: number;
      xMax: number;
    };
    shortfallSettings?: ShortfallFields;
    planMetrics: (PlanMetric & SingleViewPlanMetric)[];
    uiId: string;
    checkPermission?: boolean;
    selectedMetricKey: string;
    showPreview?: boolean;
    retirementViewModel?: any;
    isSalesConcept?: boolean;
    isParentEnabled?: boolean;
    isSinglePolicy?: boolean;
    carrierPlanId?: number | string;
    isPermitted?: boolean;
    selectedPlanId?: number;
    spreadsheetDrawConfig?: SpreadsheetDrawConfig;
  };
  filteredConfigs: any[];
  resetActivePlans: any;
  url?: string;
  dependentPagesLocked?: boolean;
  isEndPage?: boolean;
  isRequiredCoverSheet?: boolean;
  isDependentPage?: boolean;
  isDisabled?: boolean;
  label?: string;
  uiId?: string;
  toggleDisabled?: boolean;
  dragDisabled?: boolean;
  order?: number;
  isCoverSheet?: boolean;
  parentUiId?: string;
  // TODO: need to change
  id: any;
  type: any;
  children: any;
  active: any;
}

export interface GlobalConfig extends ConfigBase {
  config: {
    activePlans: Array<ActivePlan>;
    chartConfig: {
      area: boolean;
      yAxisType?: string;
      xAxisSource: XAxisSourceType;
      xMin: number;
      xMax: number;
    };
    plansTableFields: PlansTableField[];
    locked: boolean;
    bottomIrr: null | number;
    topIrr: null | number;
    maxAgeDisplay: null | number;
    minAgeDisplay: null | number;
    selectedPageId: string;
    pdfPrintAll: boolean;
    uiId: string;
    isParentEnabled?: boolean;
    showSubmitApplicationButtonVisible: boolean;
    spreadsheetDrawConfig?: SpreadsheetDrawConfig;
  };
  isDefault: boolean;
  hasActivePlans: boolean;
  isParentSalesConcept?: boolean;
}

export interface DataLimits {
  bottomIrr: null | number;
  topIrr: null | number;
  maxAgeDisplay: null | number;
  minAgeDisplay: null | number;
}

export interface DataLimitsSource extends DataLimits {
  xAxisSource: XAxisSourceType;
}

export interface SelectedPlanOptions {
  planId: string | number;
  value: number | string;
  additionalLabel: string;
  active: boolean;
  color: string;
  isShownInfo?: boolean;
  options: SelectedPlansOptions;
  toCreate?: boolean;
  taxFreeDBRemaining?: number;
  toRemove?: boolean;
}

export interface ShareableLinkResponse {
  token: string;
  clientName: string;
  clientName2?: string;
}

export enum PresentationModalType {
  PRESENTATION_SHARING = 'presentationSharing',
  SALES_CONCEPT_SETTINGS = 'salesConceptsSettings',
  SELECT_VERSION = 'selectVersion',
}

export interface ModalRegistry {
  [key: string]: ModalRef;
}
