import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DragulaModule, DragulaService } from 'ng2-dragula';

import { ButtonsModule, FormControlsModule } from '@se/common';
import { LoaderModule, ModalModule } from '@assurance/bootstrap';

import { NavbarComponent } from './navbar.component';
import { NavbarService } from './navbar.service';
import { NavbarPagesAdapterService, NavbarPagesService } from '@shared/services';
import { PdfLoadingMessageModule, TogglesModule } from '@shared/components';
import { DirectivesModule } from '@shared/directives/directives.module';
import { NoteInputModule } from '@shared/components/note-input/note-input.module';
import { SharedModalsModule } from '@shared/components/modal/shared-modals.module';
import { ModalProviderService } from 'src/app/modules/custom-pages/services';
import { NgxUploaderModule } from 'ngx-uploader';
import { QuoteBtnComponent } from './quote-btn/quote-btn.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { UmMiscModule } from '@assurance/um-misc';
import { LocalStorageService } from '@core/service';

@NgModule({
  declarations: [NavbarComponent, QuoteBtnComponent, LoginModalComponent],
  exports: [NavbarComponent],
  imports: [
    CommonModule,
    DragulaModule,
    RouterModule,
    FormsModule,
    NgxUploaderModule,
    SharedModalsModule,
    TogglesModule,
    PdfLoadingMessageModule,
    ButtonsModule,
    LoaderModule,
    ReactiveFormsModule,
    FormControlsModule,
    NoteInputModule,
    DirectivesModule,
    BsDatepickerDirective,
    ModalModule,
    UmMiscModule,
  ],
  providers: [
    NavbarService,
    DragulaService,
    NavbarPagesService,
    NavbarPagesAdapterService,
    ModalProviderService,
    LocalStorageService,
  ],
})
export class NavbarModule {}
