import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as apiConstant from '@core/constant';

@Injectable()
export class SupportInfoService {
  constructor(private http: HttpClient) {}

  getSupportInfo<T>(): Observable<T> {
    return this.http.get<T>(apiConstant.support);
  }
}
