import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ElementRef,
  ViewChild,
  HostListener,
} from '@angular/core';

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomDropdownComponent {
  @ViewChild('trigger') triggerEl!: ElementRef;
  @Input() options: Array<{ value: string }> = [];
  @Input() uiId: string = '';
  @Input() selectedValue: string = '';
  @Output() valueChange = new EventEmitter<string>();

  isOpen = false;

  listPositionTop = 0;
  listPositionLeft = 0;
  listWidth = 0;

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  toggleDropdown(): void {
    this.isOpen = !this.isOpen;

    if (this.isOpen) {
      this.updatePosition();
    }
  }

  updatePosition(): void {
    if (this.triggerEl) {
      const { bottom, left, width } = this.triggerEl.nativeElement.getBoundingClientRect();

      this.listPositionTop = bottom;
      this.listPositionLeft = left;
      this.listWidth = width;
    }
  }

  selectOption(value: string): void {
    this.valueChange.emit(value);
    this.isOpen = false;
  }
}
