import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { map, switchMap } from 'rxjs/operators';
// import { groupBy } from 'lodash-es';

import { TreeItem, TreeNode } from '@assurance/bootstrap';
// import { ContentApiService } from '@shared/services';
import { isDefined } from '@core/utils';
import { CustomPage, Insert, InsertIds } from '@shared/models';
import { INSERT_SECTIONS_LABELS } from '../../constants';
import { AppState } from '../../../../reducers';
import { ChartPlaceholder } from '@core/model';
import { getSelectedCustomPage, setInserts } from '../../redux';
import { CustomPageApiService } from '../custom-page-api/custom-page-api.service';

@Injectable()
export class TreeInsertsService {
  constructor(private store: Store<AppState>, private customPageApiService: CustomPageApiService) {}

  getTreeOfInserts(): Observable<TreeItem[]> {
    return this.store.select(getSelectedCustomPage).pipe(
      switchMap((customPage: CustomPage) =>
        this.customPageApiService.getCustomPage(customPage._id, { showAllInsertsUsages: true })
      ),
      map((fetchedCustomPage: CustomPage) => this.addOrderToInsert(fetchedCustomPage)),
      map((inserts: Insert[]) => this.createTreeOfInserts(inserts))
    );
  }

  setSelectedInserts(selectedInserts: Insert[]): void {
    const inserts: Insert[] = selectedInserts.filter(item => isDefined<Insert>(item)) as any;

    this.store.dispatch(setInserts({ inserts }));
  }

  private addOrderToInsert(customPage: CustomPage): Insert[] {
    return customPage.inserts
      .map((insert: Insert) => {
        const foundByUiId = customPage.customFields.insertIds.find(
          (item: InsertIds) => insert.metadata.id === item.uuid
        );

        return foundByUiId ? { ...insert, metadata: { ...insert.metadata, order: foundByUiId.order } } : insert;
      })
      .sort((a, b) => a.metadata.order - b.metadata.order);
  }

  // need to remove in case we will go with a way not save selected inserts
  // private mapInserts(inserts: Insert[]): Observable<Insert[][]> {
  //   return this.store.select(Selectors.getSelectedInserts).pipe(map(selectedInserts => [inserts, selectedInserts]));
  // }

  // private createTreeOfInserts(data: Insert[][]): TreeItem[] {
  //   const [inserts, selectedInserts] = data;
  //   const groupedInsert = groupBy(inserts, 'metadata.insertType');
  //
  //   const tree = Object.keys(groupedInsert).map((key: string) => {
  //     const title = INSERT_SECTIONS_LABELS.find(section => section.type === key).label;
  //
  //     const children = groupedInsert[key].map((insert: Insert) => {
  //       const title = insert.metadata.chartName || insert.metadata.placeholderName || 'Products description';
  //       const selectedInsert = selectedInserts ? selectedInserts.findIndex((i: Insert) => i._id === insert._id) : -1;
  //       const selected = selectedInsert !== -1;
  //
  //       return new TreeNode(title, insert as any, null, selected);
  //     });
  //
  //     return new TreeNode(title, null, children);
  //   });
  //
  //   return [new TreeNode('SELECT ALL', null, tree)];
  // }

  // private createTreeOfInserts(inserts: Insert[]): TreeItem[] {
  //   const groupedInsert = groupBy(inserts, 'metadata.insertType');
  //
  //   const tree = Object.keys(groupedInsert).map((key: string) => {
  //     const title = INSERT_SECTIONS_LABELS.find(section => section.type === key).label;
  //
  //     const children = groupedInsert[key].map((insert: Insert) => {
  //       const title = insert.metadata.chartName || insert.metadata.placeholderName || 'Products description';
  //
  //       return new TreeNode(title, insert as any, null, false);
  //     });
  //
  //     return new TreeNode(title, null, children);
  //   });
  //
  //   return [new TreeNode('SELECT ALL', null, tree)];
  // }

  private createTreeOfInserts(inserts: Insert[]): TreeItem[] {
    const tree = [];

    for (let index = 0; inserts.length > index; index++) {
      const insert = inserts[index];
      const insertType = insert.metadata.insertType;
      const titleSection = INSERT_SECTIONS_LABELS.find(section => section.type === insertType).label;
      const titleInsert =
        (insert.metadata as ChartPlaceholder).chartName || insert.metadata.placeholderName || 'Products description';

      const treeNode = new TreeNode(titleInsert, insert as any, null, false) as any;

      if (index !== 0 && inserts[index - 1].metadata.insertType === insertType) {
        const existedTree: TreeNode = [...tree].reverse().find((tree: TreeNode) => tree.getTitle() === titleSection);

        if (existedTree) {
          existedTree.addChildren([treeNode]);
        }
      } else {
        tree.push(new TreeNode(titleSection, null, [treeNode]));
      }
    }

    return [new TreeNode('SELECT ALL', null, tree)];
  }
}
