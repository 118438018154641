import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import * as apiConstant from '@core/constant';
import { ResponseType } from '@core/model';
import { HttpClient } from '@angular/common/http';
import { RoleCategory } from '@assurance/um-services';
import { Observable } from 'rxjs';

@Injectable()
export class RoleApiService {
  constructor(private http: HttpClient) {}

  getAllRoleCategories(): Observable<RoleCategory[]> {
    return this.http
      .get<ResponseType<RoleCategory[]>>(`/um/v1${apiConstant.roleCategory}?includeRoles=true`)
      .pipe(map((res: ResponseType<RoleCategory[]>) => res.data || []));
  }
}
