import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LOCAL_STORAGE_TOKEN, LOCATION_TOKEN, WINDOW_TOKEN, BROWSER_SESSION_STORAGE } from './constant';
import {
  BrowserSessionStorage,
  HttpErrorHandlerService,
  LocalStorageService,
  SentryErrorHandler,
  UserPilotService,
} from '@core/service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    LocalStorageService,
    BrowserSessionStorage,
    HttpErrorHandlerService,
    UserPilotService,
    { provide: WINDOW_TOKEN, useValue: window },
    { provide: LOCAL_STORAGE_TOKEN, useValue: localStorage },
    { provide: LOCATION_TOKEN, useValue: location },
    { provide: BROWSER_SESSION_STORAGE, useValue: sessionStorage },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
