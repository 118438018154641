import { RouteConf } from '@core/model';

export const routeConf: Record<string, RouteConf> = {
  home: {
    activeElement: 'home',
  },
  login: {
    activeElement: 'login',
  },
  plans: {
    name: 'PRSNT_EDIT',
  },
  setup: {
    name: 'PRSNT_EDIT',
  },
  //TODO: need to remove once PresentationView will be removed
  view: {
    name: 'PRSNT_MODE',
    view: true,
  },
  'xml-statuses': {
    name: 'isAssuranceAdmin',
  },
};
