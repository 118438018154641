import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SupportInfoComponent } from './support-info.component';
import { SupportInfoService } from './support-info.service';

@NgModule({
  declarations: [SupportInfoComponent],
  imports: [CommonModule],
  exports: [SupportInfoComponent],
  providers: [SupportInfoService],
})
export class SupportInfoModule {}
