export const TimeFormat = {
  MMDDYYYY: 'MM/DD/YYYY',
  MMddyyyy: 'MM/dd/yyyy',
  MMDDYYYYhmma: 'MM/DD/YYYY h:mm a',
  YYYYMMDDhmma: 'YYYY/MM/DD h:mm a',
  MMDDYYYYhmmssa: 'MM/DD/YYYY h:mm:ss a',
  MMDDYYYYhhmmss: 'MM/dd/YYYY HH:mm:ss',
  YYYYMMDDHHmmss: 'YYYY-MM-DD HH:mm:ss',
  ddMMMyyyy: 'dd MMM yyyy',
  GA4_YYYYMMDDhhmmss: 'YYYYMMDDhhmmss',
  YYYYMMDDhhmmss: 'YYYY-MM-DD_hh-mm-ss',
  MMDDYYYYatHHmm: 'MM.DD.YYYY [at] HH:mm',
  Mdyyyyhmma: 'M/d/yyyy h:mm a',
  MDYYYYhmmA: 'M/D/YYYY h:mm A',
  MdyyyyhmmA: 'M/d/yyyy h:mm A',
  YYYYMMDDHHmmssSSSZ: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
  MMDDYYYYhmmssA: 'MM/DD/YYYY h:mm:ss A',
};
