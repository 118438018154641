import * as fileSaver from 'file-saver';
import { Observable, Subscriber, Subscription, combineLatest, of, Subject, iif } from 'rxjs';
import { filter, finalize, first, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';

import { IAMService, UmNodeAPIService } from '@assurance/um-services';
import { AlertService } from '@se/common';

import {
  ERROR_MSG_PDF,
  LETTER_LANDSCAPE_WIDTH,
  LETTER_PORTRAIT_WIDTH,
  PDF_SCALE,
  CUSTOM_PAGE_CONFIGS_SESSION_STORAGE_KEY,
} from '@shared/constants';
import {
  PDFResponse,
  PDFRequest,
  InsertOptions,
  PageOptions,
  StorageOptions,
  GlobalConfig,
  PageConfig,
  CustomPageValues,
  NavbarCustomPagesData,
} from '@shared/models';
import {
  PRESENTATION_CONFIGS_SESSION_STORAGE_KEY,
  PRODUCTS_SESSION_STORAGE_KEY,
} from '../../../components/presentation/setup/setup.constant';
import { getCoverLetterWithRequired } from '../../../components/presentation/modals/cover-sheet-modal/cover-sheet-modal.selectors';
import { Global, DownloadApiService } from '@shared/services';
import { AppState } from '../../../reducers';
import { globalPdfGeneration } from '@ngrx-app/global.actions';
import { getCoverLetter, getDependentPermissions, isCoverLandscapeOrientation } from '@ngrx-app/global.selectors';
import {
  getDependentPagesConfig,
  getSalesConceptDependentLoaded,
  getNavbarDependentPages,
  getNavbarEndPages,
  getNavbarCustomPages,
} from '../../../components/presentation/setup/setup.selectors';
import { PDFGenerationService } from '../../../components/presentation/pdf-generation/pdf-generation.service';
import { rdiffResult } from 'recursive-diff';
import { LocalStorageService } from '@core/service';
import { PermissionKeyEnum } from '@core/enums';
import { IFrameCommunicationService } from './iframe-communication/iframe-communication.service';
import { EndPagesAdapterService } from './end-pages-adapter/end-pages-adapter.service';
import { IframeActions } from '@ensight/shim-app';

export abstract class SocketService {
  public fileName: string;
  public getExportedPDFSubscription: Subscription;
  public readonly progress$ = new Subject<PDFResponse>();
  private socket: WebSocket;
  private hasSharedToken: string;

  constructor(
    public global: Global,
    public iamService: IAMService,
    public umNodeAPIService: UmNodeAPIService,
    public downloadApiService: DownloadApiService,
    public pdfGenerationService: PDFGenerationService,
    public alertService: AlertService,
    public store: Store<AppState>,
    public localStorage: LocalStorageService,
    public iframeService: IFrameCommunicationService,
    public endPagesAdapterService: EndPagesAdapterService
  ) {}

  public abstract sendPDFExport(
    id: number | string,
    target: string,
    shareableLink: string,
    creationDate: string,
    pages?: string[]
  ): void;

  public destroyPDFListener(): void {
    this.fileName = null;
    this.unsubscribe();
  }

  protected sendMessage<T extends { id: string }>(data: T): void {
    this.initSockets();
    this.socket.onopen = (): void => {
      this.listenExportedPDF();
      const message = JSON.stringify(this.extendSocketRequestObj(data));
      this.socket.send(message);
    };
  }

  protected generatePagesQueryParams(pages: string[], staticPages = false): string {
    const params = new URLSearchParams();

    for (const page of pages) {
      params.append('pdfPage', page);
    }

    if (staticPages) {
      params.append('staticPages', 'true');
    }

    return params.toString();
  }

  protected generatePDFRequest(
    url: string,
    singlePage = false,
    configs: (GlobalConfig & PageConfig)[],
    landscape = true,
    sharedToken: string | null = null,
    creationDate: string,
    diff?: rdiffResult[],
    shareableLink?: string
  ): Observable<PDFRequest> {
    const pdfTimeout = Number(this.localStorage.getNotJSONData('pdfGenerationTimeout')) || 150000;
    const getToken = iif(() => !!sharedToken, of({ bearer: null }), this.umNodeAPIService.getToken());
    const creationDateValue = this.sendCreationDate() ? creationDate : undefined;

    return getToken.pipe(
      switchMap(({ bearer }) => {
        return singlePage
          ? of({
              id: this.uuidv4(),
              pages: [this.generateConfig(bearer, url, pdfTimeout, undefined, landscape)],
              inserts: this.setPDFInserts(creationDateValue, shareableLink),
            })
          : this.store.select(getSalesConceptDependentLoaded).pipe(
              withLatestFrom(this.store.select(getDependentPermissions)),
              filter(([loaded, dependentPermissions]) => loaded || !dependentPermissions.salesConcepts),
              switchMap(() =>
                combineLatest([
                  this.store.select(isCoverLandscapeOrientation),
                  this.store.select(getCoverLetter),
                  this.store.select(getCoverLetterWithRequired),
                  // this.store.select(getEndPages).pipe(switchMap(data => this.global.filterEndPages(data, true))),
                  this.store
                    .select(getNavbarEndPages)
                    .pipe(switchMap(data => this.endPagesAdapterService.filterEndPages(data, true))),
                  // this.store.select(getDependentPages),
                  this.store.select(getNavbarDependentPages),
                  this.store.select(getDependentPagesConfig),
                  // this.store.select(getSalesConcepts),
                  this.store
                    .select(getNavbarCustomPages)
                    .pipe(map((data: NavbarCustomPagesData) => data.salesConcepts)),
                ]).pipe(first())
              ),
              map(
                ([
                  isLandscape,
                  coverLetter,
                  coverLettersRequiredData,
                  endPages,
                  dependentPages,
                  dependentPagesConfig,
                  salesConceptPages,
                ]) => {
                  const pageConfig = this.pdfGenerationService.getPageConfig(
                    coverLetter,
                    coverLettersRequiredData.coverLettersRequired,
                    endPages,
                    cloneDeep(dependentPages),
                    cloneDeep(dependentPagesConfig?.configs),
                    cloneDeep(salesConceptPages),
                    configs
                  );
                  // @ts-ignore
                  const pages = pageConfig.map((page: PageConfig & CustomPageValues) => {
                    let landscape = true;
                    const params = new URLSearchParams();
                    params.append('pdfPage', page.config.uiId);

                    if (page.config.isSalesConcept) {
                      params.append('isSalesConcept', 'true');
                    } else if (page.isEndPage) {
                      params.append('isEndPage', 'true');
                    } else if (page.isDependentPage) {
                      params.append('pdfPageParentUiId', page.parentUiId);
                      params.append('isDependentPage', 'true');
                    } else if (page.internalId === 99) {
                      params.append('isCoverLetter', 'true');
                      landscape = isLandscape;
                    }

                    const customPage = [...endPages, ...dependentPages, ...salesConceptPages].find(
                      item => item.config.uiId === page.config.uiId
                    );

                    if (customPage) {
                      landscape = customPage.layout === 'landscape';
                    }

                    return this.generateConfig(
                      bearer,
                      url,
                      pdfTimeout,
                      page.pageName || (page.label as string),
                      landscape,
                      params
                    );
                  });
                  const options = {
                    id: this.uuidv4(),
                    pages,
                    inserts: this.setPDFInserts(creationDateValue, shareableLink),
                  };

                  if (sharedToken) {
                    this.hasSharedToken = sharedToken;
                    Object.assign(options, {
                      storage: this.setPDFStorage(diff),
                    });
                  }

                  return options;
                }
              )
            );
      }),
      first()
    );
  }

  private unsubscribe(): void {
    if (this.socket) {
      this.socket.close();
    }

    if (this.getExportedPDFSubscription) {
      this.getExportedPDFSubscription.unsubscribe();
    }
  }

  private listenExportedPDF(): void {
    this.getExportedPDFSubscription = this.getExportedPDF().subscribe(
      response => {
        if (response.pending) {
          this.progress$.next(response);
          this.log(response, 'log');

          return;
        }

        if (response.success && response.data) {
          this.progress$.next(response);
          this.log(response, 'info');
          this.downloadFile(response);
          this.store.dispatch(globalPdfGeneration({ payload: false }));
          this.iframeService.sendResponse(IframeActions.PrintPdf, { isPDFLoaded: true });

          this.unsubscribe();
        }
      },
      errorResponse => {
        this.progress$.next(errorResponse);
        this.log(errorResponse, 'error');
        this.alertService.openAlert({
          type: 'error',
          body: ERROR_MSG_PDF,
          autoClose: 5000,
        });
        this.store.dispatch(globalPdfGeneration({ payload: false }));
        this.iframeService.sendResponse(IframeActions.PrintPdf, { isPDFLoaded: true });
        this.unsubscribe();
      }
    );
  }

  private initSockets(): void {
    this.socket = new WebSocket(this.localStorage.getNotJSONData('websocketURL'));
  }

  private getExportedPDF(): Observable<PDFResponse> {
    return new Observable((observer: Subscriber<PDFResponse>): void => {
      this.socket.addEventListener('message', (response: MessageEvent) => {
        try {
          const result = JSON.parse(response.data) as PDFResponse;

          if (result.error) {
            throw result;
          }

          observer.next(result);

          if (result.success) {
            observer.complete();
          }
        } catch (error) {
          observer.error(error);
        }
      });
    });
  }

  private downloadFile(data: PDFResponse): void {
    this.downloadApiService
      .downloadPDF(data.data, this.hasSharedToken)
      .pipe(finalize(() => (this.hasSharedToken = null)))
      .subscribe((fileBuffer: Blob) => {
        let hasGroupPermission = false;

        if (this.global.isSharedPresentation()) {
          const groupPermissions$ = this.store.select(getDependentPermissions);

          groupPermissions$.subscribe(res => {
            hasGroupPermission = res.groupPermission.includes(PermissionKeyEnum.use_presentation_name_as_pdf_file_name);
          });
        } else {
          hasGroupPermission = this.iamService.hasGroupAccess(PermissionKeyEnum.use_presentation_name_as_pdf_file_name);
        }

        const blob = new Blob([fileBuffer], { type: 'application/pdf' });
        const PDFFileName =
          hasGroupPermission && this.global.getPresentation?.name
            ? this.global.getPresentation?.name
            : this.fileName || this.global.getPresentation.clientname;

        fileSaver.saveAs(blob, `${PDFFileName}.pdf`);
      });
  }

  private uuidv4(): string {
    return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );
  }

  private extendSocketRequestObj<T extends { id: string }>(data: T): Record<string, unknown> {
    return {
      action: 'sendmessage',
      application: 'EPV',
      type: 'PDF_COMPOSITE_SUBSCRIPTION',
      payload: {
        ...data,
        transactionUuid: data.id,
      },
    };
  }

  private generateConfig(
    bearer: string,
    url: string,
    pdfTimeout: number,
    pageName = 'Page',
    landscape = true,
    params: URLSearchParams = null
  ): PageOptions {
    const searchParams = params ? `?${params.toString()}` : '';
    const domainName = this.localStorage.getNotJSONData('domainName');

    return {
      pageName,
      pdfOptions: {
        id: this.uuidv4(),
        options: {
          format: 'Letter',
          landscape,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
          scale: PDF_SCALE,
          viewport: {
            // for correct SVG chart rendering.
            width: Math.ceil((landscape ? LETTER_LANDSCAPE_WIDTH : LETTER_PORTRAIT_WIDTH) / PDF_SCALE),
            height: 1080,
          },
          timeout: pdfTimeout,
          cookies: bearer
            ? [
                {
                  name: 'Authorization',
                  value: bearer,
                  domain: `.${domainName}`,
                },
                {
                  name: 'loggedIn',
                  value: 'true',
                  domain: `.${domainName}`,
                },
              ]
            : [],
        },
        url: `${location.origin}/${url}${searchParams}`,
        // url: `http://host.docker.internal:8082/${url}${searchParams}`,
        waitSelector: '#rendered',
        disableMessageSending: true,
      },
    };
  }

  private setPDFInserts(creationDate: string | undefined, shareableLink?: string): InsertOptions {
    return {
      footer: {
        pagination: true,
        rightText: creationDate ? `Created ${creationDate}` : null,
        shareableLink,
      },
    };
  }

  private setPDFStorage(diff: rdiffResult[]): StorageOptions {
    const storage = [
      {
        key: PRESENTATION_CONFIGS_SESSION_STORAGE_KEY,
        value: diff,
      },
      {
        key: CUSTOM_PAGE_CONFIGS_SESSION_STORAGE_KEY,
        value: JSON.parse(sessionStorage.getItem(CUSTOM_PAGE_CONFIGS_SESSION_STORAGE_KEY)),
      },
    ];

    const products = JSON.parse(sessionStorage.getItem(PRODUCTS_SESSION_STORAGE_KEY));

    if (products?.length) {
      storage.push({
        key: PRODUCTS_SESSION_STORAGE_KEY,
        value: products,
      });
    }

    return {
      sessionStorage: storage,
    };
  }

  private log(response: PDFResponse, type: 'info' | 'log' | 'error'): void {
    if (this.localStorage.getNotJSONData('env') === 'production') {
      return;
    }

    console[type](`PDF ${type} -> id: ${response.id}. state: ${response.state}%, message: ${response.message}`);
  }

  private sendCreationDate(): boolean {
    let hasGroupPermission = false;

    if (this.global.isSharedPresentation()) {
      this.store.select(getDependentPermissions).subscribe(res => {
        hasGroupPermission = res.groupPermission.includes(PermissionKeyEnum.hide_created_date_on_pdf);
      });
    } else {
      hasGroupPermission = this.iamService.hasGroupAccess(PermissionKeyEnum.hide_created_date_on_pdf);
    }

    return !hasGroupPermission;
  }
}
