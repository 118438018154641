<div class="modal-container bg-white">
  <ac-modal-header class="modal-header p-0 d-flex justify-content-between border-bottom-0" (close)="closeModal()">
    <div class="modal-header-title">
      {{ editMode ? title : activeInsertTypeState?.title }}
    </div>
  </ac-modal-header>

  <ac-modal-body class="modal-body w-100 p-0 text-left" [ngClass]="{'chart-form': chartConfigurationStateId}">
    @switch (activeInsertTypeState?.id) { @case (chartConfigurationStateId) {
    <ep-configuration
      [configurationStateId]="chartConfigurationStateId"
      [plansConfigData]="config.data.plansConfigData"
      [plansConfigMeta]="config.data.plansConfigMeta"
      [id]="config.data.id"
    ></ep-configuration>
    } @case (chartStyleConfigurationStateId) {
    <ep-configuration
      [configurationStateId]="chartStyleConfigurationStateId"
      [id]="config.data.id"
    ></ep-configuration>
    } @case (textConfigurationStateId) {
    <ep-configuration
      [configurationStateId]="textConfigurationStateId"
      [id]="config.data.id"
    ></ep-configuration>
    } @case (productsDescriptionStateId) {
    <ep-configuration
      [configurationStateId]="productsDescriptionStateId"
      [id]="config.data.id"
    ></ep-configuration>
    } @case (apiCallConfigurationStateId) {
    <ep-configuration
      [configurationStateId]="apiCallConfigurationStateId"
      [id]="config.data.id"
    ></ep-configuration>
    } @case (postApiCallStateId) {
    <ep-configuration
      [configurationStateId]="postApiCallStateId"
      [id]="config.data.id"
    ></ep-configuration>
    } @case (apiCallResponseStateId) {
    <ep-multiple-configurations
      [configurationStateId]="apiCallResponseStateId"
      [id]="config.data.id"
    ></ep-multiple-configurations>
    } @case (customTableStateId) {
    <ep-configuration
      [configurationStateId]="customTableStateId"
      [id]="config.data.id"
    ></ep-configuration>
    } @case (customTableColumnsStateId) {
    <ep-multiple-configurations
      [configurationStateId]="customTableColumnsStateId"
      [id]="config.data.id"
    ></ep-multiple-configurations>
    } @case (customMetricStateId) {
    <ep-custom-metrics
      [id]="config.data.id"
      [editMode]="editMode"
      [configurationStateId]="customMetricStateId"
      [customMetricFormulaStateId]="customMetricFormulaStateId"
    ></ep-custom-metrics>
    } @case (variableConfigurationStateId) {
    <ep-configuration
      [configurationStateId]="variableConfigurationStateId"
      [id]="config.data.id"
    ></ep-configuration>
    } @case (buttonConfigurationStateId) {
    <ep-configuration
      [configurationStateId]="buttonConfigurationStateId"
      [id]="config.data.id"
    ></ep-configuration>
    } @case (chartMetricsStateId) {
    <ep-multiple-configurations
      [plansConfigData]="config.data.plansConfigData"
      [plansConfigMeta]="config.data.plansConfigMeta"
      [configurationStateId]="chartMetricsStateId"
      [id]="config.data.id"
    ></ep-multiple-configurations>
    } @case (insertTypeStateId) {
    <ep-insert-type [selectedInsertType]="selectedInsertType"></ep-insert-type>
    } @case (customPageStateId) {
    <ep-choose-custom-page></ep-choose-custom-page>
    } @case (insertsStateId) {
    <ep-tree-inserts></ep-tree-inserts>
    } @case (imageStateId) {
    <ep-set-image
      [id]="config.data.id"
      (uploadImage)="setImageOnUpload($event)"
      (selectImage)="setImageSelect($event)"
      (deleteImage)="setImageDelete($event)"
      (loadedImage)="setImageLoaded($event)"
    ></ep-set-image>
    } @case (dropdownStateId) {
    <ep-configuration
      [configurationStateId]="dropdownStateId"
      [id]="config.data.id"
    ></ep-configuration>
    } @case (productSelectorStateId) {
    <ep-configuration
      [configurationStateId]="productSelectorStateId"
      [id]="config.data.id"
    ></ep-configuration>
    } @case (tabStateId) {
    <ep-configuration
      [configurationStateId]="tabStateId"
      [id]="config.data.id"
    ></ep-configuration>
    } @case (tabAreaStateId) {
    <ep-multiple-configurations
      [configurationStateId]="tabAreaStateId"
      [id]="config.data.id"
    ></ep-multiple-configurations>
    } }
  </ac-modal-body>

  <ac-modal-footer class="modal-footer d-flex align-items-center justify-content-between border-0 p-0">
    @if (isRequiredFieldsLabelShown) {
    <span data-at-id="all-fields-required">All fields are required</span>
    }
    <div class="buttons-block">
      @if (isBackBtnShown) {
      <se-button class="secondary" (click)="back()">Back</se-button>
      }
      <se-button
        (click)="next()"
        [disabled]="!isValidForm || !selectedInsertType"
      >
        {{ buttonLabel }}
      </se-button>
    </div>
  </ac-modal-footer>
</div>
