import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as apiConstant from '@core/constant';
import { CareerPlan, Presentation, Presentations, ResponseType } from '@core/model';
import { getSearchQuery } from '@core/utils';
import {
  CustomPagesConfig,
  HomeTableRequestParams,
  PageConfig,
  PlanOption,
  PlansQuantity,
  ShareableLinkResponse,
} from '@shared/models';
import { map, Observable } from 'rxjs';
@Injectable()
export class PresentationApiService {
  constructor(private http: HttpClient) {}

  public distributeTemplate<T extends { add: number[]; remove: string[] }>(
    templateId: number,
    request: { organizations: T; groups: T }
  ) {
    return this.http.post<ResponseType<any>>(
      `${apiConstant.presentation}/${templateId}/${apiConstant.distribute}`,
      request
    );
  }

  public copyPresentation(data: {
    agencyIds: number[];
    presentationsIds: number[];
    setRevisionHistory: boolean;
    storeCopyCount: boolean;
  }) {
    return this.http.post<ResponseType<any>>(`${apiConstant.presentation}/${apiConstant.clone}`, data);
  }

  public deleteCustomPageFromPresentation(presentationId: number, uiId: string) {
    return this.http.delete<ResponseType<any>>(
      `${apiConstant.presentation}/${presentationId}/${apiConstant.setupConfigs}/custom-page/${uiId}`
    );
  }

  public addPageConfigToPresentation<T>(presentationId: number, data: T): Observable<ResponseType<any>> {
    return this.http.post<ResponseType<any>>(
      `${apiConstant.presentation}/${presentationId}/${apiConstant.pageConfig}`,
      data
    );
  }

  public replacePageConfigInPresentation(presentationId: number, data: PageConfig): Observable<ResponseType<any>> {
    return this.http.put<ResponseType<any>>(
      `${apiConstant.presentation}/${presentationId}/${apiConstant.pageConfig}`,
      data
    );
  }

  public getPresentations(
    query: Record<string, string | number | boolean> | HomeTableRequestParams,
    productType?: string,
    withDeletedUsers?: boolean,
    templates = false,
    count = false
  ): Observable<ResponseType<Presentations>> {
    const restUrl = count ? apiConstant.presentationCount : apiConstant.presentation;

    if (productType) {
      Object.assign(query, { product_type: productType });
    }

    if (withDeletedUsers) {
      Object.assign(query, { DELETED_USERS: withDeletedUsers });
    }

    if (templates) {
      Object.assign(query, { TEMPLATE: templates });
    }

    return this.http.get<ResponseType<Presentations>>(restUrl, {
      params: getSearchQuery(query),
    });
  }

  public getPlansCountByIds(ids: number[]): Observable<ResponseType<PlansQuantity>> {
    return this.http.post<ResponseType<PlansQuantity>>(apiConstant.presentationsPlansCount, ids);
  }

  public updateVersionAddedDate(
    presentationId: number,
    customPageUUID: string,
    addedDate: string
  ): Observable<ResponseType<{ success: boolean }>> {
    const url = `${apiConstant.presentation}/${presentationId}/${apiConstant.setupConfigs}/${customPageUUID}/version`;

    return this.http.patch<ResponseType<{ success: boolean }>>(url, { addedDate: addedDate });
  }

  public patchPresentation(id: number, data: any) {
    const restUrl = `${apiConstant.presentation}/${id}/update`;

    return this.http.patch<ResponseType<any>>(restUrl, data);
  }

  public removePresentation(id: number) {
    return this.http.delete<ResponseType<any>>(`${apiConstant.presentation}/${id}`);
  }

  public getPresentationMetadataList() {
    return this.http.get<ResponseType<any>>(`${apiConstant.presentation}/${apiConstant.metadata}/list`);
  }

  public updatePlansOrder(data: PlanOption[], presentationId: number) {
    const restUrl = `${apiConstant.presentation}/${presentationId}/${apiConstant.plansOrder}`;

    return this.http.put<ResponseType<any>>(restUrl, {
      plansOrder: data,
      presentationId,
    });
  }

  public getSetupPageConfig(presentationId: number, presentationType?: string) {
    const queryParams = {};

    if (presentationType) {
      Object.assign(queryParams, { type: presentationType });
    }

    const restUrl = `${apiConstant.presentation}/${presentationId}/${apiConstant.setupConfigs}`;

    return this.http.get<ResponseType<any>>(restUrl, {
      params: getSearchQuery(queryParams),
    });
  }

  public putSetupPageConfig(presentationId: number, data: any) {
    const restUrl = `${apiConstant.presentation}/${presentationId}/${apiConstant.setupConfigs}`;

    return this.http.put<ResponseType<any>>(restUrl, data);
  }

  public getDependentPageConfig(presentationId: number) {
    const restUrl = `${apiConstant.presentation}/${presentationId}/${apiConstant.dependentPagesConfigs}`;

    return this.http.get<ResponseType<any>>(restUrl);
  }

  public putDependentPageConfig(presentationId: number, data: Partial<CustomPagesConfig>[]) {
    const restUrl = `${apiConstant.presentation}/${presentationId}/${apiConstant.dependentPagesConfigs}`;

    return this.http.put<ResponseType<any>>(restUrl, data);
  }

  public getPresentationConfig(presentationId: number): Observable<ResponseType<Presentation>> {
    return this.http.get<ResponseType<Presentation>>(`${apiConstant.presentation}/${presentationId}`);
  }

  public getPageConfig(presentationID: number, configKey: string) {
    const restUrl = `${apiConstant.presentation}/${presentationID}/${apiConstant.setupConfigs}/${configKey}`;

    return this.http.get<ResponseType<any>>(restUrl);
  }

  public putConfigByChunks(data: any, presentationID: number, configKey: string, uiId?: string) {
    const restUrl = `${apiConstant.presentation}/${presentationID}/${apiConstant.setupConfigs}/${configKey}`;

    return this.http.put<ResponseType<any>>(restUrl, data, {
      params: getSearchQuery({ uiId }),
    });
  }

  public createHistoryEvent(presentationID: number, data: any) {
    const restUrl = `${apiConstant.presentation}/${presentationID}/${apiConstant.events}`;

    return this.http.post<ResponseType<any>>(restUrl, data);
  }

  public updatePresentationClientName(presentationID: number, clientName: string) {
    const data = { clientName };
    const restUrl = `${apiConstant.presentation}/${presentationID}/${apiConstant.clientName}`;

    return this.http.put<ResponseType<any>>(restUrl, data);
  }

  public updatePresentationName(presentationID: number, presentationName: string, presentationNewName: string) {
    const data = { presentationNewName, presentationName };
    const restUrl = `${apiConstant.presentation}/${presentationID}/${apiConstant.presentationName}`;

    return this.http.put<ResponseType<any>>(restUrl, data);
  }

  public getPresentationsQty(query: any, productType?: string) {
    const restUrl = apiConstant.presentationQty;

    if (productType) {
      Object.assign(query, { product_type: productType });
    }

    return this.http.get<ResponseType<any>>(restUrl, {
      params: getSearchQuery(query),
    });
  }

  public getSubmittedPlans(data: Array<number | string>): Observable<ResponseType<CareerPlan[]>> {
    const restUrl = `${apiConstant.dropTicketPlans}`;

    return this.http.post<ResponseType<CareerPlan[]>>(restUrl, data);
  }

  //TODO: this method use in presentation-sharing.service
  getSharedToken(presentationId: number): Observable<string> {
    return this.http
      .get<ResponseType<ShareableLinkResponse>>(`${apiConstant.baseSharedUrl}token`, {
        params: getSearchQuery({ presentationId }),
      })
      .pipe(map(response => response.data.token));
  }
}
