import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() showUserMenu = true;
  disabledRedirect = false;
  private destroyRef = inject(DestroyRef);
  constructor(public router: Router) {}
  ngOnInit(): void {
    this.checkRouteChanges();
    this.checkRedirect();
  }

  private checkRouteChanges(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.checkRedirect();
      });
  }
  private checkRedirect(): void {
    const currentRoute = this.router.routerState.snapshot.root.firstChild;
    this.disabledRedirect = !!currentRoute?.data['disableRedirect'];
  }
}
