import { Injectable } from '@angular/core';

@Injectable()
export class DependentPermissionsService {
  private readonly dependentPermissionsKeys = ['coverPages', 'coverPagesRequired', 'salesConcepts', 'requiredEndPages'];

  public extractDependentPermissions(groupPermissions: string[]): Record<string, string[] | null> {
    let groupPermission = [];

    const permissions = this.dependentPermissionsKeys.reduce((obj, key) => {
      const values = this.getValuesFromPermissions(key, groupPermissions);

      obj[key] = values.length ? values : null;

      return obj;
    }, {});

    groupPermission = groupPermissions.filter(permission => {
      const [id] = permission.split('.');

      return !this.dependentPermissionsKeys.includes(id) && !permission.includes('.');
    });

    return { ...permissions, groupPermission };
  }

  /**
   * getValuesFromPermissions returns values (ex. ["dasq34ds", "asd13c"]) form permissions (ex. ["salesConcepts.dasq34ds", "salesConcepts.asd13c"])
   */
  private getValuesFromPermissions<T extends string | number>(key: T, permissions: string[]): string[] {
    return permissions.reduce((response, permission) => {
      const [id, value] = permission.split('.');

      if (id === key && value) {
        response.push(value);
      }

      return response;
    }, []);
  }
}
