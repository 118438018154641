import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, DestroyRef, inject } from '@angular/core';
import { SummaryTable } from '@core/class';
import { TABLE_LABELS } from '@shared/constants';
import {
  CHOOSE_CUSTOM_PAGE_CHANGELOG_HEADERS,
  DefaultChangelogOrderByRequestParam,
  DefaultPaginationOptions,
} from '../../constants/choose-custom-page-changelog.constants';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { HeaderItem, SortDirection, TableRequestParams } from '@shared/models';
import { UntypedFormControl } from '@angular/forms';
import { CustomPageChanges, CustomPageHistory, ChangelogSelectionMode } from '../../models';
import { ModalConfig, ModalRef } from '@assurance/bootstrap';
import { CustomPageService, CustomPageHistoryService, CustomPageHistoryAdapterService } from '../../services';
import { TimeService } from '@shared/services';
import { TimeFormat } from '@core/constant';
import { IPagerOptions } from '@se/common/app/navigations/components/pager/pager.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'ep-choose-custom-page-changelog',
  templateUrl: './choose-custom-page-changelog.component.html',
  styleUrl: './choose-custom-page-changelog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseCustomPageChangelogComponent extends SummaryTable implements OnInit {
  searchControl = new UntypedFormControl('');
  isDataLoading = false;
  selectedItem: CustomPageChanges;

  tableLabels = TABLE_LABELS;
  pagination: IPagerOptions = DefaultPaginationOptions;
  currentOrderByParams: TableRequestParams = DefaultChangelogOrderByRequestParam;

  records: CustomPageChanges[] = [];
  customPageInfo: ChangelogSelectionMode;
  private destroyRef = inject(DestroyRef);

  get queryParams(): TableRequestParams {
    return {
      DIRECTION: this.direction.toUpperCase() as SortDirection,
      PAGE_NUMBER: this.pagination.currentPage - 1,
      PAGE_SIZE: this.pagination.itemsPerPage,
      SEARCH_VALUE: this.searchControl?.value || '',
      SHOW_PUBLISHED: true,
      SORT_FIELD: this.sortField,
      TEMPLATE: false,
      DISABLE_FILTERS: true,
    };
  }

  constructor(
    public modal: ModalRef,
    public config: ModalConfig,
    private customPageService: CustomPageService,
    private customPageHistoryService: CustomPageHistoryService,
    private customPageHistoryAdapterService: CustomPageHistoryAdapterService,
    private timeService: TimeService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.customPageInfo = this.config.data;
    this.setTableHeadersData(CHOOSE_CUSTOM_PAGE_CHANGELOG_HEADERS);
    this.watchForSearch();
    this.loadCustomPageHistory();

    this.destroyRef.onDestroy(() => {
      this.customPageHistoryService.resetRequestParams();
    });
  }

  onCloseModal(): void {
    this.modal.close({ submitted: false });
  }

  onSelectLog(): void {
    if (this.selectedItem) {
      this.modal.close({
        submitted: true,
        selectedLog: this.selectedItem,
        isTargetLog: this.customPageInfo.isTargetLog,
      });
    }
  }

  onRowSelect(tableDataItem: any): void {
    this.selectedItem = this.records.find(record => record._id === tableDataItem._id);
  }

  onPageChange(event: IPagerOptions): void {
    this.pagination = {
      ...this.pagination,
      currentPage: event.currentPage,
    };
    this.loadCustomPageHistory();
  }

  onHeaderClick(headerItem: HeaderItem): void {
    if (headerItem.format?.sortable) {
      this.direction = this.direction === 'asc' ? 'desc' : 'asc';
      this.sortField = headerItem.key;

      this.currentOrderByParams = {
        DIRECTION: this.direction.toUpperCase() as SortDirection,
        SORT_FIELD: this.sortField,
      };

      this.loadCustomPageHistory();
    }
  }

  protected setTableData(): void {
    const startIndex = (this.pagination.currentPage - 1) * this.pagination.itemsPerPage;

    this.tableData = [
      {
        data: this.records?.map((record: CustomPageChanges, index: number) => {
          const logNumber = this.pagination.totalElements - startIndex - index;

          return {
            data: {
              ...record,
              _id: record._id,
              log: `${logNumber}`,
              title: record.title,
              pageName: record.pageName || '',
              numberOfChanges: record.numberOfChanges || 0,
              version: record.pageVersion || '',
              author: record.updatedBy || '',
              note: record.comment || '',
              updated: this.timeService.getFormattedDate(record.updated, TimeFormat.MMDDYYYYhmmssA),
            },
          } as any; // This table component requires TableDataItem type
        }),
      },
    ];
  }

  private watchForSearch(): void {
    this.searchControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => {
          this.isDataLoading = true;
          this.pagination.currentPage = 1;
        })
      )
      .subscribe(() => this.loadCustomPageHistory());
  }

  private loadCustomPageHistory(): void {
    this.isDataLoading = true;

    const tableRequestParams: TableRequestParams = {
      DIRECTION: this.currentOrderByParams.DIRECTION,
      ORDER_BY: this.currentOrderByParams.SORT_FIELD,
      PAGE: this.pagination.currentPage,
      PAGE_SIZE: this.pagination.itemsPerPage,
      SEARCH_VALUE: this.searchControl.value || '',
    };

    this.customPageService
      .getCustomPageHistory(this.customPageInfo.uiId, this.customPageInfo.labels, tableRequestParams)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        (data: CustomPageHistory) => this.setHistoryData(data),
        () => {
          this.isDataLoading = false;
          this.cdr.markForCheck();
        }
      );
  }

  private setHistoryData(data: CustomPageHistory): void {
    this.pagination = {
      ...this.pagination,
      totalElements: data.count,
      hasNextPage: data.currentPage < Math.ceil(data.count / this.pagination.itemsPerPage),
    };

    this.records = this.customPageHistoryAdapterService.adaptHistory(data, this.customPageInfo.labels).data;
    this.setTableData();

    this.isDataLoading = false;
    this.cdr.markForCheck();
  }
}
