import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { DragulaModule, DragulaService } from 'ng2-dragula';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import {
  ButtonModule,
  LoaderModule,
  ControlModule,
  CounterModule,
  DropdownModule,
  ModalModule,
  IconModule,
  InputModule,
  ModalService,
  TreeModule,
} from '@assurance/bootstrap';
import { ButtonsModule, CommonSeModule } from '@se/common';
import { DynamicFormModule } from '@se/dynamic-form';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';

import {
  SharedSocketService,
  TinyEditorService,
  InsertsApiService,
  ModalExecutor,
  ClipboardService,
  UserApiService,
} from '@shared/services';
import {
  ChartModule,
  ImagesPreviewModule,
  InsertContentModule,
  PdfLoadingMessageModule,
  TableModule,
  TinyEditorModule,
  UserMenuModule,
} from '@shared/components';
import { AssurencePipesModule } from '@shared/pipes/assurence-pipes.module';
import { DirectivesModule } from '@shared/directives/directives.module';
import { CustomPagesRoutingModule } from './custom-pages-routing.module';
import {
  CustomPageDetailsComponent,
  CustomPagePdfGenerationComponent,
  CustomPageSettingsComponent,
  CustomPageHeaderComponent,
  ManageVersionComponent,
  PresentationListModalComponent,
  CustomPageEditTextComponent,
  CustomPageHistoryComponent,
  CustomPageListComponent,
  SkeletonLoaderComponent,
  CustomPageInsertsComponent,
  CustomPagePresentationCompileComponent,
  CustomPageHistoryDetailsComponent,
} from './components';
import {
  LinkedPagesModalComponent,
  CopyModalComponent,
  UsedInModalComponent,
  PlaceholdersWizardComponent,
  ChooseCustomPageComponent,
  ConfigurationComponent,
  CustomMetricsComponent,
  InsertTypeComponent,
  MultipleConfigurationsComponent,
  SetImageComponent,
  TreeInsertsComponent,
  StyleEditComponent,
  ChooseCustomPageChangelogComponent,
  CompareFilesModalComponent,
} from './modals';
import { ConvertToDatePipe, SortInsertTypesPipe } from './pipes';
import {
  CustomPageAdapterService,
  CustomPageApiService,
  ImportExportService,
  CustomPagePreviewService,
  CustomPageService,
  CustomPageSettingsService,
  ManageVersionsService,
  ModalProviderService,
  SelectionService,
  CustomPageHistoryApiService,
  CustomPageHistoryAdapterService,
  CustomPageEditTextAdapterService,
  CustomPageHistoryService,
  CustomPageListService,
  PlaceholdersWizardService,
  TreeInsertsService,
  PlaceholdersOrderService,
} from './services';
import { PresentationService } from '../../components/presentation/presentation.service';
import { PDFGenerationService } from '../../components/presentation/pdf-generation/pdf-generation.service';
import { PlaceholdersWizardEffects, placeholderWizardReducer } from './redux';
import { ShowDataTypePipe } from './pipes/data-source.pipe';
import { IFrameCommunicationService } from '@shared/services/iframe-communication/iframe-communication.service';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [
    CustomPageDetailsComponent,
    CustomPagePdfGenerationComponent,
    CustomPageSettingsComponent,
    LinkedPagesModalComponent,
    CopyModalComponent,
    SortInsertTypesPipe,
    CustomPageHeaderComponent,
    ManageVersionComponent,
    ConvertToDatePipe,
    ShowDataTypePipe,
    PresentationListModalComponent,
    CustomPageEditTextComponent,
    CustomPageHistoryComponent,
    CustomPageListComponent,
    UsedInModalComponent,
    SkeletonLoaderComponent,
    PlaceholdersWizardComponent,
    ChooseCustomPageComponent,
    ConfigurationComponent,
    CustomMetricsComponent,
    InsertTypeComponent,
    MultipleConfigurationsComponent,
    SetImageComponent,
    TreeInsertsComponent,
    StyleEditComponent,
    CustomPageInsertsComponent,
    CustomPagePresentationCompileComponent,
    CustomPageHistoryDetailsComponent,
    ChooseCustomPageChangelogComponent,
    CompareFilesModalComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CommonSeModule,
    ButtonModule,
    ButtonsModule,
    CounterModule,
    LoaderModule,
    ControlModule,
    DropdownModule,
    TinyEditorModule,
    CustomPagesRoutingModule,
    ChartModule,
    ModalModule,
    AssurencePipesModule,
    DragulaModule,
    NgSelectModule,
    FormsModule,
    PdfLoadingMessageModule,
    BsDatepickerModule,
    IconModule,
    InputModule,
    DirectivesModule,
    InsertContentModule,
    UserMenuModule,
    TableModule,
    TreeModule,
    DynamicFormModule,
    ImagesPreviewModule,
    StoreModule.forFeature('placeholdersWizard', placeholderWizardReducer),
    EffectsModule.forFeature([PlaceholdersWizardEffects]),
    CodemirrorModule,
    RouterModule,
    PopoverModule,
    ScrollingModule,
  ],
  providers: [
    SharedSocketService,
    DragulaService,
    CustomPageSettingsService,
    TinyEditorService,
    InsertsApiService,
    ImportExportService,
    CustomPageService,
    CustomPageApiService,
    CustomPageAdapterService,
    ModalProviderService,
    ManageVersionsService,
    ModalService,
    CustomPagePreviewService,
    PlaceholdersOrderService,
    SelectionService,
    CustomPageHistoryApiService,
    CustomPageHistoryAdapterService,
    CustomPageEditTextAdapterService,
    CustomPageHistoryService,
    CustomPageListService,
    ClipboardService,
    IFrameCommunicationService,
    // These service were injected instead of PDFGenerationModule, because SharedSocketService depends on them
    // TODO: need to remove!! we do not need use this here.
    //  Not correct to injection services to shared socket service
    PresentationService,
    PDFGenerationService,
    PlaceholdersWizardService,
    ModalExecutor,
    TreeInsertsService,
    UserApiService,
  ],
})
export class CustomPagesModule {}
