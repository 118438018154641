import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';

import { iif, Observable, of, throwError } from 'rxjs';
import { delay, finalize } from 'rxjs/operators';

import { AuthService } from '@assurance/um-services';
import { AlertService } from '@se/common';

import { isPDF } from '@core/utils';
import { WINDOW_TOKEN } from '@core/constant';
import { LocalStorageService } from '@core/service';
import { userPilotStorageKey } from '@shared/constants';
import { deleteCookie, readCookie } from '@shared/utils';

@Injectable()
export class HttpErrorHandlerService {
  constructor(
    private location: Location,
    private authService: AuthService,
    private alertService: AlertService,
    private localStorage: LocalStorageService,
    @Inject(WINDOW_TOKEN) private window: Window
  ) {}

  handleError(err: HttpErrorResponse): Observable<string> {
    if (err.status === 401 && !err.url.includes('/login') && !err.url.includes('/logout')) {
      this.handleUnauthorizedError();
    } else if (Number(err.status) === 403) {
      this.handleForbiddenError(err);
    }

    if (err.url.includes('um/') || Number(err.status) === 404 || Number(err.status) === 503) {
      return throwError(err);
    }

    return throwError(`${err.status} - ${err.statusText} - ${err.error} - ${err.message}`);
  }

  private handleUnauthorizedError(): void {
    this.alertService.openAlert({
      type: 'warning',
      body: 'Your session has expired. Please log in again.',
      autoClose: 5000,
    });

    iif(() => !isPDF && !!readCookie('loggedIn'), this.authService.logout(), of(null))
      .pipe(
        delay(3000),
        finalize(() => this.navigateToLogin())
      )
      .subscribe();

    deleteCookie('loggedIn');
  }

  private handleForbiddenError(error: HttpErrorResponse): void {
    const errorData = error['body'] || error.error;
    let delay = 0;

    if (errorData) {
      this.alertService.openAlert({
        type: 'warning',
        body: errorData,
        autoClose: 5000,
        disableStack: true,
      });
      delay = 1000;
    }

    setTimeout(() => {
      this.location.go('/home');
      this.window.location.reload();
    }, delay);
  }

  private navigateToLogin() {
    if (this.location.path().includes('/login')) {
      return;
    }

    this.location.go('/login');
    this.window.location.reload();
    this.localStorage.setData(userPilotStorageKey, false);
  }
}
