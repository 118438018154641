<ac-modal-header
  class="justify-content-end"
  (click)="onCancel()"
></ac-modal-header>

<ac-modal-body>
  <div class="main-container d-flex flex-column align-items-center">
    <div class="d-flex w-100 align-items-center pb-2">
      <div class="left-container title d-flex">Entity before the change</div>
      <div
        class="right-container d-flex align-items-center justify-content-between"
      >
        <div class="title">Entity after the change</div>
        <div>
          <se-button (click)="toggleCollapseIdentical()">
            {{ collapseIdentical ? 'Expand' : 'Collapse' }}
          </se-button>
        </div>
      </div>
    </div>

    <div class="editor-container d-flex h-100 w-100">
      <div #diffEditor class="diff-editor w-100"></div>
    </div>
  </div>
</ac-modal-body>

<ac-modal-footer>
  <div class="bottom-container d-flex justify-content-end align-items-center">
    <div class="d-flex btn-group">
      <se-button class="secondary" size="medium" (click)="onCancel()">
        Cancel
      </se-button>
    </div>
  </div>
</ac-modal-footer>
