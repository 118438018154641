import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ModalRef, ModalService } from '@assurance/bootstrap';

import { SalesStoriesInputsComponent } from '../../../modals/sales-stories-inputs/sales-stories-inputs.component';
import { SalesConceptModalData } from '../../models';
import { SelectVersionModalData } from '../../../modals/select-version-modal/select-version-modal.model';
import { SelectVersionModalComponent } from '../../../modals/select-version-modal/select-version-modal.component';
import { PresentationSharingComponent } from '@shared/components/presentation-sharing/presentation-sharing.component';
import { PresentationData } from '@shared/models/shared-presentation.model';
import { SalesConceptModalComponent } from '../../../modals/sales-concept-modal/sales-concept-modal.component';
import { coverSheetModalSize } from '../../../modals/cover-sheet-modal/cover-sheet-modal.constants';
import { IFrameCommunicationService } from '@shared/services';
import { IframeActions } from '@ensight/shim-app';
import { ModalRegistry, PresentationModalType } from '@shared/models';

@Injectable()
export class ModalProviderService {
  modalRegistry: ModalRegistry = {};
  private modalStateSubject = new BehaviorSubject<ModalRegistry>({});

  constructor(private modal: ModalService, private iframeService: IFrameCommunicationService) {}

  openSalesConceptModal(data: SalesConceptModalData): ModalRef {
    return this.modal.open(SalesStoriesInputsComponent, {
      data,
      width: '960px',
      height: '652px',
      disableEscapeEvent: true,
    });
  }

  openSelectedVersionModal(data: SelectVersionModalData): ModalRef {
    const modalRef = this.modal.open(SelectVersionModalComponent, {
      data,
      minWidth: '436px',
      maxHeight: 'min-content',
    });

    this.registerModal(PresentationModalType.SELECT_VERSION, modalRef);

    modalRef.afterClosed.subscribe(() => {
      this.unregisterModal(PresentationModalType.SELECT_VERSION);
    });

    return modalRef;
  }

  openSharedPresentationModal(data: PresentationData): ModalRef {
    const modalRef = this.modal.open(PresentationSharingComponent, {
      data,
      width: '750px',
      minHeight: 'min-content',
    });

    this.registerModal(PresentationModalType.PRESENTATION_SHARING, modalRef);

    if (this.iframeService.isInitialized) {
      this.iframeService.sendResponse(IframeActions.ToggleShareModal, {
        modalState: {
          isSharePresentationModalOpened: true,
        },
      });
    }

    modalRef.afterClosed.subscribe(() => {
      this.unregisterModal(PresentationModalType.PRESENTATION_SHARING);

      if (this.iframeService.isInitialized) {
        this.iframeService.sendResponse(IframeActions.ToggleShareModal, {
          modalState: {
            isSharePresentationModalOpened: false,
          },
        });
      }
    });

    return modalRef;
  }

  openSalesConceptSettingsModal(data: any): ModalRef {
    const modalRef = this.modal.open(SalesConceptModalComponent, {
      data,
      width: coverSheetModalSize.width,
      panelClass: 'sale-story-settings',
    });

    this.registerModal(PresentationModalType.SALES_CONCEPT_SETTINGS, modalRef);

    if (this.iframeService.isInitialized) {
      this.iframeService.sendResponse(IframeActions.ToggleSalesStorySetting, {
        modalState: {
          isSettingsModalOpened: true,
        },
      });
    }

    modalRef.afterClosed.subscribe(() => {
      this.unregisterModal(PresentationModalType.SALES_CONCEPT_SETTINGS);

      if (this.iframeService.isInitialized) {
        this.iframeService.sendResponse(IframeActions.ToggleSalesStorySetting, {
          modalState: {
            isSettingsModalOpened: false,
          },
        });
      }
    });

    return modalRef;
  }

  closeModal(modalType: PresentationModalType): void {
    const modalRef = this.modalRegistry[modalType];

    if (modalRef) {
      modalRef.close({ submitted: false });
    }
  }

  closeAllModals(): void {
    const modalRefs = Object.values(this.modalRegistry);

    modalRefs.forEach(modalRef => {
      if (modalRef) {
        modalRef.close({ submitted: false });
      }
    });

    this.modalRegistry = {};
  }

  private registerModal(modalType: PresentationModalType, modalRef: ModalRef): void {
    this.modalRegistry[modalType] = modalRef;
    this.modalStateSubject.next({ ...this.modalRegistry });
  }

  private unregisterModal(modalType: PresentationModalType): void {
    delete this.modalRegistry[modalType];
    this.modalStateSubject.next({ ...this.modalRegistry });
  }
}
