import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@core/service';
import { Observable } from 'rxjs';
import { ResponseType } from '@core/model';

@Injectable()
export class CaseApiService {
  constructor(private http: HttpClient, private localStorage: LocalStorageService) {}

  /**
   * External request to EIQ for getting case info.
   */
  public getCaseInfo(caseId: number): Observable<ResponseType<any>> {
    return this.http.get<ResponseType<any>>(
      `${this.localStorage.getNotJSONData('eiqApiUrl')}/single-entry-app/v1/cases/${caseId}/info`
    );
  }

  public getCases(payload: { [key: string]: string | number }): Observable<ResponseType<any>> {
    return this.http.post<ResponseType<any>>(
      `${this.localStorage.getNotJSONData('eiqApiUrl')}/single-entry-app/v1/cases/search`,
      payload
    );
  }

  public createCase(payload: unknown): Observable<ResponseType<any>> {
    return this.http.post<ResponseType<any>>(
      `${this.localStorage.getNotJSONData('eiqApiUrl')}/single-entry-app/v1/cases`,
      payload
    );
  }
}
