<ac-modal-header class="container-header" (close)="close()">
   <span class="logo d-inline-block"></span>
</ac-modal-header>

<ac-modal-body class="container-body">
  <um-login-form
    [payloadToken]="payloadToken"
    [selectedApp]="'EPV'"
    [verticalForm]="true"
    title="Login"
    [subTitle]="'Access more features when you \n login to your Ensight account.'"
    (submitForm)="onLogin($event)"
  ></um-login-form>
</ac-modal-body>

