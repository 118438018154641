import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import * as _ from 'lodash-es';
import { Observable, of } from 'rxjs';

import { CarrierPlansApiService, Global, PresentationApiService } from '@shared/services';
import { ANNUITY_PRODUCT_TYPES_BY_INDEX } from './plans.constant';
import { CareerPlan, ResponseType } from '@core/model';
import { PlanModels } from './plans.model';
import { DEFAULT_TITLE_FIELD } from '@shared/constants';

/**
 * @ngdoc service
 * @name assuranceApp.plansService
 * @description
 * # rest
 * Service to talk with backend api and work with plans data model.
 */

@Injectable()
export class PlansService {
  private presentationId: number;
  private models: any = {};

  constructor(
    private global: Global,
    private presentationApiService: PresentationApiService,
    private carrierPlansApiService: CarrierPlansApiService
  ) {}

  /**
   * @ngdoc
   * @name assuranceApp.plansService#initData
   * @methodOf assuranceApp.plansService
   *
   * @description
   * Method to get plans data form the backend api
   *
   * @param {Number} id, the id of presentation
   * @returns {Promise} resolve with fetched data
   */

  public initData(id: number, disableCareerPlanRequest = false): Observable<PlanModels> {
    this.presentationId = id;
    const carrierPlansObs = disableCareerPlanRequest
      ? of({ data: this.global.getCurrentCarrierPlans })
      : this.carrierPlansApiService.getCareerPlans(this.presentationId);

    return carrierPlansObs.pipe(
      map((carrierPlans: ResponseType<CareerPlan[]>) => {
        return {
          plansConfig: this.global.getPlansConfig,
          plansData: carrierPlans.data,
        };
      })
    );
  }

  /**
   * @ngdoc
   * @name assuranceApp.plansService#buildModels
   * @methodOf assuranceApp.plansService
   *
   * @description
   * Method to build model for ui from raw plans data;
   *
   * @param {Object} rawData, the data from backend
   * @returns {Object} model
   */

  public buildModels(rawData: PlanModels): PlanModels {
    this.models.carrierPlansConfig = rawData.plansConfig;
    this.models.plansData = rawData.plansData;
    this.models.menuPlansList = this.buildMenuPlansList(this.models.plansData);

    return this.models;
  }

  /**
   * @ngdoc
   * @name assuranceApp.plansService#buildActivePlanModel
   * @methodOf assuranceApp.plansService
   *
   * @description
   * Method to build model of selected plan for ui;
   *
   * @param {Number} id, the selected id of plan
   */
  public buildActivePlanModel(id: any) {
    this.models.activePlanTitle = '';

    this.models.activePlan = this.models.plansData.filter((plan: any) => {
      return plan.id === id;
    });

    this.buildEmptyModels();

    const titleField = this.global.getMetricsTitleFieldName();

    if (this.models.activePlan.length) {
      _.forEach(this.models.activePlan[0].configjson.data, (colData, colName) => {
        const config = _.find(this.models.carrierPlansConfig.data, (o: any) => {
          return o.db === colName;
        });

        if (config) {
          this.models.carrierPlanData.header.push({
            colTitle: config[titleField] || config[DEFAULT_TITLE_FIELD],
            id: colName,
          });
          this.models.carrierPlanData.data[colName] = colData;
        }
      });
    } else {
      // creating plan
      this.models.carrierPlansConfig.data.map((item: any) => {
        this.models.carrierPlanData.data[item.db] = ['0'];
        this.models.carrierPlanData.header.push({
          colTitle: item[titleField] || item[DEFAULT_TITLE_FIELD],
          id: item.db,
        });
      });
    }

    function mapValueToMetaData(target: string, item: any) {
      // if model.activePlan.length === false - means we're creating the new plan from scratch,
      // so we don't need to map real values to model
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // TODO: піздець!
      if (this.models.activePlan.length) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const data = _.get(this.models.activePlan[0].configjson, target);
        item.value = data && data[item.db] !== 'null' ? data[item.db] : '';
      } else {
        item.value = '';
      }
    }

    this.models.carrierPlanPrimaryDetails.map(mapValueToMetaData.bind(this, 'metadata'));
    this.models.carrierPlanPrimaryOptionalDetails.map(mapValueToMetaData.bind(this, 'metadata'));
    this.models.carrierPlanDetails.map(mapValueToMetaData.bind(this, 'metadata'));

    this.generateActivePlanTitle();
  }

  /**
   * @ngdoc
   * @name assuranceApp.plansService#createPlanFromScratch
   * @methodOf assuranceApp.plansService
   *
   * @description
   * Method to save plan data from scratch in to the backend;
   */

  public createPlanFromScratch() {
    const paramsToUpdate = {
      jsonCarrierPlanData: {
        carrierPlan: {
          data: {},
          metadata: {
            perm: {},
          },
        },
      },
      presentationId: this.presentationId,
    };

    _.forEach(this.models.carrierPlanPrimaryDetails, item => {
      (paramsToUpdate.jsonCarrierPlanData.carrierPlan.metadata as any)[item.db] = item.value;
    });

    _.forEach(this.models.carrierPlanPrimaryOptionalDetails, item => {
      (paramsToUpdate.jsonCarrierPlanData.carrierPlan.metadata as any)[item.db] = item.value;
    });

    _.forEach(this.models.carrierPlanDetails, item => {
      (paramsToUpdate.jsonCarrierPlanData.carrierPlan.metadata as any)[item.db] = item.value;
    });

    paramsToUpdate.jsonCarrierPlanData.carrierPlan.data = this.models.carrierPlanData.data;

    return this.carrierPlansApiService.saveCarrierPlan(paramsToUpdate);
  }

  /**
   * @ngdoc
   * @name assuranceApp.plansService#updatePlan
   * @methodOf assuranceApp.plansService
   *
   * @description
   * Method to update plan;
   *
   * @returns {Promise} response from backend
   */

  public updatePlan(updateData?: any) {
    let paramsToUpdate;

    if (updateData) {
      paramsToUpdate = {
        jsonCarrierPlanData: updateData.jsonCarrierPlanData,
        planId: updateData.planId,
      };
    } else {
      paramsToUpdate = {
        jsonCarrierPlanData: _.cloneDeep(this.models.activePlan[0]),
        planId: this.models.activePlan[0].id,
      };

      _.forEach(this.models.carrierPlanPrimaryDetails, item => {
        paramsToUpdate.jsonCarrierPlanData.configjson.metadata[item.db] = item.value;
      });

      _.forEach(this.models.carrierPlanPrimaryOptionalDetails, item => {
        paramsToUpdate.jsonCarrierPlanData.configjson.metadata[item.db] = item.value;
      });

      _.forEach(this.models.carrierPlanDetails, item => {
        paramsToUpdate.jsonCarrierPlanData.configjson.metadata[item.db] = item.value;
      });

      paramsToUpdate.jsonCarrierPlanData.configjson.data = this.models.carrierPlanData.data;

      paramsToUpdate.jsonCarrierPlanData.presentationId = this.presentationId;
    }

    return this.carrierPlansApiService.updateCarrierPlan(paramsToUpdate);
  }

  /**
   * @ngdoc
   * @name assuranceApp.plansService#unPublishPresentation
   * @methodOf assuranceApp.plansService
   *
   * @description
   * Method to unpublish presentation;
   *
   * @param {Number} id, the id of the current presentation
   *
   * @returns {Promise} response from backend
   */

  public unPublishPresentation() {
    return this.presentationApiService.removePresentation(this.presentationId);
  }

  private buildMenuPlansList(data: CareerPlan[]) {
    const list: any[] = [];

    _.forEach(data, (plan: CareerPlan) => {
      const id = plan.id;
      const planOrder = plan.order;

      const planConfigJSON = typeof plan.configjson === 'string' ? JSON.parse(plan.configjson) : plan.configjson;

      list.push({
        id,
        label: planConfigJSON.metadata.company_name,
        order: planOrder,
        productName: planConfigJSON.metadata.product_name,
        productNote: planConfigJSON.metadata.note,
        carrierCode: planConfigJSON.metadata.carrier_code,
      });
    });

    return _.sortBy(list, 'order');
  }

  private buildEmptyModels() {
    this.models.carrierPlanPrimaryDetails = this.models.carrierPlansConfig.meta.filter((item: any) => {
      return item.section && item.section === 'primary';
    });
    this.models.carrierPlanPrimaryOptionalDetails = this.models.carrierPlansConfig.meta.filter((item: any) => {
      return item.section && item.section === 'optional';
    });
    this.models.carrierPlanDetails = this.models.carrierPlansConfig.meta.filter((item: any) => {
      return !item.section && item.key !== 'note' && item.key !== 'productTypeIndex';
    });

    this.models.carrierPlanData = {
      data: {},
      header: [],
    };
  }

  private generateActivePlanTitle() {
    const selectedPlanId = (_.first(this.models.activePlan) as any).id;
    const planItem = _.find(this.models.menuPlansList, ['id', selectedPlanId]);
    const companyName = planItem.label || 'Company Name';

    this.models.activePlanTitle = planItem.productName || 'Perm Product Name';
    this.models.activePlanTitle += ` (${companyName})`;
  }

  public checkOnAnnuityProducts(): boolean {
    let isAnnuity = false;

    if (this.global?.getCurrentCarrierPlans?.length) {
      const product_type_index = this.global.getCurrentCarrierPlans[0]?.configjson?.metadata?.product_type_index;

      if (product_type_index && ANNUITY_PRODUCT_TYPES_BY_INDEX.get(Number(product_type_index))) {
        isAnnuity = true;
      }
    }

    return isAnnuity;
  }

  checkOnLTCProducts(): boolean {
    return this.global.getCurrentCarrierPlans.some(plan => plan.configjson.metadata.product_type === 'LTC');
  }
}
