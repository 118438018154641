import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { routeConf } from '@core/constant';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ForgotPasswordComponent } from './components/login/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/login/reset-password/reset-password.component';

import { WinFlexRedirectComponent } from './components/win-flex-redirect.component';

import {
  CurrentThemeFetch,
  ThemesList,
  CoverLetterRequiredGuard,
  MetadataList,
  PresentationGuard,
  SharedCurrentThemeFetch,
} from './guards/misc.guard';
import { NewAuthGuard } from './guards/new-auth.guard';
import { ManagePasswordComponent } from './components/login/manage-password/manage-password.component';
import { OrganizationsSwitchComponent } from './components/login/organizations-switch/organizations-switch.component';
import {
  PolicyInForceRecordsGuard,
  CustomPagePermissionGuard,
  OrganizationsSwitchGuard,
  SharedDependentPermissions,
} from '@core/guards';
import { PresentationRoutes } from '@core/enums';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    canActivate: [OrganizationsSwitchGuard, NewAuthGuard, CurrentThemeFetch],
    data: routeConf.home,
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    path: 'home',
  },
  { component: WinFlexRedirectComponent, path: 'AssuranceAPI/auth/:id' },
  { component: WinFlexRedirectComponent, path: 'EnsightAPI/auth/:id' },
  {
    canActivate: [NewAuthGuard, CurrentThemeFetch],
    loadChildren: () => import('./modules/xml-statuses/xml-statuses.module').then(m => m.XmlStatusesModule),
    data: routeConf['xml-statuses'],
    path: 'xml-statuses',
  },
  {
    canActivateChild: [NewAuthGuard, CurrentThemeFetch],
    canLoad: [NewAuthGuard],
    loadChildren: () => import('./modules/custom-pages/custom-pages.module').then(m => m.CustomPagesModule),
    path: 'custom-pages',
  },
  {
    canActivateChild: [NewAuthGuard, CurrentThemeFetch],
    canLoad: [NewAuthGuard],
    loadChildren: () =>
      import('./modules/data-consumption-configuration/config-management.module').then(m => m.ConfigManagementModule),
    path: 'config-management',
  },
  {
    canActivateChild: [NewAuthGuard, CurrentThemeFetch],
    loadChildren: () =>
      import('./modules/import-illustration/import-illustration.module').then(m => m.ImportIllustrationModule),
    path: 'import-illustration',
  },
  {
    canActivate: [NewAuthGuard],
    canLoad: [NewAuthGuard],
    loadChildren: () => import('./um-wrapper.module').then(m => m.UMWrapperModule),
    path: 'user-management',
  },
  {
    canActivate: [NewAuthGuard, CurrentThemeFetch],
    loadChildren: () => import('./modules/history/history.module').then(m => m.HistoryModule),
    path: 'history',
    data: routeConf.home,
  },
  {
    canActivate: [NewAuthGuard],
    component: LoginComponent,
    data: routeConf.login,
    path: 'login',
  },
  { component: ForgotPasswordComponent, path: 'forgot-password' },
  {
    canActivate: [NewAuthGuard, CurrentThemeFetch],
    component: OrganizationsSwitchComponent,
    path: 'organizations-switch',
  },
  {
    component: OrganizationsSwitchComponent,
    path: 'organizations-select',
  },
  {
    component: ResetPasswordComponent,
    path: 'reset-password',
  },
  {
    component: ManagePasswordComponent,
    path: 'manage-password',
  },
  {
    component: NotFoundComponent,
    path: 'not-found',
  },
  {
    component: NotFoundComponent,
    path: 'not-found/:type',
    data: { disableRedirect: true },
  },
  {
    canActivateChild: [NewAuthGuard, CurrentThemeFetch],
    loadChildren: () => import('./components/presentation/presentation.module').then(m => m.PresentationModule),
    path: PresentationRoutes.presentation,
  },
  {
    canActivate: [SharedDependentPermissions],
    canActivateChild: [SharedCurrentThemeFetch],
    loadChildren: () =>
      import('./components/presentation/shared-presentation/shared-presentation.module').then(
        m => m.SharedPresentationModule
      ),
    path: PresentationRoutes.sharedPresentation,
  },
  //TODO: Need to refact in order to improve auth flow!!!!!
  {
    path: 'policy-inforce-records',
    canLoad: [NewAuthGuard],
    canActivate: [PolicyInForceRecordsGuard],
    loadChildren: () =>
      import('./modules/policy-in-force-records/policy-in-force-records.module').then(
        m => m.PolicyInForceRecordsModule
      ),
  },
  { path: '**', pathMatch: 'full', redirectTo: 'home' },
];
@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      anchorScrolling: 'enabled',
    }),
  ],
  providers: [
    NewAuthGuard,
    CurrentThemeFetch,
    ThemesList,
    CoverLetterRequiredGuard,
    MetadataList,
    PresentationGuard,
    SharedCurrentThemeFetch,
    SharedDependentPermissions,
    PolicyInForceRecordsGuard,
    CustomPagePermissionGuard,
    OrganizationsSwitchGuard,
  ],
})
export class AppRoutingModule {}
