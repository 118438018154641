import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {
  CUSTOM_PAGES_LIST_URL,
  defaultSelectedTab,
  HISTORY_DETAILS_SELECTED_LOGS,
  HistoryDetailsBtns,
  NotFoundMessage,
} from '../../constants';
import { TableBodyColumn, TableHeaderColumn, TableRequestParams } from '@shared/models';
import {
  getHeaderColumns,
  getBodyColumns,
  DefaultHistoryDetailsOrderByRequestParam,
} from '../../constants/custom-page-history-details.constants';
import { ChangelogTableData, CustomPageChanges, CustomPageHistoryResponse, S3MetaParams } from '../../models';
import { CustomPageHistoryService, ModalProviderService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, map, switchMap, Observable, forkJoin, of } from 'rxjs';
import { AlertService } from '@se/common';
import { BrowserSessionStorage } from '@core/service';
import CodeMirror from 'codemirror';

@Component({
  selector: 'ep-custom-page-history-details-component',
  templateUrl: './custom-page-history-details.component.html',
  styleUrl: './custom-page-history-details.component.scss',
})
export class CustomPageHistoryDetailsComponent implements OnInit, AfterViewInit {
  historyDetailsBtns = HistoryDetailsBtns;
  activeButtonKey = defaultSelectedTab;
  notFoundErrorMessage = NotFoundMessage;
  currentOrderByParams: TableRequestParams = DefaultHistoryDetailsOrderByRequestParam;
  pageNameId: string;
  pageUiid: string;
  pageId: string;
  labels: string[];

  isPageLoading = false;
  isDataLoading = false;

  changelogRecords: ChangelogTableData[] = [];
  tableHeaderColumn: TableHeaderColumn[] = getHeaderColumns();
  tableColumn: TableBodyColumn[] = getBodyColumns();

  targetChangelog: CustomPageChanges;
  previousChangelog: CustomPageChanges;

  jsonChangelog1: string = '';
  jsonChangelog2: string = '';
  @ViewChild('diffEditor') diffEditor: ElementRef;
  private destroyRef = inject(DestroyRef);
  private cdr = inject(ChangeDetectorRef);

  constructor(
    private modalService: ModalProviderService,
    private router: Router,
    private route: ActivatedRoute,
    private customPageHistoryService: CustomPageHistoryService,
    private alertService: AlertService,
    private browserSessionStorage: BrowserSessionStorage
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state;

    if (state) {
      this.pageNameId = state.pageNameId;
      this.pageUiid = state.pageUiid;
      this.pageId = state.pageId;
      this.labels = state.labels;
    } else {
      this.pageId = this.route.snapshot.paramMap.get('id');
      this.leavePage();
    }
  }

  ngOnInit(): void {
    this.isPageLoading = !this.isPageLoading;
    this.prepareButtons();
    const selectedLogs = this.browserSessionStorage.getItem(HISTORY_DETAILS_SELECTED_LOGS);
    const selectedLogsValue = selectedLogs ? JSON.parse(selectedLogs) : null;

    if (selectedLogsValue) {
      this.customPageHistoryService
        .getHistoryDetailsCompared(selectedLogsValue)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((result: CustomPageHistoryResponse) => {
          this.targetChangelog = result.data.find(log => log.pageNameId === selectedLogsValue.rightPageNameId);
          this.previousChangelog = result.data.find(log => log.pageNameId === selectedLogsValue.leftPageNameId);

          this.changelogRecords = this.customPageHistoryService.formatHistoryTableData(result.detailedModifications);
          this.isPageLoading = !this.isPageLoading;
        });
    } else if (this.pageUiid && this.pageNameId) {
      this.fetchHistoryDetails();
    }
  }

  ngAfterViewInit() {
    if (this.activeButtonKey === 'json' && this.diffEditor?.nativeElement) {
      this.initDiffEditor();
    }
  }

  leavePage(): void {
    this.router.navigate([CUSTOM_PAGES_LIST_URL, this.pageId, 'history']);
    this.browserSessionStorage.removeItem(HISTORY_DETAILS_SELECTED_LOGS);
  }

  selectPage(event: Record<string, string>): void {
    this.activeButtonKey = event.key;

    this.historyDetailsBtns = this.historyDetailsBtns.map(item => ({ ...item, active: event.key === item.key }));
    this.cdr.detectChanges();

    if (this.activeButtonKey === 'json') {
      if (!this.jsonChangelog1 || !this.jsonChangelog2) {
        this.fetchHistoryDetailsRevision();
      } else {
        this.initDiffEditor();
      }
    }
  }

  onSelectChangelog(changelog: CustomPageChanges, target: boolean): void {
    const data = {
      name: changelog.pageName,
      uiId: this.pageUiid,
      id: this.pageId,
      labels: this.labels,
      isTargetLog: target,
    };

    const modalRef = this.modalService.openChooseCustomPageChangelog(data);

    modalRef.afterClosed
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(result => Boolean(result.submitted)),
        switchMap(result => {
          this.isDataLoading = true;
          const data: S3MetaParams = this.createS3MetaParams(result.isTargetLog, result.selectedLog);

          return this.customPageHistoryService.getHistoryDetailsCompared(data).pipe(
            switchMap(res => {
              const formattedRecords = this.customPageHistoryService.formatHistoryTableData(res.detailedModifications);
              const targetKey = result.isTargetLog ? 'targetChangelog' : 'previousChangelog';
              this[targetKey] = result.selectedLog;

              this.browserSessionStorage.setItem(
                HISTORY_DETAILS_SELECTED_LOGS,
                JSON.stringify({
                  leftS3MetadataPath: this.previousChangelog.s3Metadata.path,
                  rightS3MetadataPath: this.targetChangelog.s3Metadata.path,
                  leftPageNameId: this.previousChangelog.pageNameId,
                  rightPageNameId: this.targetChangelog.pageNameId,
                })
              );

              if (this.activeButtonKey === 'json') {
                return this.fetchLogJSONRevision(this[targetKey]).pipe(
                  map(data => ({
                    changelogRecords: formattedRecords,
                    log: data,
                    logType: result.isTargetLog,
                  }))
                );
              } else {
                return of({ changelogRecords: formattedRecords, log: null, logType: result.isTargetLog });
              }
            })
          );
        })
      )
      .subscribe({
        next: data => {
          this.changelogRecords = data.changelogRecords;
          this[this.getJsonKey(data.logType)] = data.log;
          this.isDataLoading = false;

          if (this.activeButtonKey === 'json') {
            setTimeout(() => this.initDiffEditor());
          }
        },
        error: () => {
          this.alertService.openAlert({
            type: 'error',
            body: this.notFoundErrorMessage,
            autoClose: 5000,
          });
          this.isDataLoading = false;
        },
      });
  }

  onOpenChangeDetailsModal(index: number): void {
    this.modalService.openDataComparisonModal(this.changelogRecords[index]);
  }

  setOrderByValue(value: TableRequestParams): void {
    this.currentOrderByParams = value;

    this.changelogRecords = this.customPageHistoryService.sortTableData(
      this.changelogRecords,
      this.currentOrderByParams
    );
  }

  private createS3MetaParams(isTargetLog: boolean, selectedLog: CustomPageChanges): S3MetaParams {
    let leftSource, rightSource;

    if (isTargetLog) {
      leftSource = this.previousChangelog;
      rightSource = selectedLog;
    } else {
      leftSource = selectedLog;
      rightSource = this.targetChangelog;
    }

    return {
      leftS3MetadataPath: leftSource.s3Metadata.path,
      rightS3MetadataPath: rightSource.s3Metadata.path,
      leftPageNameId: leftSource.pageNameId,
      rightPageNameId: rightSource.pageNameId,
    };
  }

  private fetchHistoryDetails(): void {
    this.isDataLoading = !this.isDataLoading;

    this.customPageHistoryService
      .getHistoryDetailsChangelog(this.pageUiid, this.pageNameId, true)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(response => {
        if (response.data) {
          this.targetChangelog = response.data.find(log => log.pageNameId === this.pageNameId) || null;
          this.previousChangelog = response.data.find(log => log.pageNameId !== this.pageNameId) || null;

          this.browserSessionStorage.setItem(
            HISTORY_DETAILS_SELECTED_LOGS,
            JSON.stringify({
              leftS3MetadataPath: this.previousChangelog.s3Metadata.path,
              rightS3MetadataPath: this.targetChangelog.s3Metadata.path,
              leftPageNameId: this.previousChangelog.pageNameId,
              rightPageNameId: this.targetChangelog.pageNameId,
            })
          );
          this.changelogRecords = this.customPageHistoryService.formatHistoryTableData(response.detailedModifications);
        }

        this.isPageLoading = !this.isPageLoading;
        this.isDataLoading = !this.isDataLoading;
      });
  }

  private fetchLogJSONRevision(changelog: CustomPageChanges): Observable<string> {
    return this.customPageHistoryService
      .getRevisionCustomPage(changelog.pageUUID, changelog.pageNameId)
      .pipe(map(response => (response ? JSON.stringify(response, null, 2) : 'No data available')));
  }

  private fetchHistoryDetailsRevision(): void {
    if (!this.previousChangelog || !this.targetChangelog) {
      return;
    }

    this.isDataLoading = true;

    forkJoin({
      previousLog: this.fetchLogJSONRevision(this.previousChangelog),
      targetLog: this.fetchLogJSONRevision(this.targetChangelog),
    }).subscribe({
      next: ({ previousLog, targetLog }) => {
        this[this.getJsonKey(false)] = previousLog;
        this[this.getJsonKey(true)] = targetLog;

        setTimeout(() => this.initDiffEditor());
      },
      complete: () => {
        this.isDataLoading = false;
      },
    });
  }

  private getJsonKey(isTargetLog: boolean): 'jsonChangelog1' | 'jsonChangelog2' {
    return isTargetLog ? 'jsonChangelog2' : 'jsonChangelog1';
  }

  private prepareButtons(): void {
    // temp solution until others pages are done
    const activeBtn = this.historyDetailsBtns.find(btn => btn.key === this.activeButtonKey);
    activeBtn.active = true;
    activeBtn.disabled = false;

    // this.historyDetailsBtns = this.historyDetailsBtns.map(btn => {
    //   return {
    //     ...btn,
    //     active: this.activeButtonKey === btn.key,
    //     disabled: false,
    //   };
    // });
  }

  private initDiffEditor(): void {
    if (!this.diffEditor || !this.diffEditor.nativeElement) {
      return;
    }

    const target = this.diffEditor.nativeElement;
    target.innerHTML = '';

    CodeMirror.MergeView(target, {
      value: this.jsonChangelog2,
      origLeft: this.jsonChangelog1,
      lineNumbers: true,
      mode: 'application/json',
      showDifferences: true,
      revertButtons: false,
      readOnly: true,
      lineWrapping: true,
      collapseIdentical: false,
    });

    this.cdr.detectChanges();
  }
}
