import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomHttpParams, ResponseType } from '@core/model';
import * as apiConstant from '@core/constant';
import { getSearchQuery } from '@core/utils';
import { map, Observable, shareReplay } from 'rxjs';
import {
  CompilePageOptions,
  CustomPage,
  DataSourcesConfig,
  NavbarCustomPage,
  NavbarCustomPageRequestParams,
  SalesConceptDataResponse,
} from '@shared/models';
import { CustomPageCacheService } from 'src/app/components/presentation/setup/custom-page/custom-page-cache.service';

@Injectable()
export class ContentApiService {
  constructor(private http: HttpClient, private customPageCacheService: CustomPageCacheService) {}

  public getPagesList(params: CustomHttpParams) {
    return this.http.get<ResponseType<any>>(apiConstant.customPagesContent, {
      params: getSearchQuery(params),
    });
  }

  public getPages(params: Record<string, string | string[] | boolean>, isShared = false) {
    return this.http.get<ResponseType<any>>(
      isShared ? apiConstant.sharedContentServiceContent : apiConstant.contentServiceContentCustomPagesByUuId,
      {
        params: getSearchQuery(params),
      }
    );
  }

  public getPagesListByLabel(
    params: Record<string, string | string[] | boolean>
  ): Observable<ResponseType<{ data: CustomPage[] }>> {
    return this.http.get<ResponseType<{ data: CustomPage[] }>>(
      `${apiConstant.contentServiceContentCustomPagesByLabels}`,
      {
        params: getSearchQuery(params),
      }
    );
  }

  public getCustomPagesInserts(params: CustomHttpParams, isShared = false) {
    const url = isShared
      ? `${apiConstant.sharedContentServiceContent}${apiConstant.pagesInserts}`
      : `${apiConstant.contentServiceContent}${apiConstant.pagesInserts}`;

    const uniqueObj = {
      params: getSearchQuery(params),
      isShared,
      url,
    };
    let cachedInsertsReq$ = this.customPageCacheService.getValue(uniqueObj);

    if (!cachedInsertsReq$) {
      cachedInsertsReq$ = this.http
        .get<ResponseType<any>>(url, {
          params: getSearchQuery(params),
        })
        .pipe(shareReplay(1));
      this.customPageCacheService.setValue(cachedInsertsReq$, uniqueObj);
    }

    return cachedInsertsReq$.pipe(map(res => res.data?.data));
  }

  public getCustomPagesInsertsByPageIds(params: CustomHttpParams, isShared = false) {
    const url = isShared
      ? `${apiConstant.sharedContentServiceContent}${apiConstant.insertsByPageIds}`
      : `${apiConstant.contentServiceContent}${apiConstant.insertsByPageIds}`;

    const uniqueObj = {
      params: getSearchQuery(params),
      isShared,
      url,
    };

    let cachedInsertsReq$ = this.customPageCacheService.getValue(uniqueObj);

    if (!cachedInsertsReq$) {
      cachedInsertsReq$ = this.http
        .get<ResponseType<any>>(url, { params: getSearchQuery(params) })
        .pipe(shareReplay(1));
      this.customPageCacheService.setValue(cachedInsertsReq$, uniqueObj);
    }

    return cachedInsertsReq$.pipe(map(res => res.data?.data));
  }

  public getCompiledPages(params: any, data: CompilePageOptions, isShared: boolean, presentationId: number) {
    const url = isShared
      ? `${apiConstant.sharedContentServiceContent}/${apiConstant.compile}`
      : `${apiConstant.contentServiceContent}/${apiConstant.compile}`;
    const options = {
      params: getSearchQuery(params),
    };

    if (presentationId) {
      Object.assign(options, {
        headers: new HttpHeaders({
          PresentationId: presentationId?.toString(),
        }),
      });
    }

    return this.http.post<ResponseType<any>>(url, data, options);
  }

  public getCompiledPage(
    uiId: string,
    data: CompilePageOptions,
    isShared: boolean,
    params: CustomHttpParams,
    presentationID: number,
    pageId: string,
    tookByFormula: boolean
  ) {
    const compilePath = tookByFormula ? apiConstant.compileByPageId : apiConstant.compile;
    const idPath = tookByFormula ? pageId : uiId;

    const url = isShared
      ? `${apiConstant.sharedContentServiceContent}/${compilePath}/${idPath}`
      : `${apiConstant.contentServiceContent}/${compilePath}/${idPath}`;

    const options = {
      params: getSearchQuery(params),
    };
    let headers = new HttpHeaders();

    if (presentationID) {
      headers = headers.set('PresentationId', presentationID.toString());
    }

    Object.assign(options, { headers });

    return this.http.post<ResponseType<any>>(url, data, options);
  }

  public getSalesConceptData(
    presentationId: number,
    params: NavbarCustomPageRequestParams
  ): Observable<SalesConceptDataResponse[]> {
    let headers = new HttpHeaders();

    if (presentationId) {
      headers = headers.set('PresentationId', presentationId.toString());
    }

    return this.http
      .post<ResponseType<{ data: SalesConceptDataResponse[] }>>(`${apiConstant.salesConceptData}`, params, { headers })
      .pipe(map(response => response.data.data));
  }

  public downloadMetricsForSalesPage(
    uiId: string,
    data: CompilePageOptions,
    presentationId: number
  ): Observable<ArrayBuffer> {
    const options: any = {
      headers: new HttpHeaders({
        Accept: 'application/csv',
        'Content-Type': 'application/json',
      }),
      responseType: 'blob',
    };

    if (presentationId) {
      Object.assign(options, {
        headers: new HttpHeaders({
          PresentationId: presentationId?.toString(),
        }),
      });
    }

    return this.http.post(`${apiConstant.downloadMetricsCSV}/${uiId}`, data, options);
  }

  public uploadCustomImage(data: FormData) {
    return this.http.post<ResponseType<any>>(apiConstant.customImage, data);
  }

  public deleteCustomImage(fileName: string) {
    return this.http.delete<ResponseType<void>>(apiConstant.customImage, {
      params: getSearchQuery({ fileName }),
    });
  }

  getNavbarSalesConceptsAndDependentPages(
    body: NavbarCustomPageRequestParams,
    isShared: boolean,
    presentationId: number
  ): Observable<NavbarCustomPage[]> {
    const url = isShared ? apiConstant.sharedNavbarCustomPages : apiConstant.navbarCustomPages;
    let headers = new HttpHeaders();

    if (presentationId) {
      headers = headers.set('PresentationId', presentationId.toString());
    }

    return this.http
      .post<ResponseType<{ data: NavbarCustomPage[] }>>(url, body, { headers })
      .pipe(map((data: ResponseType<{ data: NavbarCustomPage[] }>) => data.data.data));
  }

  getNavbarEndPages(
    body: NavbarCustomPageRequestParams,
    isShared: boolean,
    presentationId: number
  ): Observable<NavbarCustomPage[]> {
    const url = isShared ? apiConstant.sharedNavbarCustomPages : apiConstant.navbarCustomPages;

    let headers = new HttpHeaders();

    if (presentationId) {
      headers = headers.set('PresentationId', presentationId.toString());
    }

    return this.http
      .post<ResponseType<{ data: NavbarCustomPage[] }>>(url, body, { headers })
      .pipe(map((data: ResponseType<{ data: NavbarCustomPage[] }>) => data.data.data));
  }

  public getDependentPages(): Observable<ResponseType<any>> {
    return this.http.get<ResponseType<any>>(apiConstant.sharedDependentPagesConfig);
  }

  public getDependentPermissions(): Observable<ResponseType<any>> {
    return this.http.get<ResponseType<any>>(apiConstant.sharedDependentPermissions);
  }

  getDataSourcesConfig(): Observable<DataSourcesConfig[]> {
    return this.http
      .get<ResponseType<DataSourcesConfig[]>>(apiConstant.dataSourcesConfig)
      .pipe(map((res: ResponseType<DataSourcesConfig[]>) => res.data));
  }
}
