<section
  class="content a-app-main-container-background-color"
  [ngClass]="{ loader: isPageLoading, hidden: !isPageLoading }"
></section>

<ensight-custom-page-header
  [title]="targetChangelog?.pageName"
  (onClose)="leavePage()"
></ensight-custom-page-header>

<div class="container-buttons d-flex justify-content-center">
  <se-button-group
    [buttons]="historyDetailsBtns"
    size="medium"
    (onSelectButton)="selectPage($event)"
  ></se-button-group>
</div>

@if(!isDataLoading && previousChangelog && targetChangelog) {
    <div class="selected-logs-container d-flex align-items-center w-100">
      <div
        class="log-container first-log-container d-flex justify-content-between align-items-center"
      >
      <div class="log-name">
        <div>
          <span>
            {{ previousChangelog.pageName }}
            @if(previousChangelog.comment) {
            <span class="log-note">({{ previousChangelog.comment }})</span>
            }
          </span>
        </div>
    
        <span class="log-author">
          by {{ previousChangelog.updatedBy }},
          {{ previousChangelog.updated | date: 'MM/dd/yyyy HH:mm:ss a' }}
        </span>
      </div>
  
      <se-button
        class="secondary"
        size="small"
        (click)="onSelectChangelog(previousChangelog, false)"
      >
        Select
      </se-button>
      </div>
     
      <span class="and-separator">vs</span>

      <div
        class="log-container d-flex justify-content-between align-items-center"
      >
      <div class="log-name">
        <div>
          <span>
            {{ targetChangelog.pageName }}
            @if(targetChangelog.comment) {
            <span class="log-note">({{ targetChangelog.comment }})</span>
            }
          </span>
        </div>
    
        <span class="log-author">
          by {{ targetChangelog.updatedBy }},
          {{ targetChangelog.updated | date: 'MM/dd/yyyy HH:mm:ss a' }}
        </span>
      </div>
    
        <se-button
          class="secondary"
          size="small"
          (click)="onSelectChangelog(targetChangelog, true)"
        >
          Select
        </se-button>
      </div>
    </div>
}
@if (isDataLoading) {
  <div class="container-spinner-message d-flex flex-column justify-content-center align-items-center h-100">
    <se-circle-loader></se-circle-loader>
  </div>
} @else if (!previousChangelog || !targetChangelog) {
  <div class="no-data-label h-100 d-flex justify-content-center align-items-center">
    {{ notFoundErrorMessage }}
  </div>
} @else {
  @switch (activeButtonKey) {
    @case ('listOfChanges') {
      @if (changelogRecords.length) {
        <div class="changelog-list-container bg-white">
          <ep-table
            [data]="changelogRecords"
            [tableHeaderColumn]="tableHeaderColumn"
            [tableColumn]="tableColumn"
            [trackByKey]="'entityType'"
            [orderByParams]="currentOrderByParams"
            (sortBy)="setOrderByValue($event)"
          >
            <ng-container epCustomTableColumn columnName="valueBefore">
              <ng-template let-value="value" let-index="index">
                @if (value.length > 100) {
                  <se-button class="secondary" (click)="onOpenChangeDetailsModal(index)">
                    Details
                  </se-button>
                } @else {
                  <div
                    [class.value-before]="value !== '-'"
                    class="d-flex align-items-center"
                  >
                    {{ value }}
                  </div>
                }
              </ng-template>
            </ng-container>

            <ng-container epCustomTableColumn columnName="valueAfter">
              <ng-template let-value="value" let-index="index">
                @if (value.length > 100) {
                  <se-button class="secondary" (click)="onOpenChangeDetailsModal(index)">
                    Details
                  </se-button>
                } @else {
                  <div
                    [class.value-after]="value !== '-'"
                    class="d-flex align-items-center"
                  >
                    {{ value }}
                  </div>
                }
              </ng-template>
            </ng-container>
          </ep-table>
        </div>
      } @else {
        <div class="no-data-label h-100 d-flex justify-content-center align-items-center">
          Selected revisions have no difference
        </div>
      }
    }

    <!-- @case ('pageView') {
    } -->
    @case ('json') {
      <div class="changelog-list-container bg-white d-flex">
          <div #diffEditor class="diff-editor w-100"></div>
      </div>
      }
  }
}
