import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';

import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { filter, first, switchMap, map, distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AppState } from '../../../../../reducers';
import {
  changeFormByChartType,
  changeVariableConfiguration,
  configurationForm,
  configurationFormWithMetricQuestion,
  dynamicFormValue,
} from '../../../redux';
import * as Actions from '../../../redux/placeholders-wizard.actions';
import { PlaceholdersDynamicFormValue, PlanConfigData, VariablePlaceholder } from '@core/model';
import { FormSharedService } from '@se/dynamic-form';
import { CHART_CONFIGURATION_STATE, CHART_STYLE_CONFIGURATION_STATE, VARIABLES_STATE } from '../../../constants';
import { ConfigurationData } from '../../../models';

@UntilDestroy()
@Component({
  selector: 'ep-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigurationComponent implements OnInit {
  @Input() configurationStateId: string;
  @Input() id: string;
  @Input() plansConfigData: PlanConfigData[];
  @Input() plansConfigMeta: PlanConfigData[];

  questionsData$: Observable<{ data: any }>;
  isInlineEditMode = false;

  constructor(private store: Store<AppState>, private formSharedService: FormSharedService) {}

  ngOnInit(): void {
    this.questionsData$ = this.getConfigurationForm().pipe(
      switchMap(data => this.modifyChartConfiguration(data)),
      switchMap(data => this.modifyChartStyleConfiguration(data)),
      first()
    );

    this.watchForDynamicFormValue();
    this.setupFormFieldsToggleListener();
  }

  private getConfigurationForm(): Observable<ConfigurationData> {
    return this.store.pipe(
      select(
        configurationForm({
          id: this.id,
          stateId: this.configurationStateId,
        })
      ),
      filter(item => !!item),
      untilDestroyed(this)
    );
  }

  private modifyChartConfiguration(data: ConfigurationData): Observable<ConfigurationData> {
    if (this.configurationStateId === CHART_CONFIGURATION_STATE.id) {
      return this.store.select(
        configurationFormWithMetricQuestion({
          data,
          plansConfigData: this.plansConfigData,
          plansConfigMeta: this.plansConfigMeta,
        })
      );
    }

    return of(data);
  }

  private modifyChartStyleConfiguration(data: ConfigurationData): Observable<ConfigurationData> {
    if (this.configurationStateId === CHART_STYLE_CONFIGURATION_STATE.id) {
      return this.modifyBasedOnDynamicFormValue(data);
    } else {
      return of(data);
    }
  }

  private modifyBasedOnDynamicFormValue(data: ConfigurationData): Observable<ConfigurationData> {
    return this.store.select(dynamicFormValue).pipe(
      first(),
      switchMap((dataSourceValue: any) =>
        this.store.select(changeFormByChartType(data, dataSourceValue.chartDataSource))
      )
    );
  }

  private watchForDynamicFormValue(): void {
    this.store
      .select(dynamicFormValue)
      .pipe(
        untilDestroyed(this),
        filter(() => this.configurationStateId === VARIABLES_STATE.id)
      )
      .subscribe((formValue: PlaceholdersDynamicFormValue) => {
        this.isInlineEditMode = (formValue as VariablePlaceholder)?.isInlineEditable;
        this.questionsData$ = this.getModifiedVariableFormConfiguration(formValue as VariablePlaceholder);
      });
  }

  private getModifiedVariableFormConfiguration(formValue: VariablePlaceholder): Observable<ConfigurationData> {
    return this.getConfigurationForm().pipe(
      first(),
      map((data: ConfigurationData) => changeVariableConfiguration(data, formValue))
    );
  }

  private setupFormFieldsToggleListener(): void {
    if (this.configurationStateId !== VARIABLES_STATE.id) {
      return;
    }

    this.formSharedService.valueChangePure$
      .pipe(
        filter((formValue: any) => formValue.formId === VARIABLES_STATE.id),
        map(formValue => formValue.form.value),
        distinctUntilChanged((prev, current) => {
          return (
            prev.isInlineEditable === current.isInlineEditable &&
            prev.openSalesStorySettingsOnClick === current.openSalesStorySettingsOnClick
          );
        }),
        untilDestroyed(this)
      )
      .subscribe(formValue => {
        const updatedFormValue = this.handleToggleExclusivity(formValue);

        if (
          formValue.isInlineEditable !== updatedFormValue.isInlineEditable ||
          formValue.openSalesStorySettingsOnClick !== updatedFormValue.openSalesStorySettingsOnClick
        ) {
          this.updateFormFields(updatedFormValue);
        }
      });
  }

  private handleToggleExclusivity(formValue: any): any {
    const updatedFormValue = { ...formValue };

    if (formValue.isInlineEditable && formValue.openSalesStorySettingsOnClick) {
      this.store
        .select(dynamicFormValue)
        .pipe(first())
        .subscribe((prevValue: any) => {
          if (prevValue.isInlineEditable !== formValue.isInlineEditable) {
            updatedFormValue.openSalesStorySettingsOnClick = false;
          } else {
            updatedFormValue.isInlineEditable = false;
          }
        });
    }

    return updatedFormValue;
  }

  private updateFormFields(formValue: any): void {
    this.store.dispatch(
      Actions.updateDynamicFormFields({
        fields: formValue,
        valid: true,
      })
    );
  }
}
