import { Component, Inject, OnInit } from '@angular/core';

import { Store, select } from '@ngrx/store';

import { AuthService } from '@assurance/um-services';
import { DOMService, Global } from '@shared/services';
import { AppState } from '../reducers';
import { getLoading } from '@ngrx-app/global.selectors';
import { LOCATION_TOKEN } from '@core/constant';

@Component({
  selector: 'ensight-router-root',
  templateUrl: './router-root.component.html',
  styleUrls: ['./router-root.component.scss'],
})
export class RouterRootComponent implements OnInit {
  loading$ = this.store?.pipe(select(getLoading));
  showUserMenu: boolean;
  hideHeader = true;
  isSharedPresentation = false;

  constructor(
    public authService: AuthService,
    public domService: DOMService,
    public store: Store<AppState>,
    private global: Global,
    @Inject(LOCATION_TOKEN) private location: Location
  ) {}

  ngOnInit(): void {
    this.isSharedPresentation = this.global.isSharedPresentation();
  }

  // TODO: Need to find another way to hide header
  // https://assuranceapp.atlassian.net/browse/DAT-7139
  public activeRouter() {
    this.toggleHeader();
    this.domService.toggleHeader(true);
    this.showUserMenu = this.authService.isLogged;
  }

  // TODO: Need to find another way to hide header
  public deactivateRouter() {
    this.toggleHeader();
  }

  private toggleHeader(): void {
    const paths = ['policy-inforce-records', 'user-management', 'custom-pages', 'config-management', 'xml-statuses'];

    this.hideHeader = paths.some((path: string) => this.location.href.includes(`${location.origin}/${path}`));
  }
}
