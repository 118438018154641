import { NgModule } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CommonSeModule } from '@se/common';
import * as Sentry from '@sentry/browser';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { GlobalEffects } from '@ngrx-app/global.effects';
import { ApiInterceptor } from '@core/interceptors/api.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WinFlexRedirectComponent } from './components/win-flex-redirect.component';
import { LoginComponent } from './components/login/login.component';
import { OrganizationsSwitchComponent } from './components/login/organizations-switch/organizations-switch.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import {
  DOMService,
  SharedSocketService,
  PresentationApiService,
  ContentApiService,
  CoverLetterApiService,
  CarrierPlansApiService,
  SharedPresentationApiService,
  DownloadApiService,
  AgencyApiService,
  ThemeApiService,
  RoleApiService,
  StyleEditorService,
  DependentPermissionsService,
} from '@shared/services'; //TODO: need to check if these services need here, in most case dont
import { RouterRootComponent } from './components/router-root.component';
import { ForgotPasswordComponent } from './components/login/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/login/reset-password/reset-password.component';
import { ManagePasswordComponent } from './components/login/manage-password/manage-password.component';

import { reducers } from './reducers';

import { ClientService } from './components/presentation/shared/client-section/client-section.service';
import { DirectivesModule } from '@shared/directives/directives.module';
import { RouterRootNewComponent } from './components/router-root-new.component';
import { RouterRootNotFoundComponent } from './components/router-root-not-found.component';
import { IconModule, ModalModule } from '@assurance/bootstrap';
import { DropTicketModalModule } from './components/presentation/shared/drop-ticket/drop-ticket-modal.module';
import { DistributedModalModule } from './components/presentation/shared/distributed-modal/distributed-modal.module';
import { ShortfallSettingsModalModule } from './components/presentation/shared/shortfall-settings-modal/shortfall-settings-modal.module';

import { isPDF } from '@core/utils';
import { DynamicFormModule } from '@se/dynamic-form';
import { UmServicesModule } from '@assurance/um-services';
import { UmMiscModule } from '@assurance/um-misc';
import {
  ChartsInjectService,
  ChartOptionsService,
  ChartDataService,
  LocalStorageService,
  StyleSchemaService,
} from '@core/service'; // TODO: need to move to core module
import { CoreModule } from '@core/core.module';
import {
  UserMenuModule,
  LoaderModule,
  StyleEditorModule,
  SupportInfoModule,
  BrowserMessageModule,
} from '@shared/components'; //TODO: need to check if these modules need here, in most case dont
import { CoverLetterModalModule, SalesConceptModalModule } from './components/presentation/modals';
import { HeaderComponent } from './common/shared/header/header.component'; //TODO: need to move to shared/components

const sentryDSN = localStorage.getItem('sentryDSN');

if (sentryDSN && !isPDF) {
  Sentry.init({
    dsn: sentryDSN,
    environment: window.location.hostname,
    denyUrls: ['localhost', '127.0.0.1'],
  });
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    RouterRootComponent,
    RouterRootNewComponent,
    RouterRootNotFoundComponent,
    LoginComponent,
    OrganizationsSwitchComponent,
    NotFoundComponent,
    ManagePasswordComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    WinFlexRedirectComponent,
    HeaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    ShortfallSettingsModalModule,
    DropTicketModalModule,
    DistributedModalModule,
    CoverLetterModalModule,
    SalesConceptModalModule,
    ModalModule,
    IconModule,
    DirectivesModule,
    StyleEditorModule,
    CommonSeModule,
    DynamicFormModule,
    UmMiscModule,
    UmServicesModule.forRoot({
      userManagementURL: 'userManagementURL',
      contentServiceURL: 'contentServiceURL',
      userManagementNodeUrl: 'umNodeURL',
      s3BucketName: 's3UmBucketName',
      s3BucketOrgImages: 's3UmBucketOrgImages',
      s3BucketUserImages: 's3UmBucketUserImages',
      userManagementNodeDirectUrl: 'userManagementNodeDirectUrl',
    }),
    TooltipModule,
    AlertModule,
    BsDatepickerModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: false,
      },
    }),
    EffectsModule.forRoot([GlobalEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 100,
      connectInZone: true,
    }),
    UserMenuModule,
    LoaderModule,
    SupportInfoModule,
    BrowserMessageModule,
  ],
  providers: [
    AgencyApiService,
    PresentationApiService,
    ContentApiService,
    CoverLetterApiService,
    CarrierPlansApiService,
    SharedPresentationApiService,
    DownloadApiService,
    ThemeApiService,
    RoleApiService,
    DOMService,
    DependentPermissionsService,
    LocalStorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    ClientService,
    ChartsInjectService,
    ChartOptionsService,
    ChartDataService,
    SharedSocketService,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    StyleSchemaService,
    StyleEditorService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
