import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { debounceTime, filter, first, groupBy, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { isNumber } from 'lodash';
import { isString } from 'lodash-es';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { FormSharedService } from '@se/dynamic-form';

import { AppState } from '../../../../reducers';
import * as Actions from '../../redux/placeholders-wizard.actions';
import {
  activeInsertTypeState,
  dynamicFormValue,
  getCustomPageAndInserts,
  placeholdersMetadata,
  selectedInsertType,
  selectFormValidation,
} from '../../redux';
import {
  INSERT_TYPE_STATE,
  CHART_CONFIGURATION_STATE,
  CHART_METRICS_STATE,
  INSERT_STEP_IDS,
  TEXT_CONFIGURATION_STATE,
  VARIABLES_STATE,
  CUSTOM_METRIC_STATE,
  IMAGE_STATE,
  PRODUCTS_DESCRIPTION_STATE,
  CUSTOM_TABLE_STATE,
  CUSTOM_TABLE_COLUMNS_STATE,
  BUTTON_STATE,
  API_CALL_CONFIGURATION_STATE,
  POST_API_CALL_STATE,
  API_CALL_RESPONSE_STATE,
  CUSTOM_PAGE,
  INSERTS_FROM_OTHER_PAGE,
  CHART_STYLE_CONFIGURATION_STATE,
  DROPDOWN_STATE,
  PRODUCT_SELECTOR_STATE,
  TAB_STATE,
  TAB_AREA_STATE,
  defaultXAxisSourceTypeStart,
  defaultXAxisSourceTypeEnd,
  CUSTOM_METRIC_FORMULA_STATE,
} from '../../constants';
import { TinyEditorService } from '@shared/services';
import * as PlaceholdersWizardActions from '../../redux/placeholders-wizard.actions';
import { CustomPage, Insert, InsertIds, ImageInfo, WizardModalResponse } from '@shared/models';
import {
  InsertType,
  PlaceholderAction,
  PlaceholdersDynamicFormValue,
  PlaceholderWizardInsertTypeState,
} from '@core/model';
import { FormValue } from '../../models';
import { ChartDataSource, ChartTypes, INSERT_TYPE } from '@core/enums';
import { PlaceholdersWizardService } from '../../services';
import { ModalConfig, ModalRef } from '@assurance/bootstrap';

@UntilDestroy()
@Component({
  selector: 'ep-placeholders-wizard',
  templateUrl: './placeholders-wizard.component.html',
  styleUrls: ['./placeholders-wizard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceholdersWizardComponent implements OnInit, OnDestroy {
  editMode: boolean;

  chartConfigurationStateId = CHART_CONFIGURATION_STATE.id;
  chartStyleConfigurationStateId = CHART_STYLE_CONFIGURATION_STATE.id;
  textConfigurationStateId = TEXT_CONFIGURATION_STATE.id;
  variableConfigurationStateId = VARIABLES_STATE.id;
  buttonConfigurationStateId = BUTTON_STATE.id;
  insertTypeStateId = INSERT_TYPE_STATE.id;
  chartMetricsStateId = CHART_METRICS_STATE.id;
  imageStateId = IMAGE_STATE.id;
  customMetricStateId = CUSTOM_METRIC_STATE.id;
  customMetricFormulaStateId = CUSTOM_METRIC_FORMULA_STATE.id;
  productsDescriptionStateId = PRODUCTS_DESCRIPTION_STATE.id;
  customTableStateId = CUSTOM_TABLE_STATE.id;
  customTableColumnsStateId = CUSTOM_TABLE_COLUMNS_STATE.id;
  apiCallConfigurationStateId = API_CALL_CONFIGURATION_STATE.id;
  postApiCallStateId = POST_API_CALL_STATE.id;
  apiCallResponseStateId = API_CALL_RESPONSE_STATE.id;
  customPageStateId = CUSTOM_PAGE.id;
  insertsStateId = INSERTS_FROM_OTHER_PAGE.id;
  dropdownStateId = DROPDOWN_STATE.id;
  productSelectorStateId = PRODUCT_SELECTOR_STATE.id;
  tabStateId = TAB_STATE.id;
  tabAreaStateId = TAB_AREA_STATE.id;
  activeInsertTypeState: PlaceholderWizardInsertTypeState;
  selectedInsertType: InsertType;
  isValidForm: boolean;
  isRequiredFieldsLabelShown: boolean;
  isBackBtnShown: boolean;
  buttonLabel: string;
  insertsFromOtherPage: InsertIds[];
  title: string;

  //@ts-ignore
  private dynamicForm: UntypedFormGroup;
  private previousSelectedXAxisSource = 'presentationBased';

  constructor(
    private formSharedService: FormSharedService,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private placeholdersWizardService: PlaceholdersWizardService,
    public modal: ModalRef,
    public config: ModalConfig<any>
  ) {}

  ngOnInit(): void {
    this.editMode = this.config.data.editMode;
    this.store.dispatch(Actions.setDynamicFormOpen({ payload: true }));
    this.store.dispatch(Actions.resetFormValidation());
    // this.handlePlaceholderStateKey();
    // this.handlePostApiCallStateId();
    // this.handleCustomMetricFormulaStateId();
    // this.handleFormDirtyAndEditMode();
    this.dynamicFormSubscriptions();
    this.watchForActiveInsertTypeState();
    this.watchForSelectedInsertType();
    this.watchForFormState();
    this.watchForDropdownInsertForm();
    this.setTitle();
    this.setRequiredLabel();
  }

  ngOnDestroy(): void {
    this.store.dispatch(Actions.setCustomPage({ customPage: null }));
    this.store.dispatch(Actions.resetDynamicFormFields());
    this.store.dispatch(Actions.resetInsertTypeStates());
    this.store.dispatch(Actions.resetFormValidation());
    this.store.dispatch(Actions.resetImagesList());
    this.store.dispatch(Actions.setDynamicFormOpen({ payload: false }));
  }

  closeModal(): void {
    this.modal.close({ submitted: false });
  }

  submitModal(data: WizardModalResponse): void {
    this.modal.close({ submitted: true, data });
  }

  next(): void {
    if (!INSERT_STEP_IDS.includes(this.activeInsertTypeState.id)) {
      this.watchForFormValue().subscribe(formValue => {
        this.updateChartDataSource(formValue);
        this.store.dispatch(Actions.nextState({ stepsToSkip: this.getStepsToSkip(formValue) }));
      });

      return;
    }

    if (this.activeInsertTypeState.id === this.insertsStateId) {
      this.insertPlaceholdersFormOtherPage();
    } else {
      this.insert();
    }
  }

  back(): void {
    this.watchForFormValue().subscribe(formValue => {
      this.store.dispatch(Actions.backState({ stepsToSkip: this.getStepsToSkip(formValue) }));
      this.store.dispatch(Actions.resetFormValidation());
      this.cdr.markForCheck();
    });
  }

  setImageOnUpload(event: ImageInfo): void {
    this.store.dispatch(
      PlaceholdersWizardActions.setImage({
        imageData: {
          ...event,
          upload: true,
          delete: false,
          loading: true,
        },
      })
    );
  }

  setImageSelect(event: ImageInfo): void {
    this.store.dispatch(
      PlaceholdersWizardActions.selectImage({
        name: event.name,
      })
    );
  }

  setImageDelete(event: ImageInfo): void {
    if (event.upload) {
      URL.revokeObjectURL(event.url);
    }

    this.store.dispatch(
      PlaceholdersWizardActions.deleteImage({
        name: event.name,
      })
    );
  }

  setImageLoaded(event: ImageInfo): void {
    this.store.dispatch(
      PlaceholdersWizardActions.loadedImage({
        name: event.name,
      })
    );
  }

  private insert(): void {
    const config: PlaceholderAction & { id: string } = this.editMode
      ? {
          id: this.config.data.id,
          edit: true,
        }
      : { id: TinyEditorService.generateId(), create: true };

    this.placeholdersWizardService
      .setPlaceholderStateById(config)
      .pipe(untilDestroyed(this))
      .subscribe(data => this.submitModal(data));
  }

  private insertPlaceholdersFormOtherPage(): void {
    this.placeholdersWizardService
      .setPlaceholdersFromOtherPage()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.closeModal());
  }

  private handlePlaceholderKey(form: UntypedFormGroup, cdRef: ChangeDetectorRef): void {
    this.store
      .select(placeholdersMetadata)
      .pipe(
        map(placeholders => placeholders.filter(placeholder => !placeholder.delete)),
        untilDestroyed(this)
      )
      .subscribe(placeholdersMetadata => {
        if (placeholdersMetadata) {
          const existingPlaceholder = placeholdersMetadata.find(
            placeholder => placeholder.insertType === this.selectedInsertType && placeholder.id === this.config.data.id
          );

          const placeholderKey = existingPlaceholder
            ? existingPlaceholder.placeholderKey
            : this.placeholdersWizardService.setGeneratedPlaceholderKey(form);

          const keyExists = placeholdersMetadata.some(
            placeholder =>
              placeholder.placeholderKey === placeholderKey &&
              placeholder.insertType === this.selectedInsertType &&
              placeholder.id !== this.config.data.id
          );

          keyExists
            ? form.controls.placeholderKey.setValidators([Validators.pattern(/(?!)/)])
            : form.controls.placeholderKey.setValidators([Validators.pattern(/(?:)/)]);

          form.controls.placeholderKey.markAsTouched();

          form.controls.placeholderKey.updateValueAndValidity({
            emitEvent: false,
          });

          this.placeholdersWizardService.updateDynamicFormFields(
            { ...form.value, placeholderKey, edit: this.editMode || false },
            form.valid
          );
          cdRef.markForCheck();
        }
      });
  }

  private watchForSharedFormValue(): Observable<FormValue> {
    return (this.formSharedService.valueChangePure$ as Observable<FormValue>).pipe(
      groupBy((form: FormValue) => form.formId),
      mergeMap(formGroup$ => formGroup$.pipe(debounceTime(100))),
      filter(() => this.activeInsertTypeState.id !== this.insertTypeStateId)
    );
  }

  // @ts-ignore
  private handlePostApiCallStateId(): void {
    this.watchForSharedFormValue()
      .pipe(
        filter((formValue: FormValue) => formValue.formId === this.postApiCallStateId),
        untilDestroyed(this)
      )
      .subscribe((formValue: FormValue) => {
        const value =
          formValue.form.touched || isString(formValue.form.value.apiCallBody)
            ? formValue.form.value
            : { ...formValue.form.value, apiCallBody: JSON.stringify(formValue.form.value.apiCallBody) };
        this.placeholdersWizardService.updateDynamicFormFields(value, formValue.form.valid);

        this.cdr.detectChanges();
      });
  }

  // @ts-ignore
  private handleCustomMetricFormulaStateId(): void {
    this.watchForSharedFormValue()
      .pipe(
        filter((formValue: FormValue) => formValue.formId === this.customMetricFormulaStateId),
        untilDestroyed(this)
      )
      .subscribe((formValue: FormValue) => {
        this.dynamicForm = formValue.form;
        this.placeholdersWizardService.updateDynamicFormFields(formValue.form.value, formValue.form.valid);

        this.cdr.detectChanges();
      });
  }

  // @ts-ignore
  private handlePlaceholderStateKey(): void {
    this.watchForSharedFormValue()
      .pipe(untilDestroyed(this))
      .subscribe((formValue: FormValue) => {
        const isHandlePlaceholderKey = [
          this.customMetricStateId,
          this.variableConfigurationStateId,
          this.dropdownStateId,
          this.productSelectorStateId,
        ].some(id => id === formValue.formId);

        const isNotToHandlePlaceholderKey = [
          this.chartConfigurationStateId,
          this.chartStyleConfigurationStateId,
          this.textConfigurationStateId,
          this.buttonConfigurationStateId,
          this.imageStateId,
          this.productsDescriptionStateId,
          this.customTableStateId,
          this.apiCallConfigurationStateId,
        ].some(id => id === formValue.formId);

        if (isHandlePlaceholderKey) {
          this.handlePlaceholderKey(formValue.form, formValue.cdRef);
        } else if (isNotToHandlePlaceholderKey) {
          this.placeholdersWizardService.updateDynamicFormFields(formValue.form.value, formValue.form.valid);
        }

        this.cdr.detectChanges();
      });
  }

  // @ts-ignore
  private handleFormDirtyAndEditMode(): void {
    this.watchForSharedFormValue()
      .pipe(untilDestroyed(this))
      .subscribe((formValue: FormValue) => {
        const { form, formId } = formValue;

        if (form.dirty || this.editMode) {
          if (this.activeInsertTypeState.id === this.apiCallResponseStateId) {
            this.placeholdersWizardService.updateDynamicFormTabs(formId, form.value, form.valid, 'results');
          }

          if (this.activeInsertTypeState.id === this.customTableColumnsStateId) {
            this.placeholdersWizardService.updateDynamicFormTabs(formId, form.value, form.valid);
          }

          if (this.activeInsertTypeState.id === this.chartMetricsStateId) {
            this.placeholdersWizardService.updateDynamicFormMetrics(formId, form.value, form.valid);
          }
        }

        if (!isNumber(formId)) {
          this.store.dispatch(
            Actions.setFormValidation({
              formId: formId,
              isValid: form.valid,
            })
          );
        }

        this.cdr.detectChanges();
      });
  }
  // @ts-ignore
  private dynamicFormSubscriptions(): void {
    this.watchForSharedFormValue()
      .pipe(untilDestroyed(this))
      .subscribe((formValue: FormValue) => {
        const { form, formId, cdRef } = formValue;

        if (
          formId === this.customMetricStateId ||
          formId === this.variableConfigurationStateId ||
          formId === this.dropdownStateId ||
          formId === this.productSelectorStateId ||
          formId === this.tabStateId
        ) {
          this.handlePlaceholderKey(form, cdRef);
        } else if (
          formId === this.chartConfigurationStateId ||
          formId === this.chartStyleConfigurationStateId ||
          formId === this.textConfigurationStateId ||
          formId === this.buttonConfigurationStateId ||
          formId === this.imageStateId ||
          formId === this.productsDescriptionStateId ||
          formId === this.customTableStateId ||
          formId === this.apiCallConfigurationStateId
        ) {
          if (formId === this.chartConfigurationStateId) {
            this.handleXAxisSourceChanges(form, cdRef);
          }

          this.placeholdersWizardService.updateDynamicFormFields(form.value, form.valid);
        } else if (formId === this.postApiCallStateId) {
          this.placeholdersWizardService.updateDynamicFormFields(
            form.touched || isString(form.value.apiCallBody)
              ? form.value
              : {
                  ...form.value,
                  apiCallBody: JSON.stringify(form.value.apiCallBody),
                },
            form.valid
          );
        } else if (formId === this.customMetricFormulaStateId) {
          this.dynamicForm = form;
          this.placeholdersWizardService.updateDynamicFormFields(form.value, form.valid);
        } else if (form.dirty || this.editMode) {
          if (this.activeInsertTypeState.id === this.apiCallResponseStateId) {
            this.placeholdersWizardService.updateDynamicFormTabs(formId as number, form.value, form.valid, 'results');
          }

          if (this.activeInsertTypeState.id === this.customTableColumnsStateId) {
            this.placeholdersWizardService.updateDynamicFormTabs(formId as number, form.value, form.valid);
          }

          if (this.activeInsertTypeState.id === this.tabAreaStateId) {
            this.placeholdersWizardService.updateDynamicFormTabs(formId as number, form.value, form.valid, 'tabs');
          }

          if (this.activeInsertTypeState.id === this.chartMetricsStateId) {
            this.placeholdersWizardService.updateDynamicFormMetrics(formId as number, form.value, form.valid);
          }
        }

        if (!isNumber(formId)) {
          this.store.dispatch(
            Actions.setFormValidation({
              formId: formId as number,
              isValid: form.valid,
            })
          );
        }

        this.cdr.detectChanges();
      });
  }

  private handleXAxisSourceChanges(form: UntypedFormGroup, cdRef: ChangeDetectorRef): void {
    if (form.value?.xAxisSource && form.value?.xAxisSource !== 'presentationBased') {
      if (form.value.xAxisSourceStart === defaultXAxisSourceTypeStart) {
        form.controls.xAxisSourceStart.setValue('');
      }

      if (form.value.xAxisSourceEnd === defaultXAxisSourceTypeEnd) {
        form.controls.xAxisSourceEnd.setValue('');
      }

      cdRef.markForCheck();
    }

    if (form.value?.xAxisSource) {
      form.controls.xAxisSource.valueChanges.pipe(untilDestroyed(this)).subscribe((newValue: string) => {
        if (this.previousSelectedXAxisSource !== newValue && newValue === 'presentationBased') {
          form.controls.xAxisSourceStart.setValue(defaultXAxisSourceTypeStart);
          form.controls.xAxisSourceEnd.setValue(defaultXAxisSourceTypeEnd);
        }

        this.previousSelectedXAxisSource = newValue;
      });
      cdRef.markForCheck();
    }
  }

  private getStepsToSkip(formValue): string[] {
    if (this.selectedInsertType === INSERT_TYPE.apiCall && formValue?.apiMethod === 'get') {
      return [this.postApiCallStateId];
    }

    return [];
  }

  private watchForActiveInsertTypeState(): void {
    this.store
      .select(activeInsertTypeState)
      .pipe(
        untilDestroyed(this),
        tap((state: PlaceholderWizardInsertTypeState) => (this.activeInsertTypeState = state)),
        switchMap(() => this.store.select(getCustomPageAndInserts)),
        tap((result: [CustomPage, Insert[]]) => this.setIsValidFormBaseOnDataAndSate(result))
      )
      .subscribe(() => {
        this.setButtonLabel();
        this.toggleBackButton();
        this.toggleRequiredLabel();
        this.cdr.markForCheck();
      });
  }

  private toggleRequiredLabel(): void {
    this.isRequiredFieldsLabelShown = [
      this.customMetricStateId,
      this.variableConfigurationStateId,
      this.buttonConfigurationStateId,
      this.textConfigurationStateId,
      this.imageStateId,
      this.productsDescriptionStateId,
      this.dropdownStateId,
      this.productSelectorStateId,
      this.tabStateId,
    ].some((id: string) => this.activeInsertTypeState.id === id);
  }

  private toggleBackButton(): void {
    if (this.editMode) {
      this.isBackBtnShown = [
        this.chartMetricsStateId,
        this.customTableColumnsStateId,
        this.apiCallResponseStateId,
        this.postApiCallStateId,
      ].some((id: string) => this.activeInsertTypeState.id === id);
    }

    this.isBackBtnShown = this.activeInsertTypeState.id !== this.insertTypeStateId;
  }

  private setButtonLabel(): void {
    const finalLabel = this.editMode ? 'Save' : 'Insert';

    this.buttonLabel = INSERT_STEP_IDS.includes(this.activeInsertTypeState.id) ? finalLabel : 'Next';
  }

  private watchForSelectedInsertType(): void {
    this.store
      .select(selectedInsertType)
      .pipe(untilDestroyed(this))
      .subscribe(selectedInsertType => (this.selectedInsertType = selectedInsertType));
  }

  private watchForFormState(): void {
    this.store
      .select(selectFormValidation)
      .pipe(untilDestroyed(this))
      .subscribe(isValid => {
        this.isValidForm = isValid;
        this.cdr.markForCheck();
      });
  }

  private watchForFormValue(): Observable<PlaceholdersDynamicFormValue> {
    return this.store.select(dynamicFormValue).pipe(first(), untilDestroyed(this));
  }

  // Set false/true to isValidForm because in case of select option 'From Other Page'
  // we don't use forms as for other options therefore we need to enable/disable next/insert button.

  private setIsValidFormBaseOnDataAndSate(result: [CustomPage, Insert[]]): void {
    const [customPage, inserts] = result;

    if (this.activeInsertTypeState.id === this.customPageStateId) {
      this.isValidForm = !!customPage;
    }

    if (this.activeInsertTypeState.id === this.insertsStateId) {
      this.isValidForm = inserts && inserts.length > 0;
    }

    if (this.activeInsertTypeState && this.activeInsertTypeState.id === this.insertTypeStateId) {
      this.isValidForm = true;
    }
  }

  private watchForDropdownInsertForm(): void {
    this.watchForSharedFormValue()
      .pipe(
        filter((formValue: FormValue) => formValue.formId === this.dropdownStateId),
        untilDestroyed(this)
      )
      .subscribe((formState: FormValue) => {
        const options = formState.form.controls['dropdownOptions'].value;
        const invalid = options.length === 1 && options[0].value === '';
        const formNameValue = formState.form.controls.placeholderName.value;
        const formNameValueNotEmpty = formNameValue !== '' && !!formNameValue.replace(/\s/g, '').length;
        const formOptions = formState.form.controls.dropdownOptions.value;
        const formDefaultOption = formState.form.controls.defaultOption?.value;
        const optionsValueNotEmpty = formOptions.map(e => e.value !== '').every(v => v === true);

        this.isValidForm =
          !invalid &&
          !!options.length &&
          formNameValueNotEmpty &&
          optionsValueNotEmpty &&
          (formDefaultOption || formDefaultOption === 0);
        this.cdr.markForCheck();
      });
  }

  private updateChartDataSource(formValue): void {
    if (
      this.selectedInsertType === INSERT_TYPE.chart &&
      formValue?.chartType !== ChartTypes.pie &&
      formValue?.chartType !== ChartTypes.donut
    ) {
      this.placeholdersWizardService.updateDynamicFormFields(
        {
          ...formValue,
          chartDataSource: ChartDataSource.dynamicMetrics,
        },
        true
      );
    }
  }

  private setTitle(): void {
    combineLatest([this.watchForSharedFormValue(), this.store.select(dynamicFormValue)])
      .pipe(untilDestroyed(this), untilDestroyed(this))
      .subscribe((data: [FormValue, any]) => {
        const [formInfo, formRawValue] = data;
        const title = this.activeInsertTypeState?.title;
        const name = formRawValue?.placeholderName || '';

        this.title = [this.customMetricStateId, this.customMetricFormulaStateId].includes(formInfo.formId)
          ? `${title} - ${name}`
          : title;
        this.cdr.markForCheck();
      });
  }

  private setRequiredLabel(): void {
    this.watchForSharedFormValue().subscribe((formValue: FormValue) => {
      if (formValue.form.value?.isInlineEditable) {
        this.isRequiredFieldsLabelShown = false;
        this.cdr.markForCheck();
      }
    });
  }
}
