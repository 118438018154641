import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GA_NOT_SET, GA_V2 } from '@shared/constants';
import { TimeService } from '@shared/services';
import { UserService } from '@assurance/um-services';
import { Global } from './global.service-ng2';
import { WINDOW_TOKEN } from '@core/constant';
import { UserPilotService } from '@core/service';
import * as apiConstant from '@core/constant';

@Injectable({
  providedIn: 'root',
})
export class GAService {
  constructor(
    private userService: UserService,
    private global: Global,
    private http: HttpClient,
    private timeService: TimeService,
    private userPilotService: UserPilotService,
    @Inject(WINDOW_TOKEN) private window: Window
  ) {}

  private getGAEventLabel(): string {
    let from = 'From Presentation Setup';

    //TODO: need to remove
    if (this.global.isPresentationViewRoute()) {
      from = 'From Presentation View';
    }

    if (this.global.isSharedPresentation()) {
      from = 'From Presentation Shareable View';
    }

    return from;
  }

  sendExitPresentationViewEvent(): void {
    this.sendPresentationEvent({
      eventAction: 'Exit Presentation View',
    });
  }
  //TODO: REMOVE SUBSCRIPTION FROM SERVICE
  sendDownloadPdfEvent(): void {
    this.global.getSelectedPageName().subscribe(pageName => {
      this.sendPresentationEvent({
        eventAction: 'Download PDF',
        pageName,
      });
    });
  }

  sendFollowIllustrationLinkEvent(): void {
    this.global.getSelectedPageName().subscribe(pageName => {
      this.sendPresentationEvent({
        eventAction: 'Follow Illustration Link',
        pageName,
      });
    });
  }

  sendColumnsButtonClickEvent(): void {
    this.global.getSelectedPageName().subscribe(pageName => {
      this.sendPresentationEvent({
        eventAction: 'Click Columns button',
        pageName,
      });
    });
  }

  sendCopyTableEvent(): void {
    this.global.getSelectedPageName().subscribe(pageName => {
      this.sendPresentationEvent({
        eventAction: 'Click Copy button',
        pageName,
      });
    });
  }

  sendOpenPageSettingsEvent(): void {
    this.global.getSelectedPageName().subscribe(pageName => {
      this.sendPresentationEvent({
        eventAction: 'Open Page Settings',
        pageName,
      });
    });
  }

  sendOpenPageEvent(pageName: string): void {
    this.sendPresentationEvent({
      eventAction: 'Open Page',
      pageName,
    });
  }

  sendEnableTileEvent(): void {
    this.global.getSelectedPageName().subscribe(pageName => {
      this.sendPresentationEvent({
        eventAction: 'Enable Tile',
        pageName,
      });
    });
  }

  sendDisableTileEvent(): void {
    this.global.getSelectedPageName().subscribe(pageName => {
      this.sendPresentationEvent({
        eventAction: 'Disable Tile',
        pageName,
      });
    });
  }

  sendAddPageEvent(pageName: string): void {
    this.sendPresentationEvent({
      eventAction: 'Add Page',
      pageName,
    });
  }

  sendRemovePageEvent(pageName: string): void {
    this.sendPresentationEvent({
      eventAction: 'Remove Page',
      pageName,
    });
  }

  sendDragProductEvent(): void {
    this.global.getSelectedPageName().subscribe(pageName => {
      this.sendPresentationEvent({
        eventAction: 'Drag and drop product',
        pageName,
      });
    });
  }

  sendPinChangedEvent(elementName = 'Chart'): void {
    this.global.getSelectedPageName().subscribe(pageName => {
      this.sendPresentationEvent({
        eventAction: 'Drag and drop pin',
        pageName,
        elementName,
      });
    });
  }

  sendEnableProductEvent(): void {
    this.global.getSelectedPageName().subscribe(pageName => {
      this.sendPresentationEvent({
        eventAction: 'Enable Product',
        pageName,
      });
    });
  }

  sendDisableProductEvent(): void {
    this.global.getSelectedPageName().subscribe(pageName => {
      this.sendPresentationEvent({
        eventAction: 'Disable Product',
        pageName,
      });
    });
  }

  sendEditProductEvent(): void {
    this.sendPresentationEvent({
      eventAction: 'Edit Product',
    });
  }

  sendEditProductNoteEvent(): void {
    this.global.getSelectedPageName().subscribe(pageName => {
      this.sendPresentationEvent({
        eventAction: 'Edit Product Note',
        pageName,
      });
    });
  }

  sendInsertEvent({ elementName, eventAction }): void {
    this.global.getSelectedPageName().subscribe(pageName => {
      this.sendPresentationEvent({
        eventAction,
        pageName,
        elementName,
      });
    });
  }

  public sendSharedLinkAnalyticsEvent(data: { [key: string]: string }) {
    return this.http.post(apiConstant.sharedAnalytics, data);
  }

  //TODO: dont subscribe inside service, need to refact
  sendPresentationEvent({ eventAction, pageName = null, elementName = null }, withLinkSource = false): void {
    const options: { [key: string]: string } = {
      dimension8: GA_NOT_SET,
      dimension9: GA_NOT_SET,
      dimension10: GA_V2,
      sessionControl: 'start',
    };

    if (pageName) {
      options.dimension8 = pageName;
    }

    if (elementName) {
      options.dimension9 = elementName;
    }

    if (this.global.isSharedPresentation()) {
      const linkSourceValue = new URLSearchParams(this.window.location.search).get('linkSource') || 'Directly';

      this.sendSharedLinkAnalyticsEvent({
        cd8: options.dimension8,
        cd9: options.dimension9,
        cd10: options.dimension10,
        ec: 'Presentation',
        ea: eventAction,
        el: withLinkSource && linkSourceValue ? linkSourceValue : this.getGAEventLabel(),
      }).subscribe();
    } else {
      gtag('event', `Presentation - ${eventAction} - ${this.getGAEventLabel()}`, {
        EventCategory: 'Presentation',
        EventAction: eventAction,
        EventLabel: this.getGAEventLabel(),
        PresentationID: this.global.getActivePresentationId,
        PresentationName: this.global.getPresentation.name,
        TemplateID: GA_NOT_SET,
        TemplateName: GA_NOT_SET,
        UserID: this.userService.user.id,
        AgencyId: this.userService.organization.id,
        AgencyName: this.userService.organization.name,
        PresentationPage: options.dimension8,
        ElementName: options.dimension9,
        EventTimestamp: this.timeService.getPstTimeForGa4(),
      });
    }

    this.userPilotService.track({ eventAction, pageName, elementName });
  }

  sendShareableLinkEvent(agencyName: string, agencyId: number): void {
    gtag('event', 'Shareable link - Copy', {
      EventCategory: 'Shareable link',
      EventAction: 'Copy',
      EventLabel: GA_NOT_SET,
      PresentationID: this.global.getActivePresentationId,
      PresentationName: this.global.getPresentation.name,
      TemplateID: GA_NOT_SET,
      TemplateName: GA_NOT_SET,
      UserID: this.userService.user.id,
      AgencyId: agencyId,
      AgencyName: agencyName,
      PresentationPage: GA_NOT_SET,
      ElementName: GA_NOT_SET,
      EventTimestamp: this.timeService.getPstTimeForGa4(),
    });
  }
}
