import { Injectable } from '@angular/core';

import { cloneDeep, omit, sortBy } from 'lodash-es';

import {
  CustomPage,
  CustomPageInsert,
  Insert,
  InsertIds,
  MappedPresentationCount,
  Plan,
  PresentationData,
  CustomPageVersions,
  LinkedPage,
} from '@shared/models';
import { isDefined } from '@core/utils';
import { ALLIANZ_ORGANIZATION, PACIFIC_LIFE_ORGANIZATION } from '../../constants';
import {
  CarrierPlanOptional,
  CompiledCustomMetric,
  CustomPagesLists,
  LinkedPagesModal,
  MappedCarrierPlan,
} from '../../models';
import { Placeholder, PlaceholderFiles, PlaceholderMetric, Presentation } from '@core/model';
import { CALCULATED_DATA_TARGET, ChartTypes, INSERT_TYPE } from '@core/enums';

@Injectable()
export class CustomPageAdapterService {
  adaptCustomPage(customPage: CustomPage): CustomPage {
    const mappedInserts = customPage.inserts
      .map((insert: Insert) => {
        const foundOrder = customPage.customFields.insertIds.find((item: InsertIds) => {
          return item.uuid === insert.metadata.id;
        });

        insert.metadata.order = foundOrder.order;

        return insert;
      })
      .sort((a, b) => a.metadata.order - b.metadata.order);

    return {
      ...customPage,
      inserts: mappedInserts,
    };
  }

  adaptCarrierPlans(data: CarrierPlanOptional[]): MappedCarrierPlan[] {
    const metrics = isDefined(data) ? this.sortOrderOfChartMetrics(data) : [];

    return metrics.map(config => ({
      label: config.csv,
      name: config.db,
      isPrimitive: config?.isPrimitive,
    }));
  }

  adaptLinkedPage(data: CustomPage[], selectedPages: LinkedPage[], order?: string): Partial<LinkedPagesModal> {
    const filteredPages = data.filter(item => !!item.customFields.title);

    const mappedPages = filteredPages.map((item: CustomPage) => {
      return {
        selected: false,
        id: item.uiId,
        label: item.customFields.title,
        order: null,
        _id: item?._id,
      };
    });

    const mappedLinkedPages = selectedPages.map((item: LinkedPage) => {
      const page = mappedPages.find(page => page.id === item.uiId);

      return {
        selected: true,
        id: item.uiId,
        label: page.label,
        order: item?.order,
        _id: page?._id,
      };
    });

    return {
      pages: sortBy(mappedPages, 'label'),
      selectedPages: sortBy(mappedLinkedPages, order ? order : 'label'),
    };
  }

  adaptVersions(data: CustomPageVersions): CustomPageVersions {
    data.versions = sortBy(data.versions, 'startDate');

    return data;
  }

  adaptPresentations(data: PresentationData): MappedPresentationCount {
    const [presentations, plans, count] = data;

    const mappedPresentations = presentations.map((p: Presentation) => {
      const plansById = plans.find((item: Plan) => item.id === p.id);

      return {
        ...p,
        carrierPlansCount: plansById.carrierPlansCount,
      };
    });

    return {
      count,
      presentations: mappedPresentations,
    };
  }

  adaptCompiledInserts(data: Insert[]): CustomPageInsert[] {
    return data.map((insert: Insert) => {
      const valueByType = {
        [INSERT_TYPE.text]: 'placeholderText',
        [INSERT_TYPE.variable]: 'placeholderDefaultValue',
      };
      const calculatedDataKey = [INSERT_TYPE.customTable, INSERT_TYPE.customMetric];

      return {
        config: {
          editable: insert.editable,
          calculable: insert.calculable,
          uiId: insert.metadata.id,
        },
        metadata: {
          ...insert.metadata,
          value: insert.metadata[valueByType[insert.metadata.insertType]],
        },
        calculatedData: calculatedDataKey.some(i => i === insert.metadata.insertType) ? insert.calculatedData : null,
        filesLinks: insert.metadata.insertType === INSERT_TYPE.image ? insert.metadata.filesLinks : [],
        errorMessage: insert?.errorMessage,
      };
    });
  }

  adaptCustomPagesLists(data: CustomPagesLists): CustomPagesLists {
    return Object.keys(data).reduce((acc: CustomPagesLists, key: string) => {
      return {
        ...acc,
        [key]: {
          ...data[key],
          data: data[key].data.map((page: CustomPage) => ({ ...page, isExpended: !!page.numDependentPages })),
        },
      };
    }, {} as CustomPagesLists);
  }

  //TODO: need to refact this method
  getFormDataForPlaceholder(placeholder: Placeholder, placeholderFiles?: PlaceholderFiles): FormData {
    const metadata = cloneDeep(placeholder);
    const formData = new FormData();
    const insertType = placeholder.insertType;

    delete metadata.usedInPages;

    if (
      [
        INSERT_TYPE.text,
        INSERT_TYPE.variable,
        INSERT_TYPE.image,
        INSERT_TYPE.dropdown,
        INSERT_TYPE.productSelector,
        INSERT_TYPE.tab,
      ].includes(insertType)
    ) {
      formData.append('editable', 'true');
    }

    if (
      [
        INSERT_TYPE.customMetric,
        INSERT_TYPE.productsDescription,
        INSERT_TYPE.customTable,
        INSERT_TYPE.dropdown,
      ].includes(insertType)
    ) {
      formData.append('calculable', 'true');
    }

    if (insertType === INSERT_TYPE.variable) {
      Object.assign(metadata, { calculatedDataTarget: CALCULATED_DATA_TARGET.variables });

      if (metadata.isInlineEditable) {
        ['placeholderMinValue', 'placeholderMaxValue', 'placeholderDefaultValue'].forEach(key => {
          if (metadata[key] === '') {
            metadata[key] = null;
          }
        });
      }
    }

    if (insertType === INSERT_TYPE.productsDescription) {
      Object.assign(metadata, {
        placeholderKey: placeholder.placeholderKey || `notes_${placeholder.id}`,
        calculatedDataTarget: placeholder.calculatedDataTarget || CALCULATED_DATA_TARGET.metadata,
      });
    }

    if (insertType === INSERT_TYPE.customTable) {
      const columns = {
        columns: placeholder.columns
          .map((column, i) => ({
            ...column,
            placeholderKey: placeholder.id + i,
          }))
          .sort((a, b) => a.index - b.index),
        calculatedDataTarget: CALCULATED_DATA_TARGET.data,
        productIndex: isNaN(+placeholder.productIndex)
          ? placeholder.productIndex
          : Math.max(0, +placeholder.productIndex - 1),
      };

      Object.assign(metadata, columns);
    }

    if (placeholderFiles) {
      for (const file of placeholderFiles.files) {
        formData.append('files', file);
      }

      if (placeholderFiles && placeholderFiles.filesToDelete.length) {
        formData.append('filesToDelete', JSON.stringify(placeholderFiles.filesToDelete));
      }
    }

    if (insertType === INSERT_TYPE.dropdown) {
      const selectedValue = metadata.defaultOption || metadata.dropdownOptions[0].value;
      Object.assign(metadata, {
        placeholderDefaultValue: selectedValue,
        placeholderSelectedValue: selectedValue,
        calculatedDataTarget: CALCULATED_DATA_TARGET.dropdowns,
      });
    }

    //TODO: need to delete defaultProductSelector prop
    if (insertType === INSERT_TYPE.productSelector) {
      Object.assign(metadata, {
        // placeholderDefaultValue: metadata.defaultProductSelector,
        calculatedDataTarget: CALCULATED_DATA_TARGET.productSelector,
        placeholderSelectedProduct: metadata.placeholderDefaultValue,
      });
    }

    if (insertType === INSERT_TYPE.tab) {
      Object.assign(metadata, { placeholderSelectedTab: 0 });
    }

    if (insertType === INSERT_TYPE.chart) {
      metadata.metrics = metadata.metrics.filter(
        (metric: PlaceholderMetric) => metric.metricType === metadata.chartDataSource
      );

      if (metadata.chartType !== ChartTypes.donut && metadata.chartType !== ChartTypes.pie) {
        metadata.chartDataSource = null;
      }
    }

    delete metadata.filesLinks;
    delete metadata.order;

    formData.append('metadata', JSON.stringify(omit(metadata, ['edit', 'create', 'delete'])));

    return formData;
  }

  adaptInsertsForCompileMetric(data: [Placeholder[], Record<string, any>]): Partial<Insert>[] {
    const [placeholders, formValue] = data;

    const newOrEditPlaceholder = this.getNewOrEditedPlaceholder(placeholders, formValue);

    const modified = [
      ...placeholders.filter(item => item.placeholderKey !== formValue?.placeholderKey),
      ...(newOrEditPlaceholder as Placeholder[]),
    ];

    return sortBy(modified, 'order')
      .filter(item => !item.delete)
      .map(item => this.getFormDataForPlaceholder(item))
      .map((item: FormData) => {
        //@ts-ignore
        const entries: Record<string, boolean | FormDataEntryValue> = Object.fromEntries(item);
        const metadata = JSON.parse(<string>entries.metadata);

        Object.keys(entries).forEach((key: string) => {
          if (['editable', 'calculable'].includes(key)) {
            entries[key] = Boolean(entries[key]);
          }
        });

        return {
          ...entries,
          metadata,
          _id: metadata._id,
        };
      });
  }

  adaptCustomMetricCompilation(page: CustomPage, placeholderKey: string): CompiledCustomMetric {
    const insert = page?.inserts.find(insert => insert.metadata.placeholderKey === placeholderKey);
    const result = insert.calculatedData ? { calculatedData: insert.calculatedData } : {};

    return {
      errorMessage: !!insert.errorMessage,
      compilationResult: insert.errorMessage ? insert.errorMessage : JSON.stringify(result, null, '\t'),
    };
  }

  private sortOrderOfChartMetrics(metrics: CarrierPlanOptional[]): CarrierPlanOptional[] {
    const winFlexMetrics = [];
    const allianzMetrics = [];
    const annuityMetrics = [];

    metrics.forEach((metric: CarrierPlanOptional) => {
      if (metric.organization === ALLIANZ_ORGANIZATION) {
        allianzMetrics.push(metric);
      } else if (metric.organization === PACIFIC_LIFE_ORGANIZATION) {
        annuityMetrics.push(metric);
      } else {
        winFlexMetrics.push(metric);
      }
    });

    return [...winFlexMetrics, ...allianzMetrics, ...annuityMetrics];
  }

  private getNewOrEditedPlaceholder(placeholders: Placeholder[], formValue: Record<string, any>): Placeholder[] {
    const existingPlaceholder = placeholders.find(item => item.placeholderKey === formValue?.placeholderKey);

    if (existingPlaceholder) {
      return [{ ...existingPlaceholder, ...(formValue || {}) }];
    } else {
      if (formValue) {
        const placeholder = {
          ...formValue,
          _id: `id${Math.random()}`,
          insertType: INSERT_TYPE.customMetric,
          order: placeholders?.length || 0,
        };

        return [placeholder as Placeholder];
      } else {
        return [];
      }
    }
  }
}
