import { Injectable } from '@angular/core';

import { orderBy } from 'lodash-es';

import { Placeholder } from '@core/model';
import { findLastIndex } from '@shared/utils';

@Injectable()
export class PlaceholdersOrderService {
  public handleOrdering(placeholders: Placeholder[]): Placeholder[] {
    let orderedPlaceholders = orderBy(placeholders, ['order']);
    orderedPlaceholders = this.assignOrders(orderedPlaceholders);

    return orderedPlaceholders;
  }

  /**
   * Assign order value for given placeholder
   * in case when it's null
   * @param placeholders
   * @private
   */
  private assignOrders(placeholders: Placeholder[]): Placeholder[] {
    placeholders.forEach((placeholder: Placeholder, i) => {
      const prev = i > 0 ? placeholders[i - 1] : null;

      if (typeof placeholder.order !== 'number' && !placeholder.delete) {
        if (typeof prev?.order === 'number') {
          placeholder.order = prev.order + 1;
        } else {
          placeholder.order = 0;
        }
      }
    });

    return placeholders;
  }

  /**
   * Add new placeholders at the end of the last section of the same insert type
   * @param oldPlaceholders
   * @param newPlaceholders
   */
  public addNewPlaceholders(oldPlaceholders: Placeholder[], newPlaceholders: Placeholder[]): Placeholder[] {
    // need to unfreeze properties, otherwise assigning/modifying properties throws errors
    // todo: need to find another way to handle this issue
    const placeholders = JSON.parse(JSON.stringify(oldPlaceholders));
    newPlaceholders.forEach(pl => {
      // find the index of the last element with the same insert type
      // to be able to add an insert at the end of the last section of the same insert type
      const index = findLastIndex(placeholders, (placeholder: Placeholder) => {
        return placeholder.insertType === pl.insertType;
      });

      if (index > -1) {
        // if there's already exist placeholder(s) with same insert type
        // add new placeholder right after the last element with the same insert type
        placeholders.splice(index + 1, 0, pl);
      } else {
        // otherwise add new placeholder at the end of the list of placeholders
        placeholders.push(pl);
      }
    });
    // re-order placeholders to assign the order that equal to the current array index
    placeholders.forEach((pl, i) => (pl.order = i));

    return placeholders;
  }

  public reorderOnDeletePlaceholder(placeholders: Placeholder[], deletedPlaceholder: Placeholder): Placeholder[] {
    // need to unfreeze properties, otherwise assigning/modifying properties throws errors
    // todo: need to find another way to handle this issue
    placeholders = JSON.parse(JSON.stringify(placeholders));
    const index = placeholders.findIndex(pl => pl.id === deletedPlaceholder.id);
    index >= 0 && placeholders.splice(index, 1);
    placeholders.forEach((pl, i) => (pl.order = i));

    return this.handleOrdering(placeholders);
  }
}
