import { SortDirections, TableBodyColumn, TableHeaderColumn } from '@shared/models';

export const HistoryDetailsTableHeaderColumns: TableHeaderColumn[] = [
  {
    sortable: true,
    key: 'entityType',
    title: 'Entity Type',
  },
  {
    sortable: true,
    key: 'entityName',
    title: 'Entity Name',
  },
  {
    sortable: true,
    key: 'property',
    title: 'Property',
  },
  {
    sortable: true,
    key: 'changeType',
    title: 'Change Type',
  },
  {
    sortable: false,
    key: 'valueBefore',
    title: 'Value Before',
  },
  {
    sortable: false,
    key: 'valueAfter',
    title: 'Value After',
  },
];

export const HistoryDetailsTableBodyColumns: TableBodyColumn[] = [
  {
    key: 'entityType',
    isTruncateText: true,
    defaultValue: '',
  },
  {
    key: 'entityName',
    isTruncateText: true,
    defaultValue: '',
  },
  {
    key: 'property',
    isTruncateText: true,
    defaultValue: '',
  },
  {
    key: 'changeType',
    isTruncateText: true,
    defaultValue: '',
  },
  {
    key: 'valueBefore',
    isTruncateText: true,
    defaultValue: '-',
  },
  {
    key: 'valueAfter',
    isTruncateText: true,
    defaultValue: '-',
  },
];

export const HistoryTableColumnStyle: Record<string, Record<string, string>> = {
  entityType: { width: '16.66%' },
  entityName: { width: '16.66%' },
  property: { width: '16.66%' },
  changeType: { width: '16.66%' },
  valueBefore: { width: '16.66%' },
  valueAfter: { width: '16.66%' },
};

export const getHeaderColumns = () => {
  return HistoryDetailsTableHeaderColumns.map((column: TableHeaderColumn) => {
    return {
      ...column,
      style: { ...HistoryTableColumnStyle[column.key] },
    };
  });
};

export const getBodyColumns = () => {
  return HistoryDetailsTableBodyColumns.map((column: TableBodyColumn) => {
    return {
      ...column,
      style: { ...HistoryTableColumnStyle[column.key] },
    };
  });
};

export const DefaultHistoryDetailsOrderByRequestParam = {
  ORDER_BY: 'updated',
  DIRECTION: SortDirections.DESC,
};
