@if (getPlaceholders$ | async; as placeholders) {
  <section
    class="content-section"
    >
    @if (placeholders.length !== 0) {
      <label class="label">Inserts</label>
      <div class="insert-sections">
        <div
          [dragula]="dragModelBag"
          [dragulaModel]="placeholders"
          class="insert-sections-container"
          >
          @for (placeholder of placeholders; track placeholder; let i = $index) {
            @if (placeholders | sortInsertLabels: placeholder) {
              <div
                class="section-label-container"
                >
                <label #sectionLabel class="insert-section-label">
                  {{ placeholders | sortInsertLabels: placeholder }}
                </label>
                @if (sectionLabel.innerText) {
                  <div class="label-underline"></div>
                }
              </div>
            }
            @if (placeholder.insertType === 'customMetric') {
              @if (placeholders | showDataType: placeholder; as dataType) {
                <div
                  class="data-type-label"
                  >
                  <div class="data-type-container d-flex align-items-center">
                    <img
                      class="{{ dataType.className }}"
                      [src]="dataType.iconSrc"
                      [alt]="dataType.label + ' icon'"
                      />
                      <label class="data-type-text m-0">{{ dataType.label }}</label>
                    </div>
                  </div>
                }
              }
              <div
                class="insert-section-item draggable-item"
                [attr.data-placeholder-id]="placeholder.id"
                >
                <div
                  class="d-flex justify-content-between flex-grow-1 flex-shrink-1"
                  >
                  <div class="d-flex align-items-center">
                    @if (placeholder.errorMessage) {
                      <div class="warning-block">
                        <img src="/assets/icons/insert-warning-icon.svg" alt="Warning icon" />
                        </div>
                      }
                      <div class="d-flex flex-row align-items-center placeholder-name pr-2 m-0">
                        <p class="placeholder-name pr-2 m-0">
                          {{ placeholder.chartName || placeholder.placeholderName || 'Products Description' }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="d-flex"
                [ngClass]="{
                  'custom-metric-insert':
                    placeholder.insertType === 'customMetric'
                }"
                      >
                      <div
                  class="key-for-copy d-none align-items-center justify-content-between {{
                    placeholder.insertType
                  }} ml-3"
                        >
                        <div class="placeholder-key">
                          {{ placeholder.placeholderKey }}
                        </div>
                        <div class="copy-button-wrapper">
                          <button
                            type="button"
                      (click)="
                        copyToClipboard(
                          placeholder.insertType,
                          placeholder.placeholderKey,
                          i
                        )
                      "
                      [popover]="
                        (placeholder.insertType === 'customMetric'
                          ? placeholder.placeholderKey
                          : keyForCopy[placeholder.insertType] +
                            '.' +
                            placeholder.placeholderKey) + ' copied!'
                      "
                            [triggers]="null"
                            [outsideClick]="true"
                            [isOpen]="copiedIndex === i"
                            [containerClass]="'popover-copied'"
                            class="btn-copy fa fa-regular fa-copy border-0"
                          ></button>
                        </div>
                        @if (placeholder.insertType === 'customMetric') {
                          <div class="custom-metric-menu-wrapper position-relative">
                            <button
                              class="btn-menu"
                              epClickOutside
                              [trigger]="['btn-menu-item', 'btn-menu']"
                              (click)="toggleMenu(i, placeholder)"
                              (close)="clickOutside()"
                            ></button>
                            @if (isMenuOpen && customMetricIndex === i) {
                              <div
                                class="menu position-absolute d-flex flex-column"
                                >
                                @for (option of customMetricCopyOptions; track option) {
                                  <button
                                    type="button"
                                    class="btn-menu-item d-flex justify-content-end align-items-center"
                                    [class.active]="customMetricIndex === i"
                                    (click)="copyCustomMetricOption(option)"
                                    >
                                    {{ option }}
                                    <button
                                      type="button"
                                      (click)="copyCustomMetricOption(option)"
                                      class="btn-menu-item fa fa-copy btn-copy fa fa-regular fa-copy border-0"
                                    ></button>
                                  </button>
                                }
                              </div>
                            }
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="insert-btn-block d-flex">
                      <div
                        class="wrap-badge d-flex align-items-center mw-100 justify-content-end"
                        >
                        @if (placeholder.usedInPages?.length) {
                          <span
                            class="badge-content"
                    [ngClass]="{
                      'no-clickable': !placeholder.usedInPages?.length
                    }"
                            (click)="openUsedInSalesConceptsModal(placeholder)"
                            >
                            {{ placeholder.usedInPages?.length }}
                          </span>
                        }
                      </div>
                      <button
                        class="btn edit-placeholder-btn"
                        (click)="addPlaceholder(placeholder)"
                        >
                        Add
                      </button>
                      <button
                        class="btn edit-placeholder-btn"
                        (mouseup)="editPlaceholder(placeholder)"
                        >
                        Edit
                      </button>
                      <button
                        class="btn delete-placeholder-btn"
                        (click)="removePlaceholder(placeholder)"
                        >
                        Delete
                      </button>
                      <div class="order-btn-container">
                        @if (placeholder.order !== placeholders.length - 1) {
                          <button
                            [disabled]="placeholder.order === placeholders.length - 1"
                            class="order-btn-down fa fa-chevron-down h-100 w-100 rounded-circle"
                    (click)="
                      handlePlaceholdersOrder(
                        'down',
                        placeholders,
                        placeholder.id
                      )
                    "
                          ></button>
                        }
                      </div>
                      <div class="order-btn-container">
                        @if (placeholder.order !== 0) {
                          <button
                            [disabled]="placeholder.order === 0"
                            class="order-btn-up fa fa-chevron-up h-100 w-100 rounded-circle"
                    (click)="
                      handlePlaceholdersOrder(
                        'up',
                        placeholders,
                        placeholder.id
                      )
                    "
                          ></button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        }
      </section>
    }
