import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map } from 'rxjs/operators';

import { WINDOW_TOKEN } from '@core/constant';
import { CoverSheetPlaceholderItem, FormField } from '../cover-sheet-modal.interfaces';
import { CoverSheetModalService } from '../cover-sheet-modal.service';

@UntilDestroy()
@Component({
  selector: 'ep-cover-sheet-input-chips',
  templateUrl: './cover-sheet-input-chips.component.html',
  styleUrls: ['./cover-sheet-input-chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverSheetInputChipsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() control: AbstractControl;
  @Input() style: Record<string, string>;
  @Input() field: FormField;

  brake = false;

  @ViewChild('textBox') textBox: ElementRef;

  constructor(
    private renderer: Renderer2,
    private coverSheetModalService: CoverSheetModalService,
    @Inject(WINDOW_TOKEN) private window: Window,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.watchForPlaceholder();
  }

  ngAfterViewInit(): void {
    this.renderer.setProperty(this.textBox.nativeElement, 'innerHTML', this.control.value);
    this.addListener();
  }

  // TODO: need to destroy component correct https://assuranceapp.atlassian.net/browse/DAT-7917
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {}

  onPaste(event: ClipboardEvent): void {
    event.preventDefault();

    const text = (event.clipboardData || this.window.clipboardData).getData('text');
    const selection = this.getSelection();

    if (selection.rangeCount) {
      selection.deleteFromDocument();
      selection.getRangeAt(0).insertNode(this.document.createTextNode(text));
      this.patchValue();
    }
  }

  saveRange(event: MouseEvent | KeyboardEvent): void {
    if (event.target['parentElement'].classList.contains('chips')) {
      return;
    }

    if (this.window.getSelection) {
      const selection = this.getSelection();

      this.coverSheetModalService.setRange = selection.rangeCount === 0 ? null : selection.getRangeAt(0);
      this.coverSheetModalService.setFiled = this.field;
    }

    if (event instanceof KeyboardEvent && event.code === 'Enter') {
      this.brake = !this.brake;
    }
  }

  watchForText(): void {
    this.patchValue();
    this.control.markAsDirty();
  }

  private watchForPlaceholder(): void {
    this.coverSheetModalService
      .watchForPlaceholder()
      .pipe(
        untilDestroyed(this),
        filter(() => this.coverSheetModalService.getFiled === this.field),
        map((placeholder: CoverSheetPlaceholderItem) => this.createInsert(placeholder))
      )
      .subscribe((chips: HTMLElement) => this.insert(chips));
  }

  private createInsert(placeholder: CoverSheetPlaceholderItem): HTMLElement {
    const chips = this.createChips(placeholder);
    const chipsText = this.createChipsText(placeholder);
    const chipsBntRemove = this.createChipsBtnRemove();

    this.renderer.appendChild(chips, chipsText);
    this.renderer.appendChild(chips, chipsBntRemove);

    return chips;
  }

  private insert(chips: HTMLElement): void {
    if (this.window.getSelection) {
      this.restoreRange();
      const selection = this.getSelection();

      if (selection.getRangeAt && selection.rangeCount) {
        const whiteSpace = this.document.createTextNode(' ');
        const range = this.getSelection().getRangeAt(0);

        range.insertNode(whiteSpace);
        range.insertNode(chips);
        range.insertNode(this.document.createTextNode(' '));

        if (this.brake) {
          range.insertNode(this.document.createTextNode('\n'));
          this.brake = !this.brake;
        }

        // selection.empty();
        range.collapse(false);
        range.setStartAfter(whiteSpace);

        this.patchValue();
      }
    }
  }

  private getSelection(): Selection {
    return this.window.getSelection();
  }

  private createChips(placeholder: CoverSheetPlaceholderItem): HTMLElement {
    const chips = this.renderer.createElement('div');

    this.renderer.setAttribute(chips, 'class', 'chips');
    this.renderer.setAttribute(chips, 'contenteditable', 'false');
    this.renderer.setAttribute(chips, 'data-placeholder-data-key', placeholder.value);
    this.renderer.setAttribute(chips, 'data-placeholder-data-from', placeholder.dataFrom);

    if (placeholder.formatted) {
      this.renderer.setAttribute(chips, 'data-placeholder-data-formatted', placeholder.formatted);
    }

    return chips;
  }

  private createChipsText(placeholder: CoverSheetPlaceholderItem): HTMLElement {
    const chipsText = this.renderer.createElement('span');

    this.renderer.setAttribute(chipsText, 'class', 'chips-text');
    this.renderer.setProperty(chipsText, 'innerHTML', placeholder.text);

    return chipsText;
  }

  private createChipsBtnRemove(): HTMLElement {
    const chipsBntRemove = this.renderer.createElement('button');

    this.renderer.setAttribute(chipsBntRemove, 'class', 'chips-btn-remove');
    this.renderer.setAttribute(chipsBntRemove, 'type', 'button');

    this.addListener();

    return chipsBntRemove;
  }

  private removeChips(event: MouseEvent): void {
    event.preventDefault();

    if (event.target['classList'].contains('chips-btn-remove')) {
      const chips = event.target['parentElement'];

      if (chips) {
        chips.remove();
        this.patchValue();
      }
    }
  }

  private restoreRange(): void {
    const selection = this.getSelection();

    selection.removeAllRanges();
    selection.addRange(this.coverSheetModalService.getRange);
  }

  private addListener(): void {
    this.renderer.listen(this.textBox.nativeElement, 'click', (event: MouseEvent) => this.removeChips(event));
  }

  private patchValue(): void {
    this.control.patchValue(this.textBox.nativeElement.innerHTML);
  }
}
