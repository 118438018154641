import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { Store } from '@ngrx/store';
import { finalize, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AlertService } from '@se/common';
import { DOMService, PreviewCompiledPageService, ZenDeskWidgetService } from '@shared/services';
import { AuthService } from '@assurance/um-services';
import { AppState } from '../../reducers';
import { globalStateReset, organizationsSwitch } from '@ngrx-app/global.actions';
import { LocalStorageService } from '@core/service';
import { ensightTitle, userPilotStorageKey } from '@shared/constants';

@UntilDestroy()
@Component({
  selector: 'ensight-login',
  styleUrls: ['login.component.scss'],
  templateUrl: 'login.component.html',
})
export class LoginComponent implements OnInit {
  managePasswordNavigateTo: any = ['/manage-password', { forced: true }];
  payloadToken = '' as any;
  orgUuid: string;

  constructor(
    private router: Router,
    private domService: DOMService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private zenDeskWidgetService: ZenDeskWidgetService,
    private alertService: AlertService,
    private store: Store<AppState>,
    private previewCompiledPageService: PreviewCompiledPageService,
    private localStorage: LocalStorageService,
    private titleService: Title
  ) {}

  public ngOnInit(): void {
    this.checkLogin();
    this.domService.toggleHeader(true);
    this.prefillZenDeskWidget();
    this.handleExternalAuth();
    this.titleService.setTitle(ensightTitle);
    this.localStorage.setData(userPilotStorageKey, false);
    this.orgUuid = this.route.snapshot.queryParams.orgUuid;
  }

  // public onLogin(response: {
  //   success: boolean;
  //   isPasswordExpired?: boolean;
  //   isCaptchaFailed?: boolean;
  //   data: {
  //     bearer: string;
  //     success: boolean;
  //     resetUsername: string;
  //     error: { message: string[] };
  //   };
  // }):
  // TODO: need to change interface
  public onLogin(response: any): void {
    if (response.success) {
      const transactionUUID = this.route.snapshot.queryParams.transactionUUID;

      if (response?.data?.success) {
        if (response?.data?.resetUsername) {
          this.router.navigate(['/manage-password', { first: true, forced: true }], {
            queryParams: { transactionUUID },
          });

          return;
        }

        if (!response.isPasswordExpired) {
          if (transactionUUID) {
            this.store.dispatch(
              organizationsSwitch({
                queryParams: { transactionUUID },
              })
            );
          } else {
            this.store.dispatch(organizationsSwitch({}));
          }
        } else {
          this.alertService.openAlert({
            type: 'neutral',
            body: 'Your password has been expired. Please update your password.',
            autoClose: 5000,
          });
        }
      } else {
        this.alertService.openAlert({
          type: 'error',
          body: 'Error logging in. Please contact the support',
          autoClose: 5000,
        });
      }
    } else if (
      response.data.error &&
      response.data.error.message &&
      response.data.error.message instanceof Array &&
      response.data.error.message[0]
    ) {
      this.alertService.openAlert({
        type: 'error',
        body: response.data.error.message[0],
        autoClose: 5000,
      });
    } else if (response.isCaptchaFailed) {
      this.alertService.openAlert({
        type: 'error',
        body: 'Invalid Captcha. Please Try Again.',
        autoClose: 5000,
      });
    } else {
      this.alertService.openAlert({
        type: 'error',
        body: 'Username or password is incorrect.',
        autoClose: 5000,
      });
    }
  }

  private checkLogin() {
    if (this.authService.isLogged) {
      this.authService
        .logout()
        .pipe(
          tap(() => {
            this.previewCompiledPageService.clearPresentationAndConfig();
            this.localStorage.setData(userPilotStorageKey, false);
          }),
          untilDestroyed(this),
          finalize(() => this.store.dispatch(globalStateReset()))
        )
        .subscribe();
    }
  }

  private prefillZenDeskWidget(): void {
    this.zenDeskWidgetService.action('logout');
  }

  private handleExternalAuth() {
    if (this.route.snapshot.queryParams.orgUuid) {
      this.alertService.openAlert({
        type: 'neutral',
        body: `You log in for the first time from this organization.
        To make sure it's you, please log in using your username and password.`,
        autoClose: 6000,
      });
    }
  }
}
