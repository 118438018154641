import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ModalModule } from 'ngx-bootstrap/modal';
import { UmMiscModule } from '@assurance/um-misc';

import { RemoveModalComponent } from './remove-modal/remove-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { ConfirmAgreementModalComponent } from './confirm-agreement/confirm-agreement.component';
import { InfoModalComponent } from './info-modal/info-modal.component';

// import { StyleEditorModule } from '../../style-editor/style.editor.module';
const components = [RemoveModalComponent, ConfirmModalComponent, ConfirmAgreementModalComponent, InfoModalComponent];

@NgModule({
  declarations: components,
  exports: components,
  imports: [ModalModule.forRoot(), FormsModule, CommonModule, UmMiscModule],
})
export class SharedModalsModule {}
