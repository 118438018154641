import { Component, OnInit, ViewChild } from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { PresentationApiService } from '@shared/services';

@Component({
  selector: 'ensight-remove-modal',
  templateUrl: 'remove-modal.component.html',
  styleUrls: ['./remove-modal.component.scss'],
})
export class RemoveModalComponent implements OnInit {
  @ViewChild('removeChildModal', { static: false })
  private removeChildModal: ModalDirective;

  resolve: any;
  public windowClass: string;
  public modalInitComplete = false;
  private modalState: Subject<any>;

  constructor(private presentationApiService: PresentationApiService) {}

  public openModal({ resolve, windowClass }: any) {
    this.resolve = resolve;
    this.windowClass = windowClass;

    this.modalInitComplete = true;
    setTimeout(() => this.removeChildModal.show(), 100);

    return new Promise(res => {
      this.modalState.subscribe(data => res(data));
    });
  }

  public ok() {
    const id = this.resolve.data.id;
    this.presentationApiService.removePresentation(id).subscribe(() => {
      this.modalState.next(id);
      this.modalInitComplete = false;
      this.modalState.observers.pop();
    });
  }

  public cancel() {
    this.modalInitComplete = false;
    this.modalState.observers.pop();
  }

  public ngOnInit() {
    this.modalState = new Subject();
  }
}
