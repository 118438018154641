<div class="dropdown-container h-100">
  <button
    #trigger
    type="button"
    class="dropdown-trigger w-100 h-100 bg-white d-flex justify-content-between align-items-center text-start"
    [attr.aria-expanded]="isOpen"
    (click)="toggleDropdown()"
  >
    <span>{{ selectedValue }}</span>
    <span
      class="fa aapp-sort-icon"
      [ngClass]="{
        'fa-chevron-up': isOpen,
        'fa-chevron-down': !isOpen
      }"
    ></span>
  </button>

  @if (isOpen) {
    <ul
      class="dropdown-options bg-white p-0 m-0 list-unstyled"
      role="listbox"
      [style.top.px]="listPositionTop + 2"
      [style.left.px]="listPositionLeft"
      [style.width.px]="listWidth"
    >
      @for (option of options; track option.value) {
        @if (option.value?.trim()) {
          <li
            role="option"
            class="dropdown-option d-flex align-items-center"
            [attr.aria-selected]="option.value === selectedValue"
            (click)="selectOption(option.value)"
          >
            {{ option.value }}
          </li>
        }
      }
    </ul>
  }
</div>
