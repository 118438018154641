import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { map, mergeMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { TimeService } from '@shared/services';
import { CustomPageVersion } from '@shared/models';
import { DatePickerConfig } from '@shared/constants/date-picker.constant';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { ModalConfig, ModalRef } from '@assurance/bootstrap';
import { CustomPageApiService } from 'src/app/modules/custom-pages/services';

@Component({
  selector: 'ep-select-version-modal',
  templateUrl: './select-version-modal.component.html',
  styleUrls: ['./select-version-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectVersionModalComponent implements OnInit {
  @ViewChild('datePicker') datePicker: BsDatepickerDirective;

  currentVersionAddedDate: Date;
  currentVersionName: string;
  versionsLoading$: Observable<boolean>;
  listOfVersions: CustomPageVersion[] = [];
  datePickerConfig = DatePickerConfig;
  minDateInCalendar: Date;
  visibleVersionLimit = 7;
  private currentUiId: string;
  private parentUiId: string;

  constructor(
    public modal: ModalRef,
    public config: ModalConfig,
    private timeService: TimeService,
    private customPageApiService: CustomPageApiService
  ) {}

  ngOnInit() {
    this.currentVersionAddedDate = new Date(this.config.data.versionAddedDate);
    this.currentVersionName = this.config.data.versionName;
    this.currentUiId = this.config.data.uiId;
    this.parentUiId = this.config.data.parentUiId;

    this.versionsLoading$ = this.getListOfVersions();
  }

  closeModal(): void {
    this.modal.close({ submitted: false });
  }

  saveAndClose(): void {
    this.modal.close({ submitted: true, versionAddedDate: this.currentVersionAddedDate });
  }

  changedVersionDate(event: Date): void {
    this.currentVersionAddedDate = event;
    this.findVersionNameForSelectedDate(event);
  }

  private getListOfVersions(): Observable<boolean> {
    return this.customPageApiService.getCustomPageVersions(this.currentUiId).pipe(
      map(data => ({
        ...data,
        versions: data.versions.map(version => this.convertVersionDatesToEDT(version)),
      })),
      tap(data => {
        this.listOfVersions = data.versions;
        this.minDateInCalendar = new Date(this.listOfVersions[0]?.startDate);
      }),
      mergeMap(() => {
        if (this.parentUiId !== this.currentUiId) {
          return this.customPageApiService.getCustomPageVersions(this.parentUiId).pipe(
            tap(parentData => {
              const parentStartDate = new Date(parentData.versions[0]?.startDate);

              if (parentStartDate > this.minDateInCalendar) {
                this.minDateInCalendar = parentStartDate;
              }
            })
          );
        }

        return of(true);
      }),
      map(() => true)
    );
  }

  private convertVersionDatesToEDT(version: CustomPageVersion): CustomPageVersion {
    return {
      ...version,
      startDate: version.startDate ? this.timeService.convertToEDT(version.startDate).toISOString() : undefined,
      endDate: version.endDate ? this.timeService.convertToEDT(version.endDate).toISOString() : undefined,
    };
  }

  private findVersionNameForSelectedDate(selectedDate: Date): void {
    const foundVersion = this.listOfVersions.find(version => {
      const startDate = this.timeService.normalizeDate(version.startDate);
      const endDate = version.endDate ? this.timeService.normalizeDate(version.endDate) : null;

      if (!endDate) {
        return selectedDate >= startDate;
      }

      return selectedDate >= startDate && selectedDate < endDate;
    });

    this.currentVersionName = foundVersion ? foundVersion.versionName : this.currentVersionName;
  }
}
