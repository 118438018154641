import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import * as apiConstant from '@core/constant';
@Injectable()
export class ThemeApiService {
  constructor(private http: HttpClient) {}

  public getCurrentTheme() {
    return this.http.get(apiConstant.sharedCurrentTheme).pipe(map((res: any) => res.data));
  }
}
