import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { IAMService, UmNodeAPIService, UserService } from '@assurance/um-services';
import { AlertService } from '@se/common';
import { first, switchMap } from 'rxjs/operators';

import { AppState } from '../../../reducers';
import { SetupService } from '../../../components/presentation/setup/setup.service';
import { PDFGenerationService } from '../../../components/presentation/pdf-generation/pdf-generation.service';
import {
  getPermittedConfigs,
  getPresentationConfigs,
  getPresentationInitialLoadingConfigs,
} from '../../../components/presentation/redux/configs/selectors';
import { SocketService, Global, TimeService, DownloadApiService, EndPagesAdapterService } from '@shared/services';
import { combineLatest } from 'rxjs';
import { getDiff, rdiffResult } from 'recursive-diff';
import { GA_NOT_SET } from '@shared/constants';
import { LocalStorageService } from '@core/service';
import { PermissionKeyEnum } from '@core/enums';
import { IFrameCommunicationService } from './iframe-communication/iframe-communication.service';
import { readCookie } from '@shared/utils';

@Injectable()
export class SharedSocketService extends SocketService {
  constructor(
    public userService: UserService,
    public iamService: IAMService,
    public umNodeAPIService: UmNodeAPIService,
    public setupService: SetupService,
    public global: Global,
    public pdfGenerationService: PDFGenerationService,
    public downloadApiService: DownloadApiService,
    public alertService: AlertService,
    public store: Store<AppState>,
    public localStorage: LocalStorageService,
    public iframeService: IFrameCommunicationService,
    public endPagesAdapterService: EndPagesAdapterService,
    private timeService: TimeService
  ) {
    super(
      global,
      iamService,
      umNodeAPIService,
      downloadApiService,
      pdfGenerationService,
      alertService,
      store,
      localStorage,
      iframeService,
      endPagesAdapterService
    );
  }

  public sendPDFExport(
    presentationId: number,
    target = 'setup',
    shareableLink: string,
    creationDate: string,
    pages: string[]
  ): void {
    // Currently only one scenario when we need to set pages array it's target premium ['life_products', 'target_premium']
    const hasPages = pages && pages.length;
    let url = `presentation/${presentationId}/export/pdf`;

    if (hasPages) {
      url = `${url}?${this.generatePagesQueryParams(pages, true)}`;
    }

    this.store
      .pipe(
        select(getPermittedConfigs),
        first(),

        switchMap(configs =>
          this.generatePDFRequest(url, !!hasPages, configs, true, null, creationDate, undefined, shareableLink)
        )
      )
      .subscribe(options => this.sendMessage(options));

    gtag('event', `PDF Download - ${target == 'view' ? 'From Presentation View' : 'From Presentation Setup'}`, {
      EventCategory: 'PDF Download',
      EventAction: target == 'view' ? 'From Presentation View' : 'From Presentation Setup',
      EventLabel: GA_NOT_SET,
      PresentationID: this.global.getActivePresentationId,
      PresentationName: this.global.getPresentation.name,
      TemplateID: GA_NOT_SET,
      TemplateName: GA_NOT_SET,
      UserID: this.userService.user.id,
      AgencyId: this.userService.organization.id,
      AgencyName: this.userService.organization.name,
      PresentationPage: GA_NOT_SET,
      ElementName: GA_NOT_SET,
      EventTimestamp: this.timeService.getPstTimeForGa4(),
    });
  }

  public sendPDFExportShared(sharedToken: string, creationDate: string): void {
    const url = `shared-presentation/${sharedToken}/export/pdf`;
    combineLatest([this.store.select(getPresentationConfigs), this.store.select(getPresentationInitialLoadingConfigs)])
      .pipe(
        first(),
        switchMap(([configs, initialConfigs]) => {
          const diff: rdiffResult[] = getDiff(initialConfigs, configs);
          const shareableLink = this.getShareableLink();

          return this.generatePDFRequest(url, false, configs, true, sharedToken, creationDate, diff, shareableLink);
        })
      )
      .subscribe(options => this.sendMessage(options));
  }

  public sendPDFExportCustomPage(
    pageId: string,
    landscape: boolean,
    creationDate: string,
    presentationId?: number
  ): void {
    const url = presentationId
      ? `custom-pages/${pageId}/export/pdf?presentationId=${presentationId}`
      : `custom-pages/${pageId}/export/pdf`;
    this.store
      .pipe(
        select(getPermittedConfigs),
        first(),
        switchMap(configs => this.generatePDFRequest(url, true, configs, landscape, null, creationDate))
      )
      .subscribe(options => this.sendMessage(options));
  }

  private getShareableLink(): string | null {
    if (
      readCookie('loggedIn') &&
      this.iamService.hasGroupAccess(PermissionKeyEnum.add_shareable_link_in_the_pdf_file_footer)
    ) {
      return `${location.origin}/shared-presentation/${this.global.getPresentation.shareableToken}?linkSource=fromPDF`;
    }

    return null;
  }
}
