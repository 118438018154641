import { Injectable } from '@angular/core';

import { ModalRef, ModalService } from '@assurance/bootstrap';

import { ManageVersionComponent, PresentationListModalComponent } from '../../components';
import {
  CompareFilesModalComponent,
  CopyModalComponent,
  LinkedPagesModalComponent,
  PlaceholdersWizardComponent,
  UsedInModalComponent,
} from '../../modals';
import { CustomPageVersions, CopyCustomPage } from '@shared/models';
import { ChangelogSelectionMode, ChangelogTableData, LinkedPagesModal, UsedInData } from '../../models';
import { SelectVersionModalComponent } from 'src/app/components/presentation/modals/select-version-modal/select-version-modal.component';
import { SelectVersionModalData } from 'src/app/components/presentation/modals/select-version-modal/select-version-modal.model';
import { CommonPlaceholderFields } from '@core/model';
import { ChooseCustomPageChangelogComponent } from '../../modals/choose-custom-page-changelog/choose-custom-page-changelog.component';

@Injectable()
export class ModalProviderService {
  constructor(private modal: ModalService) {}

  openManageVersionModal(data: CustomPageVersions): ModalRef {
    return this.modal.open(ManageVersionComponent, {
      data,
      width: '1110px',
      height: '592px',
      disableEscapeEvent: true,
    });
  }

  openChoosePresentationModal(data: number | string): ModalRef {
    return this.modal.open(PresentationListModalComponent, {
      data,
      width: '1000px',
    });
  }

  openLinkedPagesModal(data: LinkedPagesModal): ModalRef {
    return this.modal.open(LinkedPagesModalComponent, { data, width: '60%' });
  }

  openSelectedVersionModal(data: SelectVersionModalData): ModalRef {
    return this.modal.open(SelectVersionModalComponent, { data, width: '436px', maxHeight: 'min-content' });
  }

  openCopyModal(data: CopyCustomPage): ModalRef {
    return this.modal.open(CopyModalComponent, { data, width: '560px' });
  }

  openUsedInModal(data: UsedInData): ModalRef {
    return this.modal.open(UsedInModalComponent, { data, width: '759px' });
  }

  openPlaceholdersWizardModal(data?: CommonPlaceholderFields): ModalRef {
    return this.modal.open(PlaceholdersWizardComponent, {
      data,
      minWidth: '790px',
      maxWidth: '1050px',
      maxHeight: '100%',
    });
  }

  openChooseCustomPageChangelog(data: ChangelogSelectionMode): ModalRef {
    return this.modal.open(ChooseCustomPageChangelogComponent, {
      data,
      maxWidth: '90vw',
      minWidth: '790px',
      maxHeight: '100%',
    });
  }

  openDataComparisonModal(data: ChangelogTableData): ModalRef {
    return this.modal.open(CompareFilesModalComponent, {
      data,
      width: '70%',
      maxWidth: '90vw',
      maxHeight: '100%',
    });
  }
}
