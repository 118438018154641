import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { first, map, switchMap } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { combineLatest, Observable, of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { getDependentPagesConfig } from '../../setup/setup.selectors';
import {
  salesConceptModalRequestsPending,
  salesConceptModalRequestsSuccess,
  salesConceptsModalAvailable,
} from '../../setup/setup.actions';
import { AppState } from '../../../../reducers';
import { CustomPageService } from '../../setup/custom-page/custom-page.service';
import { SetupService } from '../../setup/setup.service';
import { GAService, Global, NavbarPagesService } from '@shared/services';
import {
  CustomPageValues,
  DataSourcesConfig,
  NavbarCustomPagesData,
  PageConfig,
  PresentationModalType,
} from '@shared/models';
import { ModalProviderService } from '../../shared/services';
import { getDependentPermissions } from '@ngrx-app/global.selectors';
import { getPresentationConfigs } from '../../redux/configs/selectors';

//TODO: refact this service
@UntilDestroy()
@Injectable()
export class OpenSalesConceptModalService {
  constructor(
    public store: Store<AppState>,
    public router: Router,
    public customPageService: CustomPageService,
    public setupService: SetupService,
    public global: Global,
    public gaService: GAService,
    private modalProvider: ModalProviderService,
    private navbarPagesService: NavbarPagesService
  ) {}

  editPage(page: CustomPageValues, configs: PageConfig[], dataSourcesConfig: Observable<DataSourcesConfig[]>): void {
    if (!page || !page.config.uiId) {
      return;
    }

    this.openSalesConceptModal(page, configs, dataSourcesConfig);
    this.gaService.sendOpenPageSettingsEvent();
  }

  closeEditPage(): void {
    this.modalProvider.closeModal(PresentationModalType.SALES_CONCEPT_SETTINGS);
  }

  clodeAllModals(): void {
    this.modalProvider.closeAllModals();
  }

  private openSalesConceptModal(
    selectedCustomPage: CustomPageValues,
    configs: PageConfig[],
    dataSourcesConfig: Observable<DataSourcesConfig[]>
  ): void {
    this.store
      .pipe(
        select(getDependentPagesConfig),
        map(conf => conf.configs),
        first()
      )
      .subscribe(customPagesConfig => {
        const pageConfig = this.setupService.getPageConfigById(configs, selectedCustomPage?.config?.uiId);
        const config = cloneDeep(pageConfig);
        const data = {
          salesConcepts: of(cloneDeep([selectedCustomPage])),
          plans: this.setupService.buildPlansNav(this.global.getCurrentCarrierPlans),
          pageConfig: config,
          activePlans: config.config.activePlans,
          customPagesConfigs: customPagesConfig,
          dataSources: dataSourcesConfig,
          editMode: true,
          shared: this.router.url.indexOf('shared-presentation') > -1,
        };

        const modal = this.modalProvider.openSalesConceptSettingsModal(data);

        modal.afterClosed.pipe(first()).subscribe(res => {
          if (!res?.submitted) {
            return;
          }

          this.customPageService.setCompilePage(true);
          this.store.dispatch(salesConceptModalRequestsPending());
          (res.insertsToUpdate
            ? this.customPageService.updateInsertsConfigsDirectPage(
                res.insertsToUpdate,
                customPagesConfig,
                selectedCustomPage.config.uiId
              )
            : of(null)
          )
            .pipe(
              switchMap(() => this.getNavbarCustomPages().pipe(first())),
              switchMap((data: NavbarCustomPagesData) => {
                const uiId = config.config.uiId;
                const found = data.salesConcepts.find(item => item.config.uiId === uiId);

                const params = found ? { uiId, _id: found?._id, tookByFormula: found?.tookByFormula } : { uiId };

                return this.customPageService.getSalesConcepts([params]);
              }),
              untilDestroyed(this)
            )
            .subscribe(() => {
              this.store.dispatch(salesConceptModalRequestsSuccess());
              this.store.dispatch(salesConceptsModalAvailable({ payload: true }));
              this.customPageService.setCompilePage(false);
            });
        });
      });
  }

  private getNavbarCustomPages(): Observable<NavbarCustomPagesData> {
    return combineLatest([
      this.store.select(getDependentPermissions),
      this.store.select(getPresentationConfigs),
      this.store.select(getDependentPagesConfig),
    ]).pipe(
      first(),
      switchMap(configs => {
        const [permissions, presentationConfigs, dependentPagesConfig] = configs;
        const salesConceptsUiIds = { uiId: permissions?.salesConcepts || [] };
        const uiIds = this.customPageService.getCommonUiIds(salesConceptsUiIds, presentationConfigs);

        return this.navbarPagesService.getNavbarConceptsAndDependentPages(
          presentationConfigs,
          dependentPagesConfig.configs,
          uiIds
        );
      })
    );
  }
}
