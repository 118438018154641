import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, skip, switchMap, takeUntil, tap } from 'rxjs/operators';

import { SummaryTable } from '@core/class';
import { TABLE_LABELS } from '@shared/constants';
import { CaseApiService } from '@shared/services';
import { HeaderItem } from '@shared/models';
import { COLUMNS_HEADERS_CONFIGS } from './case-selection.constants';

@Component({
  selector: 'ep-case-selection',
  templateUrl: './case-selection.component.html',
  styleUrls: ['./case-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseSelectionComponent extends SummaryTable implements OnInit, OnDestroy {
  @Output() output = new EventEmitter<unknown>();
  dataLoading = true;
  tableLabels = TABLE_LABELS;
  searchControl: UntypedFormControl = new UntypedFormControl('');

  protected sortField = 'updatedDate';
  private unsubscribe$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef, private caseApiService: CaseApiService) {
    super();
  }

  ngOnInit(): void {
    this.setTableHeadersData(COLUMNS_HEADERS_CONFIGS);
    this.getCases().subscribe();

    this.cdr.markForCheck();
    this.setSubscriptions();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onHeaderClick(headerItem: HeaderItem): void {
    this.setTableHeadersData(this.tableHeadersData, headerItem);
    this.getCases().subscribe();
  }

  onPageChange(e): void {
    this.pagination = {
      ...this.pagination,
      currentPage: e.currentPage,
    };
    this.getCases().subscribe();
  }

  onRowClick(caseInfo: unknown): void {
    this.output.emit({ caseInfo });
  }

  protected setTableData(cases): void {
    this.tableData = [
      {
        data: cases.map(caseData => ({
          data: {
            ...caseData,
            createdBy: caseData.info.createdBy,
            updatedDate: caseData.info.updatedDate,
          },
        })),
      },
    ];
  }

  private getCases(): any {
    this.dataLoading = true;
    this.cdr.markForCheck();

    return this.caseApiService.getCases(this.buildPayload()).pipe(
      map(res => this.setCasesData(res)),
      tap(() => {
        this.dataLoading = false;
        this.cdr.markForCheck();
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  private buildPayload(): { [key: string]: string | number } {
    return {
      page: this.pagination.currentPage - 1,
      search: this.searchControl.value,
      sort: `${this.sortField},${this.direction}`,
    };
  }

  private setCasesData({ data }): void {
    this.pagination = {
      ...data.pagination,
      currentPage: data.pagination.currentPage + 1,
    };
    this.setTableData(data.data);
  }

  private setSubscriptions(): void {
    this.searchControl.valueChanges
      .pipe(
        skip(1),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(() => {
          return this.getCases();
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }
}
