import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  CustomPageDetailsComponent,
  CustomPagePdfGenerationComponent,
  CustomPageSettingsComponent,
  CustomPageEditTextComponent,
  CustomPageHistoryComponent,
  CustomPageListComponent,
  CustomPageHistoryDetailsComponent,
} from './components';
import { CustomPagePermissionGuard } from '@core/guards';
import { PermissionKeyEnum } from '@core/enums';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: CustomPageListComponent,
    canActivate: [CustomPagePermissionGuard],
    data: { requiredPermission: PermissionKeyEnum.view_custom_pages },
  },
  {
    path: 'create',
    component: CustomPageSettingsComponent,
    canActivate: [CustomPagePermissionGuard],
    data: { requiredPermission: PermissionKeyEnum.create_custom_page },
  },
  {
    path: ':id',
    canActivateChild: [CustomPagePermissionGuard],
    data: { requiredPermission: PermissionKeyEnum.view_custom_pages },
    children: [
      {
        path: '',
        component: CustomPageDetailsComponent,
        canActivate: [CustomPagePermissionGuard],
        data: { requiredPermission: PermissionKeyEnum.view_custom_pages },
      },
      {
        path: 'edit',
        component: CustomPageSettingsComponent,
        canActivate: [CustomPagePermissionGuard],
        data: { requiredPermission: PermissionKeyEnum.edit_custom_page, editMode: true },
      },
      {
        path: 'export/pdf',
        component: CustomPagePdfGenerationComponent,
        canActivate: [CustomPagePermissionGuard],
        data: { requiredPermission: PermissionKeyEnum.view_custom_pages },
      },
      {
        path: 'edit-text',
        component: CustomPageEditTextComponent,
        canActivate: [CustomPagePermissionGuard],
        data: { requiredPermission: PermissionKeyEnum.edit_text_on_custom_page },
      },
      {
        path: 'history',
        component: CustomPageHistoryComponent,
        canActivate: [CustomPagePermissionGuard],
        data: { requiredPermission: PermissionKeyEnum.view_custom_page_change_log },
      },
      {
        path: 'history/details',
        component: CustomPageHistoryDetailsComponent,
        canActivate: [CustomPagePermissionGuard],
        data: { requiredPermission: PermissionKeyEnum.view_custom_page_change_log },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomPagesRoutingModule {}
