import { SortDirections, TableBodyColumn, TableHeaderColumn } from '@shared/models';
import { TimeFormat } from '@core/constant';

export const HistoryTableHeaderColumns: TableHeaderColumn[] = [
  {
    sortable: false,
    key: 'title',
    title: 'Title',
  },
  {
    sortable: false,
    key: 'page',
    title: 'Page Name',
  },
  {
    sortable: false,
    key: 'numberOfChanges',
    title: 'Number Of Changes',
  },
  {
    sortable: false,
    key: 'pageVersion',
    title: 'Version',
  },
  {
    sortable: true,
    key: 'updatedBy',
    title: 'Author',
  },
  {
    sortable: false,
    key: 'note',
    title: 'Note',
  },
  {
    sortable: true,
    key: 'updated',
    title: 'Updated',
  },
  {
    sortable: false,
    key: 'action',
    title: 'Action',
  },
  {
    sortable: false,
    key: 'menu',
    title: '',
  },
];

export const HistoryTableBodyColumns: TableBodyColumn[] = [
  {
    key: 'title',
    isTruncateText: true,
    defaultValue: '-',
  },
  {
    key: 'pageName',
    isTruncateText: true,
    defaultValue: '',
  },
  {
    key: 'numberOfChanges',
    isTruncateText: true,
    defaultValue: '',
  },
  {
    key: 'pageVersion',
    isTruncateText: true,
    defaultValue: '',
  },
  {
    key: 'updatedBy',
    isTruncateText: true,
    defaultValue: '',
  },
  {
    key: 'comment',
    isTruncateText: true,
    defaultValue: '',
  },
  {
    key: 'updated',
    isTruncateText: false,
    formatDate: TimeFormat.Mdyyyyhmma,
  },
  {
    key: 'action',
    isTruncateText: false,
  },
  {
    key: 'menu',
    isTruncateText: false,
  },
];

export const HistoryTableColumnStyle: Record<string, Record<string, string>> = {
  title: { minWidth: '126px', maxWidth: '220px' },
  pageName: { minWidth: '126px', maxWidth: '220px' },
  numberOfChanges: { minWidth: '126px', maxWidth: '220px', textTransform: 'capitalize' },
  pageVersion: { minWidth: '126px', maxWidth: '220px' },
  updatedBy: {},
  comment: { minWidth: '126px', maxWidth: '220px' },
  updated: { width: '180px', textAlign: 'right', paddingRight: '20px' },
  action: { width: '102px' },
  menu: { width: '50px', textAlign: 'center' },
};

export const getHeaderColumns = () => {
  return HistoryTableHeaderColumns.map((column: TableHeaderColumn) => {
    return {
      ...column,
      style: { ...HistoryTableColumnStyle[column.key] },
    };
  });
};

export const getBodyColumns = () => {
  return HistoryTableBodyColumns.map((column: TableBodyColumn) => {
    return {
      ...column,
      style: { ...HistoryTableColumnStyle[column.key] },
    };
  });
};

export const DefaultHistoryOrderByRequestParam = {
  ORDER_BY: 'updated',
  DIRECTION: SortDirections.DESC,
};

export const DefaultHistoryPaginationRequestParams = {
  PAGE: 1,
};

export const REVERT_CUSTOM_PAGE_SUCCESS_MESSAGE = 'The custom page was successfully reverted';
// TODO: Unify these types with backend
export const entityTypeMap: Record<string, string> = {
  versions: 'Versions',
  _id: 'ID',
  'custom-page': 'Custom Page',
  customPageUUIDsToDelete: 'Removed Custom Pages',
  inserts: 'Inserts',
};
