import { ChangeDetectionStrategy, Component, DestroyRef, Inject, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter, switchMap } from 'rxjs/operators';

import { ModalService } from '@assurance/bootstrap';
import { AlertService } from '@se/common';
import { AuthService, IAMService } from '@assurance/um-services';

import { readCookie } from '@shared/utils';
import { LoginModalComponent } from '@shared/components/assurance-navbar/login-modal/login-modal.component';
import { CareerPlan } from '@core/model';
import { WINDOW_TOKEN } from '@core/constant';
import { LocalStorageService } from '@core/service';
import { Global } from '@shared/services';
import { PermissionKeyEnum } from '@core/enums';
import { AUTH_KEYS } from '@shared/constants';

@Component({
  selector: 'ep-quote-btn',
  templateUrl: './quote-btn.component.html',
  styleUrl: './quote-btn.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteBtnComponent {
  private modalService = inject(ModalService);
  private localStorage = inject(LocalStorageService);
  private globalService = inject(Global);
  private destroyRef = inject(DestroyRef);
  private alertService = inject(AlertService);
  private iamService = inject(IAMService);
  private authService = inject(AuthService);

  constructor(@Inject(WINDOW_TOKEN) private window: Window) {}

  openModal(): void {
    if (!readCookie('loggedIn')) {
      this.openLoginModal();
    } else {
      this.navigateToCase();
    }
  }

  private openLoginModal(): void {
    const modal = this.modalService.open(LoginModalComponent, { width: '400px', panelClass: 'login-modal' });

    modal.afterClosed
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((res: Record<string, boolean>) => res.submitted),
        switchMap(() => this.authService.auth(AUTH_KEYS)),
        filter((isAuthenticated: boolean) => isAuthenticated)
      )
      .subscribe(() => {
        if (!this.iamService.hasUserAccess(PermissionKeyEnum.sales_story_quoting)) {
          this.handleMessage('User does not have permission');
        } else {
          this.navigateToCase();
        }
      });
  }

  private navigateToCase(): void {
    const eiqUrl = this.localStorage.getNotJSONData('eiqUrlHost');

    const sortedPlans = this.globalService.getCurrentCarrierPlans.sort((a: CareerPlan, b: CareerPlan) => {
      return new Date(b.planAddedDate).getTime() - new Date(a.planAddedDate).getTime();
    });

    const caseId = sortedPlans[0].configjson.metadata?.case_id;

    if (eiqUrl && caseId) {
      this.window.open(`${eiqUrl}/proposal/${caseId}/LAST/LAST`, '_blank');
    } else {
      this.handleMessage('Case ID does not exist. Please try again');
    }
  }

  private handleMessage(message: string): void {
    this.alertService.openAlert({ type: 'error', body: message, autoClose: 5000 });
  }
}
