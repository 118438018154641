import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getSearchQuery } from '@core/utils';
import { Observable } from 'rxjs';
import * as apiConstant from '@core/constant';
import { CareerPlan, ResponseType } from '@core/model';

@Injectable()
export class CarrierPlansApiService {
  constructor(private http: HttpClient) {}

  public getCarrierPlansConfig(organization?: string) {
    const query = {
      organization: organization,
    };

    return this.http.get<ResponseType<any>>(apiConstant.carrierPlansConfig, {
      params: getSearchQuery(query),
    });
  }

  public getSharedCarrierPlansConfig(organization?: string) {
    const query = {
      organization: organization,
    };

    return this.http.get<ResponseType<any>>(apiConstant.sharedCarrierPlansConfig, {
      params: getSearchQuery(query),
    });
  }

  public getSharedCarrierPlans(): Observable<ResponseType<any>> {
    return this.http.get<ResponseType<any>>(apiConstant.sharedCarrierPlanData);
  }

  public getCareerPlans(id: number): Observable<ResponseType<CareerPlan[]>> {
    return this.http.get<ResponseType<CareerPlan[]>>(`${apiConstant.presentation}/${id}/${apiConstant.carrierPlans}`);
  }

  public saveCarrierPlan(params: any) {
    const restUrl = `${apiConstant.presentation}/${params.presentationId}/${apiConstant.carrierPlans}`;

    return this.http.post<ResponseType<any>>(restUrl, params.jsonCarrierPlanData);
  }

  public removePlan(id: number) {
    return this.http.delete<ResponseType<any>>(`${apiConstant.carrierPlanData}/${id}`);
  }

  public updateCarrierPlan(params: any) {
    const restUrl = `${apiConstant.carrierPlanData}/${params.planId}`;

    return this.http.put<ResponseType<any>>(restUrl, params.jsonCarrierPlanData);
  }

  public downloadCSV(planId: any): Observable<any> {
    const restUrl = `${apiConstant.carrierPlanData}/${planId}/${apiConstant.csv}`;

    const headersList: any = {};
    headersList.Accept = 'application/csv';
    headersList['Content-Type'] = 'application/json';

    const options: any = {
      headers: new HttpHeaders({ ...headersList }),
      responseType: 'blob',
    };

    return this.http.get(restUrl, options);
  }
}
