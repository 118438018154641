import { Inject, Injectable } from '@angular/core';

import { DOCUMENT } from '@angular/common';

@Injectable()
export class DOMService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  setActiveButton(selector: string): void {
    const btns = this.document.querySelectorAll('.new-presentation-btns .btn');
    const currentBtn = this.document.querySelector(selector);

    [].map.call(btns, (el: any) => el.classList.remove('btn-primary'));

    if (currentBtn) {
      currentBtn.classList.add('btn-primary');
    }

    this.toggleHeader(false);
  }

  toggleHeader(show: boolean): void {
    const elem: any = this.document.querySelector('.aapp-header-main');

    if (elem) {
      elem.style.display = show ? 'block' : 'none';
    }
  }

  togglePresentationViewMode(hide: boolean): void {
    const hiddenElem = this.document.querySelectorAll(`
            .new-presentation-info,
            .new-presentation-btns,
            .new-presentation-title
        `);
    [].map.call(hiddenElem, (elem: any) => (elem.style.display = hide ? 'none' : ''));
  }

  /**
   * clearModalInitialization removes an unnecessary modal class `modal-open` from the body when single modal initializes twice.
   *
   * @memberof DOMService
   */
  clearModalInitialization(): void {
    this.document.body.classList.remove('modal-open');
  }
}
