import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { CoreModule, FormControlsModule, LoadersModule, NavigationsModule, TablesModule } from '@se/common';

import { CaseSelectionComponent } from './case-selection.component';
import { CaseApiService } from '@shared/services';

const components = [CaseSelectionComponent];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CoreModule,
    CommonModule,
    ReactiveFormsModule,
    FormControlsModule,
    TablesModule,
    NavigationsModule,
    LoadersModule,
  ],
  providers: [CaseApiService],
})
export class CaseSelectionModule {}
