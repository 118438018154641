import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ResponseType } from '@core/model';
import * as apiConstant from '@core/constant';
import { CustomPageHistory, CustomPageHistoryResponse, S3MetaParams } from '../../models';
import { ExportedCustomPage } from '@shared/models';

@Injectable()
export class CustomPageHistoryApiService {
  constructor(private http: HttpClient) {}

  getCustomPageHistory(customPageUUID: string, params): Observable<CustomPageHistory> {
    return this.http
      .get<ResponseType<CustomPageHistory>>(`${apiConstant.customPageHistory}/${customPageUUID}`, {
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(map((response: ResponseType<CustomPageHistory>) => response.data));
  }

  getCustomPageHistoryRecord(
    customPageUUID: string,
    recordId: string,
    showPrevious = false
  ): Observable<CustomPageHistoryResponse> {
    const url = `${apiConstant.customPageHistory}/${customPageUUID}/${recordId}${
      showPrevious ? '?SHOW_PREV=true' : ''
    }`;

    return this.http
      .get<ResponseType<CustomPageHistoryResponse>>(url)
      .pipe(map((response: ResponseType<CustomPageHistoryResponse>) => response.data));
  }

  getComparedRecords(params: S3MetaParams): Observable<CustomPageHistoryResponse> {
    return this.http
      .post<ResponseType<CustomPageHistoryResponse>>(`${apiConstant.customPageHistory}/compare`, params)
      .pipe(
        map((response: ResponseType<CustomPageHistoryResponse>) => response.data),
        catchError(error => {
          return throwError(() => error);
        })
      );
  }

  revertCustomPageHistoryRecord(recordId: string, pageNameId: string): Observable<void> {
    return this.http.post<void>(`${apiConstant.customPageHistory}/${recordId}/${pageNameId}/revert`, {});
  }

  exportRevisionCustomPage(customPageUUID: string, record: string): Observable<ExportedCustomPage> {
    return this.http
      .get<ResponseType<ExportedCustomPage>>(`${apiConstant.customPageHistory}/${customPageUUID}/${record}/revision`)
      .pipe(map((response: ResponseType<ExportedCustomPage>) => response.data));
  }
}
