import { Injectable } from '@angular/core';

import { Observable, of, throwError } from 'rxjs';
import { saveAs } from 'file-saver';

import {
  DefaultHistoryOrderByRequestParam,
  DefaultHistoryPaginationRequestParams,
  entityTypeMap,
} from '../../constants';
import { TableRequestParamsService, TimeService } from '@shared/services';
import { TimeFormat } from '@core/constant';
import { LocalStorageService } from '@core/service';
import {
  ChangelogTableData,
  ChangeType,
  ChangeTypes,
  CustomPageChanges,
  CustomPageHistoryResponse,
  DetailedModifications,
  S3MetaParams,
} from '../../models';
import { CustomPageHistoryApiService } from '../custom-page-history-api/custom-page-history-api.service';
import { catchError, map } from 'rxjs/operators';
import { ExportedCustomPage, TableRequestParams } from '@shared/models';
import { orderBy } from 'lodash-es';

@Injectable()
export class CustomPageHistoryService extends TableRequestParamsService {
  constructor(
    private localStorageService: LocalStorageService,
    private timeService: TimeService,
    private customPageHistoryApiService: CustomPageHistoryApiService
  ) {
    super(DefaultHistoryPaginationRequestParams, null, DefaultHistoryOrderByRequestParam);
  }

  revertCustomPageHistory(record: CustomPageChanges): Observable<void> {
    return this.customPageHistoryApiService.revertCustomPageHistoryRecord(record.pageUUID, record.pageNameId);
  }

  downloadRecord(record: CustomPageChanges): void {
    const jsonString = JSON.stringify(record, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const fileName = this.constructFileName(record, 'List of changes');

    saveAs(blob, fileName);
  }

  getRevisionCustomPage(pageUUID: string, pageNameId: string): Observable<ExportedCustomPage> {
    return this.customPageHistoryApiService.exportRevisionCustomPage(pageUUID, pageNameId);
  }

  exportRevisionCustomPage(record: CustomPageChanges): Observable<void> {
    return this.customPageHistoryApiService.exportRevisionCustomPage(record.pageUUID, record.pageNameId).pipe(
      map((response: ExportedCustomPage) => {
        const fileName = this.constructFileName(record, 'Revision');
        saveAs(new Blob([JSON.stringify(response)]), fileName);
      })
    );
  }

  getHistoryDetailsChangelog(
    pageUiid: string,
    pageNameId: string,
    showPrevious = false
  ): Observable<CustomPageHistoryResponse> {
    return this.customPageHistoryApiService
      .getCustomPageHistoryRecord(pageUiid, pageNameId, showPrevious)
      .pipe(catchError(() => of(null)));
  }

  getHistoryDetailsCompared(params: S3MetaParams): Observable<CustomPageHistoryResponse> {
    return this.customPageHistoryApiService.getComparedRecords(params).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  formatHistoryTableData(detailedModifications: DetailedModifications[]): ChangelogTableData[] {
    if (!detailedModifications?.length) {
      return [];
    }

    return detailedModifications.map(modif => {
      const { property, entityType, entityName, changeType, changes } = modif;

      const formatValue = (value: any): string => {
        if (value === undefined || value === null) {
          return '-';
        }

        if (typeof value === 'string') {
          return value.trim() || '-';
        }

        if (typeof value === 'object') {
          return JSON.stringify(value);
        }

        return String(value);
      };

      return {
        entityType: entityTypeMap[entityType],
        entityName: entityName,
        property,
        changeType: this.formatChangeType(changeType),
        valueBefore: formatValue(changes.before),
        valueAfter: formatValue(changes.after),
      };
    });
  }

  sortTableData(records: ChangelogTableData[], sortParams: TableRequestParams): ChangelogTableData[] {
    const { ORDER_BY, DIRECTION } = sortParams;

    return orderBy(records, [ORDER_BY], [DIRECTION.toLowerCase() as 'asc' | 'desc']);
  }

  private formatChangeType(changeType: ChangeType): string {
    switch (changeType) {
      case ChangeTypes.E:
        return 'Edit';
      case ChangeTypes.N:
        return 'Create';
      case ChangeTypes.A:
        return 'Modify Array';
      case ChangeTypes.D:
        return 'Delete';
      default:
        return changeType;
    }
  }

  private constructFileName(selectedRecord: CustomPageChanges, title: string): string {
    const env = this.localStorageService.getNotJSONData('appEnv');
    const timestamp = this.timeService.getFormattedDate(new Date(), TimeFormat.YYYYMMDDhhmmss);
    const revisionDatetime = this.timeService.getFormattedDate(selectedRecord.updated, TimeFormat.YYYYMMDDhhmmss);

    return `${env} - ${title} - ${revisionDatetime} - [${selectedRecord.pageName}] - ${timestamp}.json`;
  }
}
