import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as apiConstant from '@core/constant';
import { ResponseType } from '@core/model';
import { Observable } from 'rxjs';

@Injectable()
export class AgencyApiService {
  constructor(private http: HttpClient) {}
  public getAgencyChildren(id: number) {
    return this.http.get<ResponseType<any>>(`${apiConstant.agency}/${id}/${apiConstant.agencyChildren}`);
  }

  public getSharedAgencyRules(): Observable<ResponseType<any>> {
    return this.http.get<ResponseType<any>>(apiConstant.sharedAgencyRules);
  }
}
