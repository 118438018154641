import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, ElementRef, inject, ViewChild } from '@angular/core';
import { ModalConfig, ModalRef } from '@assurance/bootstrap';
import { debounceTime, distinctUntilChanged, fromEvent, merge } from 'rxjs';
import * as CodeMirror from 'codemirror';
import 'codemirror/addon/merge/merge';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/htmlmixed/htmlmixed';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'ep-compare-files-modal',
  templateUrl: './compare-files-modal.component.html',
  styleUrl: './compare-files-modal.component.scss',
})
export class CompareFilesModalComponent implements AfterViewInit {
  @ViewChild('diffEditor') diffEditor: ElementRef;
  collapseIdentical = false;
  private destroyRef = inject(DestroyRef);

  constructor(public config: ModalConfig<any>, private modalRef: ModalRef, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.initDiffEditor();
  }

  toggleCollapseIdentical(): void {
    this.collapseIdentical = !this.collapseIdentical;
    this.initDiffEditor();
  }

  onCancel(): void {
    this.modalRef.close();
  }

  private detectMode(data: any): string {
    if (typeof data === 'object') {
      return data;
    }

    if (data?.trim().startsWith('<')) {
      return 'text/html';
    }

    return 'application/json';
  }

  private extractContent(data: any, mode: string): string {
    if (!data) {
      return '';
    }

    if (typeof data === 'string') {
      return data === '-' ? '' : data;
    }

    if (mode === 'text/html' && data.htmlBody) {
      return data.htmlBody;
    }

    return JSON.stringify(data, null, 2);
  }

  private initDiffEditor(): void {
    const target = this.diffEditor.nativeElement;
    target.innerHTML = '';

    const afterMode = this.detectMode(this.config.data?.valueAfter);
    const beforeMode = this.detectMode(this.config.data?.valueBefore);

    const editorMode = afterMode !== 'application/json' ? afterMode : beforeMode;

    const afterText = this.extractContent(this.config.data?.valueAfter, afterMode);
    const beforeText = this.extractContent(this.config.data?.valueBefore, beforeMode);

    const mergeView = CodeMirror.MergeView(target, {
      value: afterText,
      origLeft: beforeText,
      lineNumbers: true,
      mode: editorMode,
      showDifferences: true,
      revertButtons: false,
      readOnly: true,
      lineWrapping: true,
      collapseIdentical: this.collapseIdentical,
    });

    const rightEditor = mergeView.editor();
    const leftEditor = mergeView.leftOriginal();

    // method to prevent expanding on click on editor
    ['mousedown', 'dblclick', 'touchstart', 'contextmenu'].forEach(eventName => {
      this.preventEventOn(rightEditor, eventName);
      this.preventEventOn(leftEditor, eventName);
    });

    merge(fromEvent(target, 'click'), fromEvent(target, 'mouseup'))
      .pipe(debounceTime(50), distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.updateCollapseState(target));
  }

  private preventEventOn(editor, eventName): void {
    editor.on(eventName, (_, event) => {
      event.preventDefault();
      event.codemirrorIgnore = true;

      return true;
    });
  }

  private updateCollapseState(element: HTMLElement): void {
    const collapsedWidgets = element.querySelectorAll('.CodeMirror-merge-collapsed-widget');
    const expandedWidgets = element.querySelectorAll('.CodeMirror-merge-expanded-widget');

    const isAllCollapsed = collapsedWidgets?.length && !expandedWidgets.length;

    if (this.collapseIdentical !== isAllCollapsed) {
      this.collapseIdentical = isAllCollapsed;
      this.cdr.detectChanges();
    }
  }
}
