import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { IAMService } from '@assurance/um-services';
import { OperatorKey } from '@shared/constants';
import { AccessControlPermissions } from '@shared/models/access-permissions.model';

@Directive({
  selector: '[accessControl]',
})
export class AccessControlDirective {
  private isOperatorLogicalAnd: boolean;

  @Input() set accessControl(permissions: AccessControlPermissions) {
    if (!permissions) {
      this.viewContainer.createEmbeddedView(this.templateRef);

      return;
    }

    this.isOperatorLogicalAnd = (permissions.operator ?? OperatorKey.AND) === OperatorKey.AND;

    const accessForUser = permissions.user ? this.checkAccessForUser(permissions.user) : true;
    const accessForGroup = permissions.group ? this.checkAccessForGroup(permissions.group) : true;

    if (accessForGroup && accessForUser) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private iamService: IAMService
  ) {}

  private checkAccessForUser(userPermissions: string[]): boolean {
    return this.isOperatorLogicalAnd
      ? userPermissions.every(permission => this.iamService.hasUserAccess(permission))
      : userPermissions.some(permission => this.iamService.hasUserAccess(permission));
  }

  private checkAccessForGroup(groupPermissions: string[]): boolean {
    return this.isOperatorLogicalAnd
      ? groupPermissions.every(permission => this.iamService.hasGroupAccess(permission))
      : groupPermissions.some(permission => this.iamService.hasGroupAccess(permission));
  }
}
