import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { get } from 'lodash-es';

import { AppState } from '../../../../reducers';
import { salesConceptsModalAvailable } from '../../setup/setup.actions';
import { CustomPageValues, PageConfig } from '@shared/models';
import { PlansWithCustomFields } from './sales-concept-modal.model';
import * as ConfigsActions from '../../redux/configs/actions';
import { Global, PresentationApiService } from '@shared/services';
import { CustomPageService } from '../../setup/custom-page/custom-page.service';
import { ActivePlan } from '@core/model';

@Injectable()
export class SalesConceptModalService {
  constructor(
    public store: Store<AppState>,
    private customPageService: CustomPageService,
    private global: Global,
    private presentationApiService: PresentationApiService
  ) {}

  getFilteredActivePlans(plans: PlansWithCustomFields[], selectedPlansIds: number[]): ActivePlan[] {
    return this.convertToActivePlans(this.getFilteredPlans(plans, selectedPlansIds));
  }

  addIsShownField(plans: PlansWithCustomFields[], isShown = false): PlansWithCustomFields[] {
    return plans.map(el => {
      if (!('isShown' in el)) {
        el.isShown = isShown;
      }

      return el;
    });
  }

  addPage(page: CustomPageValues, disableModal?: boolean): Observable<void> {
    return this.presentationApiService
      .addPageConfigToPresentation(this.global.getActivePresentationId, this.customPageService.buildBodyToSend(page))
      .pipe(
        map(res => {
          if (res && res.data && res.data.pageConfig) {
            const { pageConfig } = res.data;

            this.store.dispatch(ConfigsActions.addConfig({ config: pageConfig }));

            if (disableModal) {
              this.store.dispatch(salesConceptsModalAvailable({ payload: false }));
            }

            return pageConfig;
          } else {
            throw new Error();
          }
        })
      );
  }

  editPage(page: PageConfig) {
    return this.presentationApiService.replacePageConfigInPresentation(this.global.getActivePresentationId, page).pipe(
      map(res => {
        if (!get(res, 'data.config')) {
          throw new Error();
        }

        const uiId = page.config.uiId;
        const activePlans = get(res.data.config, 'config.activePlans');
        const pageName = get(res.data.config, 'pageName');
        const isNameChanged = get(res.data.config, 'isNameChanged');

        this.store.dispatch(ConfigsActions.setPageName({ uiId, pageName, isNameChanged }));
        this.store.dispatch(
          ConfigsActions.setActivePlans({
            configsActivePlans: {
              [uiId]: activePlans,
            },
          })
        );
      })
    );
  }

  private convertToActivePlans(plans: PlansWithCustomFields[], isShown = false): ActivePlan[] {
    return plans.map(el => {
      return {
        order: el.order,
        carrierPlanId: el.id,
        isShown: el.isShown || isShown,
      };
    });
  }

  private getFilteredPlans(plans: PlansWithCustomFields[], selectedPlansIds: number[]): PlansWithCustomFields[] {
    return selectedPlansIds.map((id, index) => {
      const selectedPlan = plans.find(plan => plan.id === id);
      selectedPlan.order = index + 1;

      return selectedPlan;
    });
  }
}
