import { Injectable } from '@angular/core';
import { UiTheme } from '@core/model';
import { CompileTheme } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class StyleSchemaService {
  private defaultUiTheme: UiTheme;

  set setDefaultUiTheme(theme: UiTheme) {
    this.defaultUiTheme = theme;
  }

  get getDefaultUiTheme(): UiTheme {
    return this.defaultUiTheme;
  }

  extractCssValues(theme: UiTheme): CompileTheme {
    const cssValues = theme?.sections?.reduce((acc, section) => {
      section.items?.forEach(({ className, value }) => {
        if (className && value !== null) {
          acc[className] = value;
        }
      });

      return acc;
    }, {} as Record<string, string>);

    return { cssValues, title: this.defaultUiTheme?.title };
  }

  setTitleOfDefaultTheme(title: string): void {
    this.defaultUiTheme.title = title;
  }
}
