import { TimeFormat } from '@core/constant';
import { IPagerOptions } from '@se/common/app/navigations/components/pager/pager.interface';
import { DEFAULT_HEADERS_FORMAT } from '@shared/constants';
import { SortDirection, TableRequestParams } from '@shared/models';

const dateFormat = {
  useCustomFormat: true,
  dateFormat: TimeFormat.MMDDYYYYhmmssa,
  type: 'date',
  sortable: true,
};

export const CHOOSE_CUSTOM_PAGE_CHANGELOG_HEADERS = [
  {
    format: { ...DEFAULT_HEADERS_FORMAT, sortable: false },
    key: 'log',
    title: 'Log',
    order: 1,
  },
  {
    format: { ...DEFAULT_HEADERS_FORMAT, sortable: false },
    key: 'title',
    title: 'Title',
    order: 2,
  },
  {
    format: { ...DEFAULT_HEADERS_FORMAT, sortable: false },
    key: 'pageName',
    title: 'Page Name',
    order: 3,
  },
  {
    format: {
      ...DEFAULT_HEADERS_FORMAT,
      sortable: false,
    },
    key: 'numberOfChanges',
    title: 'Number Of Changes',
    order: 4,
  },
  {
    format: {
      ...DEFAULT_HEADERS_FORMAT,
      sortable: false,
    },
    key: 'version',
    title: 'Version',
    order: 5,
  },
  {
    format: {
      ...DEFAULT_HEADERS_FORMAT,
    },
    key: 'author',
    title: 'Author',
    order: 6,
  },
  {
    format: {
      ...DEFAULT_HEADERS_FORMAT,
      sortable: false,
    },
    key: 'note',
    title: 'Note',
    order: 6,
  },
  {
    format: {
      ...DEFAULT_HEADERS_FORMAT,
      ...dateFormat,
    },
    key: 'updated',
    title: 'Updated',
    order: 7,
  },
];

export const DefaultChangelogOrderByRequestParam: TableRequestParams = {
  DIRECTION: 'DESC' as SortDirection,
  SORT_FIELD: 'updated',
};

export const DefaultPaginationOptions: IPagerOptions = {
  itemsPerPage: 10,
  currentPage: 1,
  hasNextPage: true,
  totalElements: 0,
};
