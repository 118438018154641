<div class="choose-custom-page-changelog-container bg-white">
  <ac-modal-header
    class="modal-header p-0 d-flex justify-content-between border-bottom-0"
    (close)="onCloseModal()"
  >
    <div class="modal-header-title">Select Log</div>
  </ac-modal-header>

  <ac-modal-body>
    <section class="choose-custom-page-body">
      <div class="search-container">
        <se-form-input
          [formControl]="searchControl"
          placeholder="Search Logs by Title, Page Name, Version, and Author"
        ></se-form-input>
        <span class="fa fa-search"></span>
      </div>

      @if (tableData && tableData[0]?.data.length && !isDataLoading) {
      <se-summary-table
        tableType="compact"
        emptyDataSymbol=""
        [data]="tableData"
        [showRadioButton]="true"
        [tableHeaders]="tableHeadersData"
        (onRowSelect)="onRowSelect($event)"
        (headerClick)="onHeaderClick($event)"
      ></se-summary-table>
      <div class="container-pagination">
        <se-pager
          [options]="pagination"
          [pagesSliceCount]="1"
          (onPageChange)="onPageChange($event)"
        ></se-pager>
      </div>
      } @if (isDataLoading) {
      <div class="table-list-loader">
        <se-circle-loader type="circle-scale"></se-circle-loader>
      </div>
      } @if (tableData && !tableData[0]?.data?.length && !isDataLoading) {
      <se-notification
        type="no-entities-found"
        [title]="tableLabels.noEntitiesFound.title"
        [message]="tableLabels.noEntitiesFound.message"
      ></se-notification>
      }
    </section>
  </ac-modal-body>

  <ac-modal-footer
    class="modal-footer d-flex align-items-center justify-content-end border-0 p-0"
  >
    <se-button class="secondary" (click)="onCloseModal()">Cancel</se-button>
    <se-button [disabled]="!selectedItem" (click)="onSelectLog()">
      Select Log
    </se-button>
  </ac-modal-footer>
</div>
