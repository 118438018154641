import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class ClipboardService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  copyExecCommand(value: string): void {
    const textarea = this.document.createElement('textarea');

    textarea.style.position = 'fixed';
    textarea.style.left = '0';
    textarea.style.top = '0';
    textarea.style.opacity = '0';
    textarea.value = value;

    this.document.body.appendChild(textarea);

    textarea.focus();
    textarea.select();

    this.document.execCommand('copy');
    this.document.body.removeChild(textarea);
  }
}
