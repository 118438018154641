import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as apiConstant from '@core/constant';
import { getSearchQuery } from '@core/utils';
import { Observable } from 'rxjs';

@Injectable()
export class DownloadApiService {
  constructor(private http: HttpClient) {}

  public downloadPDF(resource: string, sharedToken?: string) {
    const params = {
      resource,
    };

    if (sharedToken) {
      Object.assign(params, { sharedToken });
    }

    return this.http.get(apiConstant.downloadPDF, {
      params,
      responseType: 'blob',
      headers: {
        'shared-token': sharedToken || '',
      },
    });
  }

  public downloadFileFromObjectURL(url: string) {
    return this.http.get(url, { observe: 'response', responseType: 'blob' });
  }

  public downloadRawPayload(presentationId: number): Observable<any> {
    const restUrl = `${apiConstant.downloadIncomingData}`;

    const headersList: Record<string, string> = {};
    headersList.Accept = 'application/zip';
    headersList['Content-Type'] = 'application/zip';

    const options: Record<string, unknown> = {
      headers: new HttpHeaders({ ...headersList }),
      responseType: 'blob',
      observe: 'response',
      params: getSearchQuery({ presentationId }),
    };

    return this.http.get(restUrl, options);
  }

  public getInforceCSV(data: { inforceFilePath: string }) {
    return this.http.get<{ csv: string; fileName: string; success: boolean }>(apiConstant.downloadInforceCSV, {
      params: getSearchQuery(data),
    });
  }

  public sendFile(path: string, method: 'PUT' | 'POST', file: File): Observable<Record<string, unknown>> {
    const body: FormData = new FormData();
    body.append('file', file);

    const headers = new HttpHeaders().append('Accept', 'application/json');

    const options = {
      body,
      headers,
    };

    return this.http.request<Record<string, unknown>>(method, path, options);
  }
}
