<div class="aapp-header-main">
  <header class="aapp-header-wrapper a-app-company-background-color print-elem-hide">
    <div class="container aapp-header-container position-relative h-100">
      <div class="row aapp-header-row h-100">
        <div class="aapp-header m-0 border-0 rounded-0 h-100 w-100">
          <div class="aapp-header-box h-100 d-flex align-items-center justify-content-start">
            <div class="root-navbar-logo d-flex align-items-center w-50">
              @if (showUserMenu) {
                <a [routerLink]="disabledRedirect ? null : '/home'" class="aapp-header-logo a-app-logo-img d-block m-0 p-0 w-100" [ngClass]="{ 'aapp-header-logo-disabled': disabledRedirect }"></a>
              }
              @if (!showUserMenu) {
                <div [routerLink]="disabledRedirect ? null : '/home'" class="aapp-header-logo a-app-logo-img d-block m-0 p-0 h-100" [ngClass]="{ 'aapp-header-logo-disabled': disabledRedirect }"></div>
              }
            </div>
            <div class="root-navbar-content d-flex align-items-center ml-auto">
              <ensight-support-info></ensight-support-info>
              @if (showUserMenu) {
                <ensight-user-menu></ensight-user-menu>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</div>

