import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  Inject,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DOCUMENT } from '@angular/common';

import { EventObj } from '@tinymce/tinymce-angular/editor/Events';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { INIT_CONFIG, UPLOAD_IMAGE_ERROR_MSG } from '@shared/constants';
import { ContentApiService } from '@shared/services';

@UntilDestroy()
@Component({
  selector: 'ensight-tiny-editor',
  templateUrl: './tiny-editor.component.html',
  styleUrls: ['./tiny-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TinyEditorComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() controlName: string;
  @Input() customInitConfig: any;

  @Output() onKeyUp = new EventEmitter<EventObj<KeyboardEvent>>();
  @Output() onEditorInstanceReady = new EventEmitter<unknown>();

  public initConfig: any;
  public loading = true;
  public editor: any;
  private imgFilePath: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private contentApiService: ContentApiService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public ngOnInit(): void {
    const initConfig = this.customInitConfig ? this.customInitConfig : INIT_CONFIG;

    this.initConfig = {
      ...initConfig,
      images_upload_handler: this.uploadImage.bind(this),
      init_instance_callback: this.onEditorCreated.bind(this),
    };
  }

  private onEditorCreated(instance): void {
    this.onEditorInstanceReady.emit(instance);
    this.loading = false;
    this.cdr.detectChanges();
  }

  private uploadImage(blobInfo: any, success: any, failure: any): void {
    const imageToUpload = blobInfo.blob();
    const formData: FormData = new FormData();

    formData.append('imageToUpload', imageToUpload);

    if (this.imgFilePath) {
      const imgFilePath = this.imgFilePath.slice(this.imgFilePath.lastIndexOf('/') + 1);
      this.contentApiService.deleteCustomImage(imgFilePath).pipe(untilDestroyed(this)).subscribe();
    }

    this.contentApiService
      .uploadCustomImage(formData)
      .pipe(untilDestroyed(this))
      .subscribe(
        res => {
          this.imgFilePath = res.data && res.data.location ? res.data.location : '';
          success(this.imgFilePath);
        },
        () => failure(UPLOAD_IMAGE_ERROR_MSG)
      );

    const saveButton = this.document.querySelector('button[title="Save"]');

    saveButton.addEventListener(
      'click',
      () => {
        this.imgFilePath = '';
      },
      { once: true }
    );
  }
}
