import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { AlertService } from '@se/common';
import { ModalRef } from '@assurance/bootstrap';

import { SignInResponse } from '@core/model';

@Component({
  selector: 'ep-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrl: './login-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginModalComponent {
  private modalRef = inject(ModalRef);
  private alertService = inject(AlertService);

  payloadToken = '' as any;

  close(value?: boolean): void {
    this.modalRef.close({ submitted: value });
  }

  onLogin(res: Record<string, boolean | unknown>): void {
    const response = res as unknown as SignInResponse;

    if (response.success) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      response.data?.success ? this.close(true) : this.handleMessage('Error logging in. Please contact the support');

      return;
    }

    const errors = response.data?.error?.message;

    if (Array.isArray(errors) && errors[0]) {
      this.handleMessage(errors[0]);
    } else {
      this.handleMessage('Username or password is incorrect.');
    }
  }

  private handleMessage(message: string): void {
    this.alertService.openAlert({ type: 'error', body: message, autoClose: 5000 });
  }
}
