import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SupportInfo } from './support-info.model';
import { SupportInfoService } from './support-info.service';

@UntilDestroy()
@Component({
  selector: 'ensight-support-info',
  templateUrl: './support-info.component.html',
  styleUrls: ['./support-info.component.scss'],
})
export class SupportInfoComponent implements OnInit {
  supportInfo: SupportInfo;

  constructor(private supportInfoService: SupportInfoService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.supportInfoService
      .getSupportInfo<SupportInfo>()
      .pipe(untilDestroyed(this))
      .subscribe((res: SupportInfo) => {
        this.supportInfo = res;
        this.cdr.markForCheck();
      });
  }
}
